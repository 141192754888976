import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import Paths1 from "../../../assets/header-images/Paths1.jpg";

function DifferentPaths() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={Paths1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Exploring Different Paths </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Choosing the right major is a pivotal decision in your college journey, influencing not only your academic path but also your future career, financial stability, and personal fulfillment. This chapter provides a comprehensive exploration of how community colleges can facilitate your major exploration, the realities of changing majors, and strategies for balancing passion with practicality. By utilizing available resources and understanding your own goals, you can make an informed decision that aligns with both your interests and career aspirations.
    </p>
  </div>
</div>


      <div id="whole-section-page-container">


        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Understanding the Benefits of a College Education
      </strong>
    </p>
    <p class="chapter-description">
      College isn’t just about getting a degree; it’s a transformative experience that offers numerous advantages that can enrich both your professional journey and personal growth:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Expanded Career Opportunities:</strong> A college degree opens doors to a variety of professions, giving you a competitive edge in today’s job market.
    </li>
    <li class="bullet-point">
      <strong>Higher Earnings Potential:</strong> On average, college graduates earn significantly more than those with only a high school diploma, providing a pathway to greater financial stability.
    </li>
    <li class="bullet-point">
      <strong>Networking Opportunities:</strong> College is a hub for meeting peers, professors, and alumni who can offer mentorship and valuable job leads, helping you build a supportive network.
    </li>
    <li class="bullet-point">
      <strong>Skill Development:</strong> College fosters essential skills like problem-solving, critical thinking, and effective communication, all of which are crucial for success in both personal and professional realms.
    </li>
  </ul>
</div>





<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Considering the Alternatives</strong>
    </p>
    <p class="chapter-description">
      While college is a well-trodden path to success, it’s not the only one. There are several alternatives that can lead to fulfilling careers, often at a lower cost and with a quicker entry into the workforce:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Vocational Schools and Technical Training:</strong> These programs provide focused training for careers in fields like healthcare, technology, and trades, allowing you to step into skilled jobs without the need for a four-year degree.
    </li>
    <li class="bullet-point">
      <strong>Apprenticeships:</strong> By blending hands-on training with classroom learning, apprenticeships let you earn while acquiring a trade, such as electrical work or mechanics, setting you on a path to lucrative careers.
    </li>
    <li class="bullet-point">
      <strong>Entrepreneurship:</strong> If you have a solid business idea and the drive to make it happen, launching your own venture can be a fulfilling choice, although it requires significant dedication and hard work.
    </li>
    <li class="bullet-point">
      <strong>Immediate Workforce Entry:</strong> Many careers value experience just as much as formal education, enabling you to gain essential skills and advance your career by starting right after high school.
    </li>
  </ul>
</div>





<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Evaluating Your Career Goals</strong>
    </p>
    <p class="chapter-description">
      As you explore your future, think about your interests, strengths, and career aspirations to determine if pursuing a college degree is the right choice for you:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Interest and Passion Alignment:</strong> Take time to reflect on the subjects and activities that excite you the most. Research how these passions connect with different career paths and whether a college education or an alternative route, like vocational training, aligns better with your goals.
    </li>
    <li class="bullet-point">
      <strong>Career Requirements:</strong> Look into the specific qualifications needed for your dream career, such as degrees, certifications, or specialized training. Consider the time and effort required to achieve these qualifications and how they fit with your long-term objectives.
    </li>
    <li class="bullet-point">
      <strong>Professional Guidance:</strong> Reach out to professionals in your field of interest for insights into their educational and career journeys. Learning from their experiences can empower you to make informed decisions about the necessity of a college degree for your success.
    </li>
  </ul>
</div>




<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Assessing Financial Implications</strong>
    </p>
    <p class="chapter-description">
      The financial commitment of attending college can be substantial, making it essential to evaluate how well it aligns with your long-term aspirations:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Tuition and Living Expenses:</strong> Take into account the complete range of college costs, from tuition and textbooks to housing and daily living expenses. Compare these figures with your available resources—such as personal savings, financial aid, scholarships, or student loans—and consider how these costs may impact your financial well-being in the long run.
    </li>
    <li class="bullet-point">
      <strong>Return on Investment (ROI):</strong> Reflect on the potential boost in lifetime earnings that a degree could provide relative to its initial costs. Understanding the long-term financial benefits versus immediate expenses can help you determine if college is a smart investment for your future.
    </li>
    <li class="bullet-point">
      <strong>Opportunity Costs:</strong> Think about what you might sacrifice by choosing to attend college, such as the income you could earn from working full-time right away. This consideration is especially important if your desired field values practical experience over formal education.
    </li>
  </ul>
</div>






<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Personal Readiness and Life Circumstances</strong>
    </p>
    <p class="chapter-description">
      Your personal readiness and current life circumstances are crucial factors in deciding whether pursuing a college education is the right choice for you:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Academic Preparedness:</strong> Honestly evaluate your academic strengths and weaknesses to determine if you're ready to tackle the intellectual challenges of college. If you have any doubts, consider taking steps to enhance your skills before making a commitment.
    </li>
    <li class="bullet-point">
      <strong>Life Responsibilities:</strong> Reflect on any personal or family obligations that might affect your ability to devote time and energy to your studies. Balancing responsibilities like work, caregiving, or financial pressures can significantly impact your readiness for full-time education.
    </li>
    <li class="bullet-point">
      <strong>Emotional and Social Maturity:</strong> College life requires a strong sense of independence, self-discipline, and resilience. Make sure you feel emotionally equipped to handle the academic pressures and social adjustments that come with this new chapter.
    </li>
  </ul>
</div>



        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Types of Higher Education Institutions: A World of Choices
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Community Colleges:</strong> These institutions provide two-year associate degree programs, which can focus on academic transfer courses or skills-based vocational training. They serve as an affordable entry point into higher education, featuring smaller class sizes and a strong emphasis on teaching.
    </li>
    <li class="bullet-point">
      <strong>Universities:</strong> Larger than community colleges, universities offer a diverse range of undergraduate and graduate programs. They can be categorized into research universities, which prioritize research and provide a wide array of bachelor’s, master’s, and doctoral programs, and teaching universities, which focus primarily on undergraduate education.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Understanding Degrees: The Stepping Stones to Your Future
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Associate’s Degrees (avg. 2 years):</strong> Typically completed in two years at community colleges, students can choose between an Associate of Arts (AA) or Associate of Science (AS) degree, designed to prepare for transfer to a four-year university, or an Associate of Applied Science (AAS), which focuses on direct entry into the workforce.
    </li>
    <li class="bullet-point">
      <strong>Bachelor’s Degrees (avg. 4 years):</strong> Requiring four years of study, these degrees are offered in various fields, including Arts (BA), Science (BS), and Fine Arts (BFA). They serve as foundational credentials for professional entry and are prerequisites for advanced studies across numerous disciplines.
    </li>
    <li class="bullet-point">
      <strong>Master’s Degrees (avg. 2 years):</strong> Generally requiring two years of study beyond a bachelor's degree, master's programs offer advanced knowledge and skills in specific areas. Common types include Master of Arts (MA), Master of Science (MS), Master of Business Administration (MBA), and Master of Fine Arts (MFA). These degrees typically combine coursework with research and may require a thesis or capstone project.
    </li>
    <li class="bullet-point">
      <strong>PhD (Doctor of Philosophy) Degrees (avg. 4-7 years):</strong> These research-intensive degrees can take between four to seven years to complete. They represent the highest academic achievement and require original research that contributes new knowledge to the field. PhD candidates must pass comprehensive exams and defend their dissertation before a panel of experts. PhD programs are available in numerous disciplines, including humanities, sciences, social sciences, and engineering, preparing graduates for careers in academia, research, and specialized professional fields.
    </li>
  </ul>
</div>

       
      </div>
    </div>
  );
}

export default DifferentPaths;
