import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/CCTimeLine.png";

import ccpathway from "../../../assets/header-images/ccpathway.jpg";

function CommunityCollege() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={ccpathway} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Community College Pathways </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Navigating the path to a successful community college experience is an exciting journey filled with opportunities for personal and academic growth! This guide provides a comprehensive overview of the essential steps you need to take, from getting started to transferring to a four-year institution. By following these steps and actively engaging in both academics and extracurricular activities, you can ensure a smooth and productive journey through community college. Let’s embark on this adventure together and unlock your full potential!
    </p>
  </div>
</div>


  {/*    <img id="timeline" src={timeline} /> */}


{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title"> Chapter 1: </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Benefits of Starting at Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Lower tuition costs:</strong> Community colleges offer significantly lower tuition rates than four-year universities, making them an affordable option for students eager to minimize education expenses during their first two years.
    </li>
    <li class="bullet-point">
      <strong>Smaller class sizes:</strong> Experience more personalized attention from instructors in smaller classes, enhancing your learning journey and fostering meaningful student-teacher interactions.
    </li>
    <li class="bullet-point">
      <strong>Flexible schedules:</strong> With options for evening and weekend classes, community colleges provide flexible scheduling that accommodates students balancing work, family, or other commitments while pursuing their education.
    </li>
    <li class="bullet-point">
      <strong>Support services:</strong> Benefit from readily available support services such as tutoring, academic advising, and counseling, which can help you stay on track both academically and personally.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Financial Aid for Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>FAFSA:</strong> Start by completing the Free Application for Federal Student Aid (FAFSA) to discover your eligibility for federal financial aid, including grants, loans, and work-study opportunities that can ease your financial burden.
    </li>
    <li class="bullet-point">
      <strong>Cal Grant:</strong> If you’re a California resident, don’t miss out on applying for Cal Grants—state-funded financial aid programs designed specifically for California students attending community colleges or universities.
    </li>
    <li class="bullet-point">
      <strong>Community College Scholarships:</strong> Many community colleges provide their own scholarships and grants. Take the time to research and apply for these opportunities to help reduce your tuition costs even further.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Steps to Succeed in Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Academic Planning:</strong>
    </p>

    <li class="bullet-point">
      <strong>Talk to a Counselor:</strong> Schedule regular meetings with your academic counselor to create and update your educational plan (ED plan). This roadmap should span 2-3 years, helping you stay focused on graduation or transfer goals.
    </li>
    <li class="bullet-point">
      <strong>Create a Roadmap:</strong> Utilize resources like 
      <a className="link" href="https://assist.org">  assist.org
      </a> to navigate the specific course and credit requirements needed for transferring to your target university and desired major. This foresight can save you time and ensure a smoother transfer process.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Research Colleges:</strong>
    </p>

    <li class="bullet-point">
      <strong>Research:</strong> Identify universities that offer programs aligned with your academic and career aspirations. Consider factors like the school’s reputation, available resources, faculty expertise, and overall campus culture when making your selections.
    </li>
    <li class="bullet-point">
      <strong>Transfer Major Requirements:</strong> Carefully review the transfer requirements for your intended major at each school. Being proactive about meeting these prerequisites can make the application process much smoother.
    </li>
    <li class="bullet-point">
      <strong>Use assist.org:</strong> Explore assist.org to check the transfer requirements and acceptance rates for your major at different universities, helping you target the best options for your transfer journey.
    </li>
    <li class="bullet-point">
      <strong>Identify Your Goals:</strong> Create a clear list of universities you aim to transfer to, including their major requirements, transfer prerequisites, and other relevant factors. This focus will help you meet the specific criteria for each school.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Extracurriculars:</strong>
    </p>

    <li class="bullet-point">
      <strong>Join Clubs and Activities:</strong> Get involved in extracurricular activities like clubs, sports, and volunteer work. Taking on leadership roles or even starting your own club can significantly enhance your application.
    </li>
    <li class="bullet-point">
      <strong>Make Connections:</strong> Build relationships with fellow students and professors. Networking can open doors to valuable academic and professional opportunities, including recommendations and career guidance.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Start Writing Your Essay:</strong>
    </p>

    <li class="bullet-point">
      <strong>Begin Early:</strong> Start drafting your transfer application essays during the summer before you plan to apply. Allowing ample time for revisions will help you create strong, compelling essays.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Apply for Scholarships:</strong>
    </p>

    <li class="bullet-point">
      <strong>Ongoing Applications:</strong> Continue applying for scholarships throughout the academic year. The more scholarships you apply for, the better your chances of receiving financial aid that can significantly ease college expenses.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Preparing for College: From Community College to Higher Education
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>
        Beyond Academics: Showcasing Your Well-Rounded Self
      </strong>
    </p>

    <li class="bullet-point">
      <strong>Extracurricular Activities:</strong> Get involved in clubs, participate in sports, and volunteer in your community. These experiences demonstrate your time management skills, teamwork, and enthusiasm for activities beyond academics.
    </li>
    <li class="bullet-point">
      <strong>Internships and Work Experience:</strong> Seek out valuable real-world experiences and explore potential career paths. Look for opportunities in fields that align with your academic interests to enhance your resume and skill set.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>
        Developing Essential Skills: Sharpen Your Tools for Success
      </strong>
    </p>

    <li class="bullet-point">
      <strong>Time Management:</strong> Master the art of organizing your schedule. Utilize planners, calendars, and productivity apps to balance your academics, extracurriculars, and personal commitments effectively.
    </li>
    <li class="bullet-point">
      <strong>Communication:</strong> Enhance your writing and speaking skills to express your ideas clearly. Engage in writing workshops and seek out public speaking opportunities to build confidence.
    </li>
    <li class="bullet-point">
      <strong>Critical Thinking and Problem Solving:</strong> Cultivate your ability to analyze information, identify solutions, and think creatively. Challenge yourself through rigorous academic courses and hands-on extracurricular activities.
    </li>
    <li class="bullet-point">
      <strong>Leadership:</strong> Showcase your leadership skills by taking on roles in clubs, teams, or community organizations. This reflects your ability to influence and guide others effectively.
    </li>
  </ul>
</div>

{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 1: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
          </div>
        </div>
*/}
{/*
        <div id="ch-base-page-container">
          <div id="ch-base-page-text-container">
            <div id="ch-base-page-title-container">
              <h2 id="ch-base-page-title"> Chapter 2: </h2>
              <div id="ch-base-page-underline"> </div>
            </div>
          </div>
        </div>
*/}
        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Course Planning</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Mix Courses:</strong> Aim for a balanced mix of easy and challenging courses each semester. Taking more major preparation courses early on is often beneficial, as they tend to be more manageable. As the coursework becomes more demanding, you can pair these with General Education (GE) courses for a lighter overall workload.
    </li>
    <li class="bullet-point">
      <strong>Avoid Low Grades:</strong> Strive to keep your grades above a C. If you find yourself struggling and your grade drops below a B, consider withdrawing from the course (W on your transcript) instead, which can be less detrimental to your academic record.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Study Groups and Networking</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Create Study Groups:</strong> Form study groups by setting up a Discord server or another online platform for your classes. This facilitates easy communication with classmates, resource sharing, and collaborative discussions about course material. Organizing study groups proactively not only enhances your learning but can also be highlighted as an extracurricular activity, showcasing your leadership and teamwork skills.
    </li>
    <li class="bullet-point">
      <strong>Select Good Professors:</strong> Utilize websites like RateMyProfessors to find the best professors, especially for challenging courses. Choosing the right professor can greatly enhance your understanding of the material and improve your overall academic experience.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Free Tools and Resources</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Textbook PDFs:</strong> Take advantage of free online resources that offer a wide range of academic textbooks in PDF format. This can help you save significantly on textbook costs throughout your college journey.
    </li>
    <li class="bullet-point">
      <strong>College Resources:</strong> Fully utilize the free software and tools provided by your college, including access to Microsoft Word, Excel, Adobe Acrobat, and other premium applications. These resources can greatly enhance your academic work and streamline your assignment completion.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Transfer College App</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Assist.org (Community College in CA to CA University):</strong>
    </p>
    <li class="bullet-point">
      Assist.org is an invaluable tool for California community college students planning to transfer to a California university. It helps you identify which courses are transferable and meet the requirements for your intended major at your target university. Be sure to check it regularly as part of your transfer planning process.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>University of California (UC) Application:</strong>
    </p>

    <li class="bullet-point">
      <strong>TAG:</strong> The Transfer Admission Guarantee (TAG) program offers community college students a guaranteed admission pathway to select UC campuses. Learn more about TAG eligibility and requirements through this{" "}
      <a className="link" href="https://admission.universityofcalifornia.edu/admission-requirements/transfer-requirements/uc-transfer-programs/transfer-admission-guarantee-tag.html">
        link
      </a>.
    </li>
    <li class="bullet-point">
      <strong>Transfer Statistics by Major:</strong> Review detailed transfer statistics, including acceptance rates and GPAs for each major at various UC campuses. This information can help you assess your chances of acceptance based on your academic profile. Check the data here:{" "}
      <a className="link" href="https://www.universityofcalifornia.edu/about-us/information-center/transfers-major">
        link
      </a>.
    </li>
    <li class="bullet-point">
      <strong>Transfer Pathways Guide (Courses):</strong> The Transfer Pathways Guide outlines the specific courses community college students should take to prepare for popular majors at UC campuses. It’s a useful resource for planning your coursework to ensure you meet transfer requirements. Explore the guide here:{" "}
      <a className="link" href="https://pathwaysguide.universityofcalifornia.edu/college-pathways/0/0">
        link
      </a>.
    </li>
  </ul>
</div>


{/*

        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 2: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Succeeding in community college involves a blend of strategic
              academic planning, active participation in extracurricular
              activities, and continuous preparation for transferring to a
              four-year institution. By following these steps and utilizing
              available resources, you can make the most of your community
              college experience and set yourself up for future success.
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default CommunityCollege;
