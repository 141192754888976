import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import freshmen from "../../../assets/header-images/freshmen.jpg";

function FreshmenAndSophomorePrograms() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={freshmen} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Freshmen and Sophomore Programs </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            Freshmen and sophomore programs serve as pivotal stepping stones for early career development, providing underclassmen with valuable hands-on experience, essential technical skills, and opportunities to build professional networks. These programs are thoughtfully designed to give students a head start on their academic and career journeys, ensuring they are well-prepared for the future.
    
            Below, you’ll find a selection of some of the most impactful freshmen and sophomore programs to consider. While this list is not exhaustive, it highlights key opportunities that can significantly contribute to your professional growth and help you expand your network.
        </p>

        <p id="base-page-introduction-text-main">
            <strong>Note:</strong> To learn more about these opportunities, use the link below:
            <a class="link" href="https://upe-ucla.medium.com/finding-internship-opportunities-as-a-freshman-sophomore-659928065e13"> Finding Internship Opportunities as a Freshman/Sophomore</a>
        </p>
    </div>
</div>


      <div id="sections-container">


        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Freshmen/Sophomore Specific Internships:</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Google STEP Internship</strong>
      </p>
      <p class="chapter-description">
        A 12-week internship designed for first and second-year undergraduate students to gain invaluable experience in software engineering at Google.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://buildyourfuture.withgoogle.com/programs/step" class="link"> Google STEP Internship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Microsoft Explore Program</strong>
      </p>
      <p class="chapter-description">
        A dynamic 12-week summer internship program for first and second-year college students, offering exposure to exciting software development and engineering roles at Microsoft.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.microsoft.com/us/en/um/explore-microsoft" class="link"> Microsoft Explore Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Facebook University</strong>
      </p>
      <p class="chapter-description">
        An enriching 8-week internship program for underrepresented students to learn about Facebook’s innovative people, products, and services.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.facebook.com/careers/students-and-grads" class="link"> Facebook University
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>UberSTAR</strong>
      </p>
      <p class="chapter-description">
        A hands-on 10-week internship for undergraduate freshmen or sophomores to gain experience in technology and engineering at Uber.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.uber.com/us/en/careers/" class="link"> UberSTAR
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Microsoft New Technologists</strong>
      </p>
      <p class="chapter-description">
        A fantastic learning experience for underclassmen to develop technical and professional skills through hands-on projects and mentorship.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://newtechnologists.com/" class="link"> Microsoft New Technologists
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Amazon Future Engineer Internship</strong>
      </p>
      <p class="chapter-description">
        A program offering valuable internships to freshmen and sophomores to gain experience in software development at Amazon.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.amazonfutureengineer.com/" class="link"> Amazon Future Engineer Internship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Bank of America Global Technology</strong>
      </p>
      <p class="chapter-description">
        An engaging internship program for underclassmen to explore exciting careers in technology at Bank of America.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://campus.bankofamerica.com/careers/global_technology_summer_analyst_program__2025.html" class="link"> Bank of America Global Technology
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Programs Known to Accept Freshmen/Sophomores</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Amazon SDE Internship</strong>
      </p>
      <p class="chapter-description">
        An exciting internship for students to gain hands-on experience in software development at Amazon, where innovation thrives!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.amazon.jobs/content/en/career-programs/student-programs/internships-for-students" class="link"> Amazon SDE Internship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Google Summer of Code</strong>
      </p>
      <p class="chapter-description">
        A global program inviting students to work on open source projects under the guidance of mentors from around the world, fostering creativity and collaboration!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://summerofcode.withgoogle.com/" class="link"> Google Summer of Code
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>PlayStation</strong>
      </p>
      <p class="chapter-description">
        Internships for students to explore various roles within the gaming industry at PlayStation, where your passion for games meets professional experience!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.playstation.com/en-us/corporate/jobs/internships/" class="link"> PlayStation
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Fidelity Investments</strong>
      </p>
      <p class="chapter-description">
        Internships offering students exposure to various roles within finance and technology at Fidelity Investments, a great way to kickstart your career!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://jobs.fidelity.com/" class="link"> Fidelity Investments
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Booz-Allen Summer Games Internship</strong>
      </p>
      <p class="chapter-description">
        A competitive internship program where students tackle real-world problems through innovative projects, enhancing your skills and creativity!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.boozallen.com/" class="link"> Booz-Allen Summer Games Internship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Civic Digital Fellowship</strong>
      </p>
      <p class="chapter-description">
        A unique fellowship for students to work on tech projects that serve the public good in government agencies, making a meaningful impact in your community!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.codingitforward.com/fellowships" class="link"> Civic Digital Fellowship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Prudential Software Development</strong>
      </p>
      <p class="chapter-description">
        Internships for students to gain valuable experience in software development and engineering at Prudential, where innovation meets opportunity.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.prudential.com/careers" class="link"> Prudential Software Development
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Mitre Student Development Program</strong>
      </p>
      <p class="chapter-description">
        A program offering internships for students to work on impactful projects in technology and engineering at MITRE, helping shape the future of innovation!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.mitre.org/careers" class="link"> Mitre Student Development Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Johns Hopkins APL College Summer Intern Program</strong>
      </p>
      <p class="chapter-description">
        A summer internship for students to gain hands-on experience in research and development projects at Johns Hopkins Applied Physics Laboratory, where your ideas can make a difference!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.jhuapl.edu/Careers/Internships" class="link"> Johns Hopkins APL College Summer Intern Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>IBM</strong>
      </p>
      <p class="chapter-description">
        A summer internship for students to work on innovative tech projects at IBM, where creativity and technology come together!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.ibm.com/us-en/employment/" class="link"> IBM
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Full Program Listings</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Amazon Technical Apprenticeship</strong>
      </p>
      <p class="chapter-description">
        A fantastic apprenticeship program where students can develop technical skills in software engineering at Amazon, stepping into a world of innovation!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.amazon.jobs/en/landing_pages/software-development-apprenticeship" class="link"> Amazon Technical Apprenticeship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Microsoft Leap</strong>
      </p>
      <p class="chapter-description">
        An immersive program designed for students to hone their technical skills through training and mentorship at Microsoft, paving the way for future success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.microsoft.com/leap/" class="link"> Microsoft Leap
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Uber Career Prep</strong>
      </p>
      <p class="chapter-description">
        A dynamic program preparing students for tech careers through hands-on training, mentorship, and real-world experience at Uber.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.uber.com/us/en/careers/careerprep/" class="link"> Uber Career Prep
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Goldman Sachs Possibilities Summits</strong>
      </p>
      <p class="chapter-description">
        Summits offering students invaluable networking, mentorship, and professional development opportunities at Goldman Sachs—an excellent chance to build your future!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.goldmansachs.com/careers/students/programs/americas/possibilities-summits.html" class="link"> Goldman Sachs Possibilities Summits
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Accenture Apprenticeship</strong>
      </p>
      <p class="chapter-description">
        Apprenticeships offering hands-on training and experience in technology and consulting at Accenture, setting you up for a bright future!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.accenture.com/us-en/about/company/apprenticeships" class="link"> Accenture Apprenticeship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Airbnb Connect Software Engineering Apprenticeship</strong>
      </p>
      <p class="chapter-description">
        A rewarding apprenticeship for underrepresented students to gain valuable experience in software engineering at Airbnb, contributing to innovative projects!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.airbnb.com/connect-engineering-apprenticeship/" class="link"> Airbnb Connect Software Engineering Apprenticeship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Dropbox Emerging Talent</strong>
      </p>
      <p class="chapter-description">
        Internships for students to work on exciting real-world projects and develop their skills at Dropbox, a great place to grow your career!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.dropbox.com/jobs" class="link"> Dropbox Emerging Talent
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Dropbox Launch Internship</strong>
      </p>
      <p class="chapter-description">
        A 12-week internship for second-year students to gain hands-on experience in software engineering at Dropbox, where you can truly make an impact!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.dropbox.com/jobs" class="link"> Dropbox Launch Internship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Build Your Future with Google</strong>
      </p>
      <p class="chapter-description">
        A suite of programs and internships to help students develop technical skills and explore exciting careers in technology at Google.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://buildyourfuture.withgoogle.com/" class="link"> Build Your Future with Google
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>IBM Impact Feature Apprenticeship Program</strong>
      </p>
      <p class="chapter-description">
        Apprenticeships offering students the chance to develop technical and professional skills at IBM—an incredible opportunity for growth!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.ibm.com/careers/career-opportunities" class="link"> IBM Impact Feature Apprenticeship Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>LinkedIn Reach Program</strong>
      </p>
      <p class="chapter-description">
        Apprenticeships for students to develop skills in software engineering and other technical fields at LinkedIn, opening doors to your future!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.linkedin.com/reach" class="link"> LinkedIn Reach Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Lyft SWE Apprenticeship Program</strong>
      </p>
      <p class="chapter-description">
        Software engineering apprenticeships for underrepresented students to gain hands-on experience at Lyft, where your contributions can shine!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.lyft.com/careers/early-talent" class="link"> Lyft SWE Apprenticeship Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Meta University</strong>
      </p>
      <p class="chapter-description">
        An enriching internship program for underrepresented students to learn about Meta’s innovative products and services while gaining valuable experience.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.metacareers.com/careerprograms/pathways/metauniversity" class="link"> Meta University
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Breakthrough Tech Sprinternships</strong>
      </p>
      <p class="chapter-description">
        Short-term internships providing students with real-world experience in technology, making an impact from day one!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.breakthroughtech.org/programs/sprinternships/" class="link"> Breakthrough Tech Sprinternships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Verizon Sprinternship Program</strong>
      </p>
      <p class="chapter-description">
        Short-term internships designed to help students gain valuable experience in technology at Verizon, paving the way for future careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.breakthroughtech.org/verizon-sprinternship-program/" class="link"> Verizon Sprinternship Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Snap Academies</strong>
      </p>
      <p class="chapter-description">
        Training and mentorship programs for underrepresented students to gain essential skills in technology at Snap Inc., where your potential can flourish!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://mailchi.mp/c073af747abe/snap-academies-signup" class="link"> Snap Academies
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Jane Street Programs and Events</strong>
      </p>
      <p class="chapter-description">
        Various programs and events designed to help students gain experience in finance and technology at Jane Street, preparing you for a bright future!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.janestreet.com/join-jane-street/programs-and-events/?location=north-america&program-type=all-programs&event-type=all-events&show-programs=true&show-events=true" class="link"> Jane Street Programs and Events
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Jane Street AMP</strong>
      </p>
      <p class="chapter-description">
        Mentorship and training programs for students to develop skills in technology and finance at Jane Street, paving the way for future success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.janestreet.com/join-jane-street/programs-and-events/amp/" class="link"> Jane Street AMP
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>IBM Accelerate Program</strong>
      </p>
      <p class="chapter-description">
        Early career development opportunities through training and hands-on experience at IBM, where innovation thrives!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.ibm.com/blogs/jobs/career-ready-skills-with-ibm-accelerate-summer-program/" class="link"> IBM Accelerate Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Lyft Early Talent Access Program</strong>
      </p>
      <p class="chapter-description">
        Programs helping underrepresented students gain experience in technology at Lyft, a fantastic opportunity to grow your career!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.lyft.com/careers/early-talent" class="link"> Lyft Early Talent Access Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Ally Early Talent Technology Development Program</strong>
      </p>
      <p class="chapter-description">
        Training and development opportunities for students interested in technology careers at Ally, setting the stage for future success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://ally.avature.net/careers/JobDetail/2024-Early-Talent-Technology-Talent-Development-Program/9943" class="link"> Ally Early Talent Technology Development Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Pinterest Early Career Development</strong>
      </p>
      <p class="chapter-description">
        Programs to help underrepresented students gain experience and skills in technology at Pinterest, a fantastic way to jumpstart your career!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.pinterestcareers.com/early-career/apprenticeships/" class="link"> Pinterest Early Career Development
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Pinterest Engage Scholar Program</strong>
      </p>
      <p class="chapter-description">
        Training opportunities for students to improve technical and interpersonal skills at Pinterest, enhancing your professional journey!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.pinterestcareers.com/early-career/scholar-programs/" class="link"> Pinterest Engage Scholar Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>HBCU 20x20</strong>
      </p>
      <p class="chapter-description">
        Fellowship opportunities for African American or Latinx students focusing on career development, creating pathways for success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://theapplication.org/fellowships" class="link"> HBCU 20x20
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Management Leadership for Tomorrow</strong>
      </p>
      <p class="chapter-description">
        Programs designed to help African American, Latino, and Native American students prepare for career success, empowering the next generation of leaders!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://mlt.org/career-prep/" class="link"> Management Leadership for Tomorrow
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Mensa Foundation Scholarships</strong>
      </p>
      <p class="chapter-description">
        Scholarships for students based on academic achievement and leadership, recognizing your hard work and dedication!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.mensafoundation.org/what-we-do/scholarships/us-scholarships/" class="link"> Mensa Foundation Scholarships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Bessemer Fellowship Program</strong>
      </p>
      <p class="chapter-description">
        Fellowships for students to gain experience in technology and entrepreneurship, fostering innovation and creativity!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.bvp.com/" class="link"> Bessemer Fellowship Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Code 2040</strong>
      </p>
      <p class="chapter-description">
        Programs offering fellowships, internships, and professional development opportunities for Black and Latinx students, promoting diversity and inclusion!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://programs.code2040.org/" class="link"> Code 2040
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Morgan Stanley Early Insights Program</strong>
      </p>
      <p class="chapter-description">
        Early-stage career development opportunities in finance and technology, setting you on the path to success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.morganstanley.com/" class="link"> Morgan Stanley Early Insights Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Twitter Academy</strong>
      </p>
      <p class="chapter-description">
        Internships for underrepresented students to gain hands-on experience in the tech industry at Twitter, where your voice matters!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.twitter.com/en/early-career.html" class="link"> Twitter Academy
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>EA Pathfinder</strong>
      </p>
      <p class="chapter-description">
        Internships for students interested in engineering or analytics at EA, where you can shape the future of gaming!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.ea.com/careers/students" class="link"> EA Pathfinder
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Palantir Path</strong>
      </p>
      <p class="chapter-description">
        Programs to support students from underrepresented communities in gaining experience in technology at Palantir, building a brighter future!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.palantir.com/" class="link"> Palantir Path
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>TwoSigma Freshman Software Engineering Intern</strong>
      </p>
      <p class="chapter-description">
        A 10-week program for freshmen to gain valuable experience in software engineering at Two Sigma, where innovation is at your fingertips!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.twosigma.com/" class="link"> TwoSigma Freshman Software Engineering Intern
        </a>
      </li>
    </ul>
  </div>
</div>



      </div>
    </div>
  );
}

export default FreshmenAndSophomorePrograms;
