import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function CraftingStandoutApplication() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} alt="Header" />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 4</p>
            <p id = "sub-scholarship-ch-header-text">Crafting a Strong Application</p>
            <p id = "sub-scholarship-ch-text-two">Showcase Your Strengths and Shine</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            This chapter equips you with the tools and strategies necessary to craft a winning scholarship application that captures the committee's attention and effectively highlights your unique qualities. By focusing on what makes you stand out, you can create a compelling narrative that not only showcases your achievements but also reflects your personal journey, aspirations, and the impact you hope to make in the future. Let’s explore how to make your application resonate with the selection committee and increase your chances of success.
        </p>
    </div>
</div>


      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>The "Information About Yourself" Template</strong>
            </p>
            <p className="chapter-description">
              Streamline your application process by creating a versatile
              template that captures essential information about yourself.
              This pre-populated template can be used across various
              applications and simplifies the reference letter request
              process for both you and your recommenders.
            </p>
            <p className="chapter-description">
              <strong>The Template Should Include:</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Introduction Paragraph:</strong> Briefly introduce
                yourself, including your background, current education
                status, major, and future goals (250+ words).
              </li>
              <li className="bullet-point">
                <strong>Extracurricular Activities:</strong> Describe your
                extracurricular activities (50-100 words each), including
                volunteering, community service, school clubs, awards
                received, and leadership experiences.
              </li>
              <li className="bullet-point">
                <strong>Research, Internship, or Work Experience:</strong>
                Briefly describe your research, internship, or work
                experience (50-100 words each), highlighting what you
                learned, accomplishments, and any leadership roles held.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Steps to Craft a Compelling Scholarship Essay</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Start Early and Manage Time Wisely:</strong>
                <ul>
                  <li>
                    Begin the writing process early, so that you can have
                    plenty of time to improve it and make it the best
                    possible essay.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Exceed Word Count Initially:</strong>
                <ul>
                  <li>
                    Aim to exceed the required word count initially to
                    gather ample content for refinement. This makes sure you
                    cover all relevant information comprehensively.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Avoid Repetition:</strong>
                <ul>
                  <li>
                    Even for similar prompts, avoid repeating ideas across
                    multiple essays. Approach each topic with fresh
                    perspectives.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Common Essay Prompts:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Challenges / Obstacles:</strong>
                    <ul>
                      <li>
                        Describe personal challenges or major events you've
                        overcome to reach your current position.
                      </li>
                    </ul>
                  </li>
                  <li className="bullet-point">
                    <strong>Personal Statement:</strong>
                    <ul>
                      <li>
                        Craft a statement outlining your background,
                        personal interests, and educational and career
                        goals.
                      </li>
                    </ul>
                  </li>
                  <li className="bullet-point">
                    <strong>Community Involvement:</strong>
                    <ul>
                      <li>
                        Reflect on how your community and extracurricular
                        activities have shaped your interests and detail
                        your contributions to improving your school or
                        community.
                      </li>
                    </ul>
                  </li>
                  <li className="bullet-point">
                    <strong>Academic Goals:</strong>
                    <ul>
                      <li>
                        Outline your current academic activities, plans, and
                        aspirations, and explain how the scholarship would
                        help you achieve these goals.
                      </li>
                    </ul>
                  </li>
                  <li className="bullet-point">
                    <strong>Leadership Experience:</strong>
                    <ul>
                      <li>
                        Share an experience where you served in a leadership
                        role, and describe the qualities and characteristics
                        you developed during that time.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Writing Essays That Win</strong>
            </p>
            <ul className="bullet-point-list">
              <p>
                <strong>Draft 1:</strong> Read the prompt and freely write about anything related to
                the topic. Aim to write 1.5 to 2 times the required word
                count to ensure comprehensive coverage.
              </p>
              <li className="bullet-point">
                <strong>Utilize ChatGPT or Gemini for Enhancement:</strong>
                <ul>
                  <li>
                    <b>Note:</b> While ChatGPT can be a helpful tool, it's
                    crucial to maintain originality and authenticity. Use it
                    for enhancement, not as a replacement for your own
                    writing.
                  </li>
                  <li>
                    <strong>How to Leverage ChatGPT or Gemini:</strong>
                    <ul className="bullet-point-list">
                      <li className="bullet-point">
                        Copy your initial draft and paste it into ChatGPT.
                      </li>
                      <li className="bullet-point">
                        Include the scholarship essay prompt and desired
                        word count in your prompt to guide ChatGPT's
                        suggestions.
                      </li>
                      <li className="bullet-point">
                        <strong>Commands:</strong>
                        <ul className="bullet-point-list">
                          <li className="bullet-point">
                            Paste Your essay here
                            <br></br>
                            <br></br>
                            "using the text above answer the scholarship
                            essay prompt"
                            <br></br>
                            <br></br>
                            [Insert scholarship essay prompt here] in
                            [Insert desired word count here] words.
                          </li>
                        </ul>
                      </li>
                      <li className="bullet-point">
                        ChatGPT will analyze your draft and suggest
                        improvements for clarity, conciseness, and flow.
                        Remember to maintain your unique voice and writing
                        style.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <hr></hr>
              <br></br>
              <p>
                <strong>Draft 2 - Refine and Focus:</strong> Carefully review your rough draft. Remove extraneous
                information and focus on refining the most impactful details
                to streamline your essay and improve overall coherence.
              </p>
              <li className="bullet-point">
                <strong>Utilize ChatGPT for Enhancement:</strong>
                <ul>
                  <li>
                    Once satisfied with the structure and content, seek
                    assistance from ChatGPT to enhance its quality and
                    engagement.
                    <ul>
                      <li className="bullet-point">
                        Provide your essay to ChatGPT with a prompt to "make
                        it better," ensuring it remains human-sounding and
                        aligned with your voice.
                      </li>
                      <li className="bullet-point">
                        If ChatGPT uses complex language, instruct it to
                        "make it sound human" for a more natural tone.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <br></br>
              <hr></hr>
              <br></br>

              <p>
                <strong>Final Review:</strong> Conduct a final review to ensure your essay effectively
                addresses the scholarship prompt. Consider these options:
              </p>
              <li className="bullet-point">
                <strong>Seek Feedback from ChatGPT:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    Open a new chat with ChatGPT and ask it to rate your
                    essay out of 10 based on its alignment with the
                    scholarship prompt. Aim for a score between 8 or 9.
                  </li>
                  <li className="bullet-point">
                    <strong>Commands:</strong> Open a new chat from ChatGPT,
                    then type the following:
                    <ul className="bullet-point-list">
                      <li className="bullet-point">
                        Please review the essay below and provide feedback:
                        [Insert your essay here].
                      </li>
                      <li className="bullet-point">
                        Does the essay above adequately address the prompt:
                        [Insert the scholarship prompt here].
                      </li>
                      <li className="bullet-point">
                        Please rate the essay out of 10.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Seek Feedback from Others:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    Ask a trusted person, like a teacher, counselor, or
                    mentor, to read your essay and provide feedback.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Request Essay Review from Us:</strong>
                <ul>
                  <li>
                    Reach out for a comprehensive essay review from us. We
                    will try our best to provide detailed feedback on your
                    essay's structure, content, and alignment with the
                    scholarship prompt, helping you refine it further for
                    submission.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Securing Strong Recommendation Letters</strong>
            </p>
            <p className="chapter-description">
              While not all scholarships require recommendation letters,
              most do. They help validate your application and showcase your
              qualities beyond your essay.
            </p>
            <strong>Here's How to Secure Strong Recommendation Letters:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Identify Recommenders:</strong> Choose at least two
                professors, community leaders, or counselors who know you
                well and can speak to your achievements and character.
              </li>
              <li className="bullet-point">
                <strong>Request Recommendations Early:</strong> Reach out to
                your recommenders at least one month before the scholarship
                deadline to ensure they have ample time to write thoughtful
                letters.
              </li>
              <li className="bullet-point">
                <strong>Ask Their Permission First:</strong> Begin by asking
                for their permission to serve as a recommender for your
                scholarship applications.
              </li>
              <li className="bullet-point">
                <strong>Provide Supportive Information:</strong> Once they
                agree, share the "Information About Yourself" template with
                your recommenders to refresh their memory about your
                background, goals, and experiences.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>“Why” Matters More Than “What” for Scholarships</strong>
            </p>
            <p className="chapter-description">
              Many scholarship applicants make the mistake of listing their
              activities and achievements without providing the context or
              motivation behind them. While it's important to highlight your
              accomplishments, it's even more crucial to explain the reasons
              and passions driving your actions. This approach not only
              makes your application more compelling but also provides the
              scholarship committee with a deeper understanding of who you
              are and what motivates you.
            </p>
            <p className="chapter-description">
              <strong>Here’s the secret sauce to crafting a compelling scholarship
              application:</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Shift the Focus:</strong> Instead of only stating
                your accomplishments, delve into the motivations and
                experiences behind them. For example, rather than saying, “I
                won the science fair,” you could say, “Winning the science
                fair fueled my passion for solving real-world problems and
                sparked a deeper interest in environmental science.” This
                approach provides a narrative that connects your
                achievements to your personal growth and future aspirations.
              </li>
              <li className="bullet-point">
                <strong>Connect Activities to a Bigger Goal:</strong> Demonstrate how your activities and experiences contribute
                to your long-term goals. If you are an aspiring doctor who
                volunteers at a clinic, you could say, “Witnessing firsthand
                the impact of healthcare on patients at the clinic
                reinforced my commitment to pursue a career in medicine and
                serve underserved communities.” This shows that your actions
                are part of a larger, purposeful journey.
              </li>
              <li className="bullet-point">
                <strong>Highlight Personal Growth and Resilience:</strong> Share stories of how you have evolved through your
                experiences and overcome challenges. For instance, you might
                say, “Starting a coding club taught me valuable teamwork and
                leadership skills, even when faced with setbacks like
                organizing events and maintaining member engagement.” This
                highlights your ability to grow and learn from difficult
                situations, which is a trait highly valued by scholarship
                committees.
              </li>
              <li className="bullet-point">
                <strong>Provide Specific Examples:</strong> Use specific
                anecdotes and examples to illustrate your points. This makes
                your application more vivid and memorable. For example,
                instead of vaguely mentioning that you participated in
                community service, describe a particular project you led,
                the challenges you faced, and the impact it had on the
                community.
              </li>
              <li className="bullet-point">
                <strong>Reflect on the Impact:</strong> Consider how your
                experiences have shaped your values, goals, and perspective.
                Reflecting on the impact of your activities not only shows
                self-awareness but also provides insight into how you might
                use the scholarship to further your education and contribute
                to your field or community.
              </li>
            </ul>
          </div>
        </div>


        
      </div>
    </div>
  );
}

export default CraftingStandoutApplication;
