import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import allstem from "../../../assets/header-images/allstem.jpg";

function AllStemWorkOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={allstem} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> All STEM Work Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            This page offers a comprehensive overview of a variety of experiential learning opportunities tailored for STEM students. Whether you're seeking internships, research projects, or volunteer work, you'll find valuable insights here to help you gain practical experience and pave the way for a successful career in the STEM field.
        </p>
        <p id="base-page-introduction-text-main">
            <strong>Note:</strong> If you are interested in internships in any of the areas below, <a class="link" href="https://www.smc.edu/student-support/career-services/get-the-job/stem/stem-experiential-opportunities/">click here</a> for more information.
        </p>
    </div>
</div>


      <div id="sections-container">


        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>STEM Experiential Learning Opportunities</strong>
    </p>
    <p class="chapter-description">
      Experiential learning opens up a world of possibilities, allowing you to expand your knowledge and apply your skills beyond the traditional classroom setting. This hands-on approach better prepares you for a successful, lifelong career in STEM! Here are various ways you can gain valuable experience as a STEM student.
    </p>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Opportunities Sorted by STEM Major/Department</strong>
    </p>
    <p class="chapter-description">
      Explore a variety of experiential learning opportunities tailored to your specific STEM major or department!
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Automotive Technology:</strong> Dive into hands-on opportunities in automotive technology to gain practical skills and industry knowledge that can drive your career forward.
      </li>
      <li class="bullet-point">
        <strong>Computer Information Systems (CIS) and Computer Science (CS):</strong> Discover exciting internships, innovative projects, and research opportunities in CIS and CS to enhance your technical expertise.
      </li>
      <li class="bullet-point">
        <strong>Earth Science (Astronomy, Geology, GIS, Sustainable Technologies):</strong> Engage in fieldwork, research, and internships across various earth science disciplines to uncover the wonders of our planet.
      </li>
      <li class="bullet-point">
        <strong>General Science:</strong> Participate in a wide range of experiential learning activities that span multiple scientific fields, allowing you to broaden your horizons!
      </li>
      <li class="bullet-point">
        <strong>Life Science (Biological Sciences and Environmental Science):</strong> Gain practical experience through lab work, field research, and internships in life sciences, contributing to our understanding of living systems.
      </li>
      <li class="bullet-point">
        <strong>Math:</strong> Explore opportunities to apply mathematical theories and concepts in real-world scenarios, turning numbers into solutions!
      </li>
      <li class="bullet-point">
        <strong>Physical Science (Chemistry, Engineering, Physics):</strong> Participate in engaging research projects, internships, and applied learning experiences in physical sciences to fuel your passion for discovery.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Opportunities Sorted by Career Focus Area</strong>
    </p>
    <p class="chapter-description">Explore a variety of opportunities by career focus area to find your perfect fit!</p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Education/Teaching:</strong> Pursue exciting teaching assistantships, tutoring positions, and educational outreach programs in STEM to inspire the next generation!
      </li>
      <li class="bullet-point">
        <strong>Healthcare:</strong> Engage in meaningful internships, volunteer work, and research opportunities within the healthcare industry, making a difference in people’s lives.
      </li>
      <li class="bullet-point">
        <strong>Non-profit/Government:</strong> Discover internships, fellowships, and volunteer positions in non-profit organizations and government agencies to contribute to impactful initiatives.
      </li>
      <li class="bullet-point">
        <strong>Research:</strong> Participate in cutting-edge research projects, lab work, and academic research programs that push the boundaries of knowledge and innovation.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Opportunities Sorted by Applied and Service Learning, Internships, and Volunteer Experience
      </strong>
    </p>
    <p class="chapter-description">
      Explore opportunities that focus on Applied and Service Learning, Internships, and Volunteer Experience to enrich your STEM journey!
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Applied and Service Learning:</strong> Engage in community-based projects and service-learning opportunities that apply your STEM knowledge to real-world challenges, making a tangible impact!
      </li>
      <li class="bullet-point">
        <strong>Internships:</strong> Gain invaluable hands-on experience through internships in various STEM fields, enhancing your professional skills and expanding your network for future opportunities.
      </li>
      <li class="bullet-point">
        <strong>Volunteer Work:</strong> Contribute to STEM-related volunteer activities and projects to gain experience while giving back to the community, and feel the satisfaction of making a difference.
      </li>
    </ul>
  </div>
</div>


        {/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              This page provides an overview of the many experiential learning
              opportunities available to STEM students. By exploring the options
              listed here, you can find opportunities that align with your
              interests and career goals. For more detailed information and to
              find specific programs, internships, and research projects, please
              visit the{" "}
              <a
                className="link"
                href="https://www.smc.edu/student-support/career-services/get-the-job/stem/stem-experiential-opportunities/"
              >
                {" "}
                STEM Experiential Opportunities{" "}
              </a>{" "}
              page. This resource is designed to help you navigate and take
              advantage of the vast array of experiences that will enrich your
              education and prepare you for a successful career in STEM. Embrace
              these opportunities to expand your knowledge, develop your skills,
              and build a professional network that will support your future
              endeavors.
            </p>
          </div>
        </div>
        */}

      </div>
    </div>
  );
}

export default AllStemWorkOpportunities;
