import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import major1 from "../../../assets/header-images/major1.jpg";

function Majors() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={major1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Choosing a Major </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Choosing the right major is one of the most significant decisions you'll make during your college career. This choice can shape your professional journey and impact your personal satisfaction and financial stability. This chapter will guide you through the exploration of majors, emphasizing the unique advantages offered by community colleges, the reality of changing majors, and strategies for balancing passion with practicality. By utilizing various resources and adopting thoughtful decision-making strategies, you can make an informed choice that aligns with your academic and career aspirations.
    </p>
  </div>
</div>

{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 3: Choosing the Right Major{" "}
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}
      <div id="whole-section-page-container">


       <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        The Role of Community College in Exploring Majors
      </strong>
    </p>
    <p class="chapter-description">
      Community colleges provide a unique and valuable opportunity for students to explore different majors without the financial burden typically associated with four-year universities. With lower tuition costs and flexible course options, these institutions serve as an excellent platform for testing out various fields of study. Students can enroll in a diverse range of introductory courses in arts, sciences, business, and more, allowing them to gauge their interests and aptitudes. This exploration is vital as it leads to more informed decisions when choosing a major at a four-year institution.
    </p>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>The Reality of Changing Majors</strong>
    </p>
    <p class="chapter-description">
      Changing majors is a common part of the college experience. Many students find themselves switching majors within the first one to two years of their college journey, and some even make changes during their third year. This shift often stems from initial decisions made without fully understanding the major's content, related careers, or their own evolving interests. Recognizing that these changes are perfectly normal can help alleviate the pressure to make the "perfect" choice right away.
    </p>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Balancing Passion and Practicality</strong>
    </p>
    <p class="chapter-description">
      When selecting a major, it's essential to consider both your passions and the potential career opportunities that await you.
    </p>

    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Follow Your Interests:</strong> Engaging in a field you are passionate about is crucial for maintaining motivation and achieving academic success. Students tend to excel in their studies and actively pursue additional opportunities, such as internships and research projects, when they are genuinely interested in the subject matter.
      </li>
      <li class="bullet-point">
        <strong>Consider Career Prospects:</strong> While following your passion is vital, it's equally important to assess the career opportunities linked to your major. Research the job market and growth projections in your fields of interest. Consider how specific majors can lead to stable and financially rewarding careers by looking at potential salaries, industry demand, and the qualifications required for various roles.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Utilizing Resources for Major Selection</strong>
    </p>
  </div>

  <p class="chapter-description">
    Many resources are available to help students make informed decisions about their majors:
  </p>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Career Quizzes:</strong> Online career quizzes can be a great tool for students who are unsure about which major to choose. These quizzes typically assess a student's interests, strengths, and values, providing suggestions for majors and careers that align with their profiles. They can serve as a valuable starting point for further exploration and discussions with academic advisors and career counselors.
    </li>
    <li class="bullet-point">
      <strong>Academic Advisors and Career Centers:</strong> These resources provide valuable insights into the specifics of different majors and the careers they lead to. Academic advisors are particularly helpful in understanding the curriculum and graduation requirements, while career centers offer guidance on job prospects and internship opportunities.
    </li>
    <li class="bullet-point">
      <strong>Internships and Part-Time Jobs:</strong> Gaining practical experience in potential fields of interest can be incredibly illuminating. Internships and part-time jobs offer hands-on opportunities to understand what daily work in a field looks like and whether it aligns with your expectations and interests.
    </li>
    <li class="bullet-point">
      <strong>Job Shadowing and Informational Interviews:</strong> Speaking directly with professionals in your areas of interest can provide a realistic perspective on what various jobs entail and the paths taken to get there. Job shadowing allows you to observe a professional on the job, while informational interviews can answer specific questions about industries and career trajectories.
    </li>
  </ul>
</div>


        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Decision-Making Strategies</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-description">
      When deciding on a major, it’s important to use thoughtful strategies to guide your choice:
    </p>

    <li class="bullet-point">
      <strong>List Pros and Cons:</strong> For each major you're considering, make a list of the potential benefits and drawbacks as they relate to your personal goals, passions, and long-term career prospects. Weighing these factors will help you make a more informed decision.
    </li>
    <li class="bullet-point">
      <strong>Project Yourself into the Future:</strong> Imagine where you see yourself in ten years. Consider how each major may either support or hinder your ability to achieve those future goals. This exercise can help you evaluate the long-term impact of your choices.
    </li>
    <li class="bullet-point">
      <strong>Seek Feedback:</strong> Talk to family, friends, mentors, or professionals in your field of interest. Getting input from people who know you well or have relevant experience can offer new insights and perspectives that you might not have considered on your own.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Check Career Paths of That Major</strong>
    </p>
  </div>

  <p class="chapter-description">
    Understanding the career paths associated with a major can help you make a more informed decision about your future. Follow these steps to explore potential career options:
  </p>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Review the Course Curriculum:</strong> Examine the required courses and curriculum for the major. Familiarize yourself with the subjects you'll study and the skills you’ll develop, as this will provide insight into what to expect academically and professionally.
    </li>
    <li class="bullet-point">
      <strong>Research Job Prospects:</strong> Investigate the types of jobs that graduates with this major typically pursue. Websites like LinkedIn, Glassdoor, and the Bureau of Labor Statistics offer valuable information on job titles, descriptions, and market demand for specific roles.
    </li>
    <li class="bullet-point">
      <strong>Explore Future Job Market Trends:</strong> Evaluate the stability and growth potential of jobs related to the major. Research salary expectations, required qualifications, and future demand using resources like the 
      <a class="link" href="https://www.bls.gov/">  Bureau of Labor Statistics
      </a>. Understanding these trends can help you assess the long-term viability of your career choice.
    </li>
    <li class="bullet-point">
      <strong>LinkedIn Research:</strong> Utilize LinkedIn to search for alumni from your school who graduated with the major you’re considering. Review their career paths and job positions to determine if their roles align with your career aspirations and interests.
    </li>
  </ul>
</div>



{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 3: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Choosing a major is a significant decision that requires careful
              thought and planning. By taking advantage of the exploratory
              opportunities offered by community colleges, assessing both your
              interests and the practicality of your choices, and utilizing
              available resources, you can make a decision that aligns with both
              your personal aspirations and professional ambitions. Remember,
              it’s perfectly normal to change direction as you learn more about
              yourself and your chosen field, so approach this process with an
              open mind and a willingness to adapt.
            </p>
          </div>
        </div>



*/}        
      </div>
    </div>
  );
}

export default Majors;
