import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import employment from "../../../assets/header-images/employment.jpg";

function StudentWorkOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={employment} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Student Employment Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>


      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Balancing work and school can be tough, but finding the right job opportunities or side hustles can really help you manage your finances while gaining some valuable experience. These gigs not only provide financial support but also allow you to develop skills that’ll be super helpful in your future career. Below are various options you can explore to earn some cash during your college years.
        </p>
        <p id="base-page-introduction-text-main">
            NOTE: Here’s a selection of some of the most impactful work opportunities and side hustles to keep an eye on. While this list isn’t exhaustive, it highlights key options that can significantly contribute to your professional growth and financial stability.
        </p>
    </div>
</div>


      <div id="sections-container">

        
      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Research Opportunities</strong>
    </p>
    <p className="chapter-description">
      Participating in research projects offers a great way to gain hands-on experience, apply your knowledge, and work alongside professors and peers. Research can enhance your understanding of your field while also providing stipends or hourly wages, making it both an academic and financial benefit.
    </p>
    <p className="chapter-description">
      Research roles often help build important connections with faculty, which could lead to further academic opportunities, such as graduate school or future job prospects. To find research positions, talk to your professors or check with your university’s research departments for open projects.
    </p>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Work Study</strong>
    </p>
    <p className="chapter-description">
      Work-study programs allow students to take on part-time jobs, often on campus, to help cover college expenses. These positions are typically flexible, designed to fit around your class schedule, making them a convenient way to gain work experience while earning money.
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Federal Work-Study Program:</strong> Provides part-time jobs for students with financial need, enabling them to earn income to support their education costs. These roles often focus on community service or work related to a student’s field of study, adding valuable experience to their resume.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>College Corps</strong>
    </p>
    <p className="chapter-description">
      College Corps offers students the chance to engage in community service while earning money or educational awards. These programs not only provide financial support but also help students develop valuable skills and experience in fields like education, health, or environmental sustainability. The hands-on work you do through College Corps can enhance your resume and create meaningful connections within the community.
    </p>
  </div>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Side Hustles</strong>
    </p>
    <p className="chapter-description">
      Engaging in side hustles is a flexible way to earn extra money while also developing new skills. Here are some ideas to consider:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Selling Digital Products:</strong> Create and sell digital products, such as e-books, printables, or online courses. Platforms like Etsy and Gumroad can help you reach a wide audience.
      </li>
      <li className="bullet-point">
        <strong>Dropshipping:</strong> Start an online store without holding inventory. Use platforms like Shopify to set up your store and partner with suppliers who ship products directly to customers.
      </li>
      <li className="bullet-point">
        <strong>Amazon FBA:</strong> Fulfillment by Amazon (FBA) allows you to sell products on Amazon, with Amazon handling storage, packaging, and shipping. While this requires some initial investment, it can be quite profitable.
      </li>
      <li className="bullet-point">
        <strong>TikTok Shop Affiliate Marketing:</strong> Promote products on TikTok and earn commissions through affiliate marketing. Join affiliate programs and use your social media influence to drive sales.
      </li>
      <li className="bullet-point">
        <strong>Airbnb Rental:</strong> Rent out a spare room or property on Airbnb. While this side hustle requires more initial investment, it has the potential to generate significant income.
      </li>
      <li className="bullet-point">
        <strong>Print On Demand (Clothing Selling):</strong> Design and sell custom clothing and merchandise without holding inventory. Platforms like Printful or Teespring can handle printing and shipping for you.
      </li>
      <li className="bullet-point">
        <strong>Flipping Cars:</strong> Buy used cars, make necessary repairs, and sell them for a profit. This side hustle requires mechanical skills and initial capital investment.
      </li>
      <li className="bullet-point">
        <strong>Learning to Trade Stocks:</strong> Invest in stocks and learn to trade using platforms like Robinhood or E*TRADE to start growing your investments over time.
      </li>
      <li className="bullet-point">
        <strong>Create Online Content:</strong> Produce engaging content on popular social media platforms, monetizing through brand partnerships, sponsored posts, and TikTok’s Creator Fund.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Additional Work Opportunities</strong>
    </p>
    <p className="chapter-description">
      Here are some additional ideas to consider for earning money while developing valuable new skills:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Apple At-Home Advisor:</strong> Work from home providing customer support for Apple products. This position offers flexible hours and the ability to work remotely, making it a convenient option for students.
      </li>
      <li className="bullet-point">
        <strong>Sell Used Items:</strong> Sell items you no longer need on platforms like eBay, Facebook Marketplace, or Craigslist. This not only helps you declutter your space but also provides a way to earn extra cash while practicing sales and negotiation skills.
      </li>
    </ul>
  </div>
</div>


      </div>
    </div>
  );
}

export default StudentWorkOpportunities;
