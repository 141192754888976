import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function MasteringScholarshipInterview() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 5</p>
            <p id = "sub-scholarship-ch-header-text">Mastering the Scholarship Interview</p>
            <p id = "sub-scholarship-ch-text-two">Confidence is Key</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            This chapter equips you with essential skills to navigate scholarship interviews successfully, ensuring you leave a lasting positive impression. While not all scholarships require interviews, those that do provide a unique opportunity to showcase your personality, aspirations, and values in a way that goes beyond your written application. With the right preparation and mindset, you can confidently present yourself and highlight why you're an excellent fit for the scholarship.
        </p>
    </div>
</div>

      <div id="whole-section-page-container">

        
      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Understanding the Interview Process</strong>
    </p>
    <p className="chapter-description">
      While not all scholarships require interviews, those that do give you the chance to showcase your personality and goals in a more personal way, beyond your application.
    </p>
    <strong>Key Points:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Purpose:</strong> The interview helps the committee understand your motivations, personal values, and long-term goals, ensuring you're a good fit for the scholarship.
      </li>
      <li className="bullet-point">
        <strong>Format:</strong> Scholarship interviews may vary in format. You could face a one-on-one interview, a panel with multiple interviewers, or a group setting with other candidates.
      </li>
      <li className="bullet-point">
        <strong>Preparation:</strong> Research the organization’s mission and values, and be ready to connect your goals with theirs. Practice common interview questions and prepare examples that demonstrate your strengths and experiences.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Professional Attire: The Silent Communicator</strong>
    </p>
    <p className="chapter-description">
      Dressing professionally for both in-person and virtual interviews is crucial to making a strong first impression. Here are some tips to ensure you project confidence and professionalism:
    </p>

    <strong>In-Person Interview:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Business Attire:</strong> Wear a suit, dress, or business casual outfit that reflects a polished, professional image. Choose clothes that make you feel confident and comfortable.
      </li>
      <li className="bullet-point">
        <strong>Clean and Neat:</strong> Ensure your clothing is clean, wrinkle-free, and in good condition. Attention to detail shows that you take the interview seriously.
      </li>
    </ul>

    <strong>Virtual Interview:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Full Professional Dress:</strong> Dress professionally from head to toe, even if you're not fully visible on camera. This keeps you mentally prepared and professional in case you need to stand up or adjust during the call.
      </li>
    </ul>

    <strong>General Tips:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Minimal Jewelry and Fragrances:</strong> Keep jewelry simple and avoid strong fragrances to prevent any distractions or discomfort during the interview.
      </li>
      <li className="bullet-point">
        <strong>Neutral Colors:</strong> Opt for neutral tones like black, navy, gray, or white. These colors create a calm, professional appearance that doesn’t overpower your personality.
      </li>
      <li className="bullet-point">
        <strong>Neat Hairstyle:</strong> Ensure your hair is well-groomed and styled neatly. A professional hairstyle enhances your overall look and shows attention to detail.
      </li>
    </ul>

    <p className="chapter-description">
      <strong>Note:</strong> If you don’t have access to professional attire, ask your college counselor about resources. Many schools offer clothing closets or voucher programs to help students dress professionally for interviews.
    </p>
    <p className="chapter-description">
      <strong>Remember:</strong> The goal is to project professionalism and confidence, letting your qualifications and personality shine through.
    </p>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Strategies for Interview Success</strong>
    </p>
    <p className="chapter-description">
      Preparation is key! Follow these steps to maximize your chances of success during the interview:
    </p>

    <strong>Research Common Behavioral Questions:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        Prepare thoughtful responses that highlight your strengths, relevant experiences, and unique qualities. Tailor your answers to the values of the organization.
      </li>
      <li className="bullet-point">
        <strong>Example Questions:</strong>
      </li>
      <ul className="bullet-point-list">
        <li className="bullet-point">"Tell me about yourself."</li>
        <li className="bullet-point">"What are your greatest strengths/weaknesses?"</li>
        <li className="bullet-point">"What are your goals?"</li>
      </ul>
    </ul>

    <strong>Practice Makes Perfect:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Rehearse Responses:</strong> Practice answering key questions with peers, mentors, or even in front of a mirror to fine-tune your delivery and build confidence.
      </li>
      <li className="bullet-point">
        <strong>Mock Interviews:</strong> Participate in mock interviews to simulate the real experience, helping you feel more comfortable with the process and pacing.
      </li>
    </ul>

    <strong>Be Authentic and Articulate:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Authenticity:</strong> Present yourself genuinely—confidence, sincerity, and honesty leave a lasting impression. Authentic answers resonate more than over-rehearsed responses.
      </li>
      <li className="bullet-point">
        <strong>Articulation:</strong> Speak clearly and concisely. Organize your thoughts to ensure your points are well-structured and easy to follow.
      </li>
    </ul>
  </div>
</div>


        
      </div>
    </div>
  );
}

export default MasteringScholarshipInterview;
