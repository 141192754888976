import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";

import preparing from "../../../assets/header-images/preparing.jpg";

import "../../../css/SubPage/SubPage.css";

function BeforeCollege() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={preparing} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Preparing for College Life </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Starting college is a significant milestone that brings excitement and challenges. Being well-prepared can make your transition smoother and set you up for success. This guide provides practical advice and tips to help you navigate your journey from high school to college, ensuring you are ready for what lies ahead. Embrace this new chapter with confidence, knowing that the right preparations can lead to a rewarding college experience.
        </p>
    </div>
</div>


      <div id="sections-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Things to Know Before Starting College</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Follow Your Passion:</strong>
      </p>
      <li class="bullet-point">
        <strong>Pursue Your Interests:</strong> While it’s important to follow your passion, ensure it can also support you financially after graduation. College is a fantastic time for self-discovery and personal growth.
      </li>
      <p class="chapter-sub-title">
        <strong>Plan Well:</strong>
      </p>
      <li class="bullet-point">
        <strong>Early Planning:</strong> Tackle your distribution requirements early to avoid last-minute stress. This approach will give you more flexibility in later semesters to focus on your major and electives.
      </li>
      <li class="bullet-point">
        <strong>Academic Advising:</strong> Make it a habit to regularly meet with academic advisors to stay on track with your degree requirements and explore various options.
      </li>
      <p class="chapter-sub-title">
        <strong>Be Flexible:</strong>
      </p>
      <li class="bullet-point">
        <strong>Adaptability:</strong> Stay open to changes and new opportunities. College life can be unpredictable, so being adaptable will help you make the most of your experience.
      </li>
      <li class="bullet-point">
        <strong>Backup Plans:</strong> Always have contingency plans for classes, housing, and extracurricular activities to keep stress at bay.
      </li>
      <p class="chapter-sub-title">
        <strong>Step Outside Your Comfort Zone:</strong>
      </p>
      <li class="bullet-point">
        <strong>New Activities:</strong> Regularly try new activities! Join clubs, attend events, and meet new people to broaden your horizons and enrich your college experience.
      </li>
      <li class="bullet-point">
        <strong>Networking:</strong> Take advantage of networking opportunities to build relationships with peers, professors, and professionals.
      </li>
      <p class="chapter-sub-title">
        <strong>Find an Upperclassman to Follow:</strong>
      </p>
      <li class="bullet-point">
        <strong>Guidance:</strong> Seek out an upperclassman who shares your goals and can provide guidance on navigating college life, choosing courses, and balancing academics with extracurriculars.
      </li>
      <li class="bullet-point">
        <strong>New Activities:</strong> Follow their lead in trying new activities, joining clubs, and attending events to further enrich your college experience.
      </li>
      <li class="bullet-point">
        <strong>Networking:</strong> Use their advice to capitalize on networking opportunities and build relationships that will benefit you throughout your college journey.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>College Housing</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>On-Campus Housing:</strong>
      </p>
      <li class="bullet-point">
        <strong>Dorms:</strong> Traditional dormitories feature shared rooms and communal facilities that create a vibrant community atmosphere. Living in dorms enhances your college experience through social interactions and activities.
      </li>
      <li class="bullet-point">
        <strong>Suites and Apartments:</strong> More private accommodations with shared common areas, offering a balance of independence and community. These options are ideal for students seeking a quieter living environment.
      </li>
      <li class="bullet-point">
        <strong>Living-Learning Communities:</strong> Themed housing options centered around specific academic or social interests, promoting collaboration and networking among like-minded peers.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Off-Campus Housing:</strong>
      </p>
      <li class="bullet-point">
        <strong>Nearby Apartments:</strong> Rentals located within walking or a short driving distance from campus, providing convenience for daily commutes and easy access to campus resources.
      </li>
      <li class="bullet-point">
        <strong>Commute Options:</strong> Explore methods such as public transportation, biking, or driving to reach campus efficiently, based on your personal preferences.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Finding Roommates:</strong>
      </p>
      <li class="bullet-point">
        <strong>University Reddit:</strong> Use your university's Reddit page to find potential roommates or get housing recommendations from fellow students with similar experiences.
      </li>
      <li class="bullet-point">
        <strong>Roommate Matching Services:</strong> Access school-provided or independent services that help you find compatible roommates based on preferences and lifestyles for a smoother living arrangement.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Housing Tips:</strong>
      </p>
      <li class="bullet-point">
        <strong>Lease Agreements:</strong> Always read and understand lease agreements thoroughly before signing. Pay attention to terms regarding deposits, maintenance, and lease duration to avoid potential issues.
      </li>
      <li class="bullet-point">
        <strong>Budgeting:</strong> Factor in all costs, including rent, utilities, and transportation, to create a realistic budget that helps you manage your finances effectively throughout the academic year.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Join Online Communities</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Connect with Fellow Students:</strong>
      </p>
      <li class="bullet-point">
        <strong>Discord Groups:</strong> Join Discord groups specific to your class year to meet fellow students, share experiences, and stay informed about campus events and activities.
      </li>
      <li class="bullet-point">
        <strong>Instagram:</strong> Follow class year-specific Instagram accounts for updates, announcements, and networking opportunities with peers.
      </li>
      <li class="bullet-point">
        <strong>Reddit:</strong> Participate in Reddit communities for your university to seek advice, share insights, and connect with other students facing similar challenges.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Online Resources:</strong>
      </p>
      <li class="bullet-point">
        <strong>College Forums:</strong> Engage in college forums and chat groups where you can ask questions, share experiences, and learn from others.
      </li>
      <li class="bullet-point">
        <strong>Social Media:</strong> Use various social media platforms to join student groups, stay updated on campus activities, and enhance your network.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Look into Major Requirements</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Academic Planning:</strong>
      </p>
      <li class="bullet-point">
        <strong>Course Requirements:</strong> If you have a clear field of study in mind, review the course requirements for that major or minor. If you're undecided, take the time to explore various department pages to familiarize yourself with their offerings and prerequisites.
      </li>
      <li class="bullet-point">
        <strong>Four-Year Plan:</strong> Create a comprehensive schedule for the next four years to ensure you meet all prerequisites and graduation requirements on time.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Action Steps:</strong>
      </p>
      <li class="bullet-point">
        <strong>Visit Academic Websites:</strong> Navigate to your college’s academic website for detailed information on programs and requirements.
      </li>
      <li class="bullet-point">
        <strong>Review Course Catalog:</strong> Check the course catalog for specific major and minor requirements to understand the classes you need to take.
      </li>
      <li class="bullet-point">
        <strong>Prerequisite Courses:</strong> Identify any prerequisite courses necessary for your desired major to ensure you're prepared for upper-level classes.
      </li>
      <li class="bullet-point">
        <strong>Consult Advisors:</strong> Regularly meet with academic advisors to refine your academic plan and address any questions you may have.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Look into Clubs</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Research and Engagement:</strong>
      </p>
      <li class="bullet-point">
        <strong>Club Listings:</strong> Many colleges maintain a web page featuring their active and popular clubs. To prepare for the club fair, search online for your college's name followed by the terms "clubs" or "organizations." This will give you a head start on identifying clubs that interest you.
      </li>
      <li class="bullet-point">
        <strong>Club Fair:</strong> Typically held at the beginning of the year during the Fall Semester, attending the club fair is a great opportunity to explore various organizations and find those that align with your personal interests and goals.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Action Steps:</strong>
      </p>
      <li class="bullet-point">
        <strong>Research:</strong> Investigate clubs and organizations that relate to your interests by browsing their Instagram pages and websites for more information.
      </li>
      <li class="bullet-point">
        <strong>Make a List:</strong> Compile a list of clubs you’re interested in joining to keep track of your options.
      </li>
      <li class="bullet-point">
        <strong>Attend Club Fair:</strong> Take the time to visit club booths during the club fair to gather more detailed information about each organization.
      </li>
      <li class="bullet-point">
        <strong>Reach Out:</strong> Don’t hesitate to contact club leaders or current members to learn more about their activities and get guidance on how to join.
      </li>
    </ul>
  </div>
</div>




      </div>
    </div>
  );
}

export default BeforeCollege;
