import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import essentialtips from "../../../assets/header-images/essentialtips.jpg";

import "../../../css/SubPage/SubPage.css";

function CollegeJourney() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={essentialtips} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Essential College Tips </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container"> <div id="base-page-introduction-text-container"> <div id="base-page-introduction-title-container"> <h2 id="base-page-intro-title">Introduction</h2> </div> <p id="base-page-introduction-text-main"> Starting college is not just an exciting milestone; it’s a transformative journey filled with opportunities for growth and self-discovery. This guide is crafted to equip you with practical tips and insights, helping you navigate the complexities of college life—from excelling academically to forging meaningful relationships. Whether you're stepping into your first semester or preparing for graduation, the resources and advice here will empower you to make the most of this pivotal time in your life. Embrace this journey with confidence and purpose, and set the stage for a successful college experience. </p> </div> </div>

      <div id="sections-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Excel In School</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Study Tips:</strong>
      </p>
      <li class="bullet-point">
        <strong>Active Learning:</strong> Engage with the material through discussions, practice problems, and teaching others. This not only reinforces concepts but also enhances retention and understanding.
      </li>
      <li class="bullet-point">
        <strong>Regular Review:</strong> Allocate time each week to revisit notes and readings. Consistent review strengthens learning and aids long-term memory retention.
      </li>
      <li class="bullet-point">
        <strong>Group Study:</strong> Collaborate with classmates to deepen your understanding and share different perspectives. Group study sessions can clarify difficult concepts and inspire new ideas.
      </li>
      <p class="chapter-sub-title">
        <strong>Time Management:</strong>
      </p>
      <li class="bullet-point">
        <strong>Create a Schedule:</strong> Utilize a planner or digital calendar to organize your time effectively. Schedule study sessions, classes, and personal commitments to maintain a healthy balance.
      </li>
      <li class="bullet-point">
        <strong>Prioritize Tasks:</strong> Focus on high-priority assignments first to ensure important deadlines are met, helping to reduce stress and enhance productivity.
      </li>
      <li class="bullet-point">
        <strong>Break Tasks into Smaller Steps:</strong> Make large projects more manageable by dividing them into smaller, actionable tasks. This method prevents overwhelm and boosts overall productivity.
      </li>
      <li class="bullet-point">
        <strong>Use Time Management Tools:</strong> Tools like Google Calendar can help you organize daily and weekly tasks effectively, keeping you on track.
      </li>
      <p class="chapter-sub-title">
        <strong>Academic Resources:</strong>
      </p>
      <li class="bullet-point">
        <strong>Libraries:</strong> Take advantage of campus libraries for quiet study spaces and access to valuable academic resources. Libraries often provide an environment conducive to focused studying.
      </li>
      <li class="bullet-point">
        <strong>Tutoring Centers:</strong> Utilize tutoring centers for assistance with challenging subjects. Tutors can offer additional explanations, strategies, and study techniques tailored to your needs.
      </li>
      <li class="bullet-point">
        <strong>Office Hours:</strong> Attend professors' office hours to ask questions and seek clarification on course material. Building relationships with professors can also create networking opportunities for future reference letters.
      </li>
      <li class="bullet-point">
        <strong>Rate My Professors:</strong> Check reviews for potential tutors and professors to find those who align with your learning style and preferences.
      </li>
      <li class="bullet-point">
        <strong>Reddit:</strong> Engage with Reddit communities to discover study groups and course recommendations. Your university's Reddit community can be a valuable resource.
      </li>
      <p class="chapter-sub-title">
        <strong>Goal Setting:</strong>
      </p>
      <li class="bullet-point">
        <strong>Track Progress:</strong> Regularly review your goals and adjust them as needed. Monitoring your progress helps keep you on track and allows for necessary changes.
      </li>
      <li class="bullet-point">
        <strong>Celebrate Achievements:</strong> Reward yourself for reaching milestones, as celebrating achievements boosts motivation and encourages continued effort.
      </li>
      <p class="chapter-sub-title">
        <strong>Tools:</strong>
      </p>
      <li class="bullet-point">
        <strong>Chegg:</strong> Access study help, textbook rentals, and homework assistance. Chegg provides resources for various subjects and can be invaluable for academic support.
      </li>
      <li class="bullet-point">
        <strong>Quizlet:</strong> Utilize flashcards and interactive study games to enhance memorization and understanding of key concepts.
      </li>
      <li class="bullet-point">
        <strong>Math Resources:</strong> Leverage online math resources for additional practice and tutorials. Websites like Khan Academy offer comprehensive tutorials for a variety of math topics.
      </li>
      <p class="chapter-sub-title">
        <strong>Free Book Websites:</strong>
      </p>
      <li class="bullet-point">
        <strong>ThriftBooks:</strong> Purchase affordable used books. ThriftBooks offers a budget-friendly way to obtain textbooks and other reading materials.
      </li>
      <p class="chapter-sub-title">
        <strong>How to Maintain a Good GPA:</strong>
      </p>
      <li class="bullet-point">
        <strong>Attend All Classes:</strong> Regular attendance is essential for grasping material and participating in discussions. Being present helps you keep up with the course content and engage actively.
      </li>
      <li class="bullet-point">
        <strong>Stay Organized:</strong> Keep meticulous track of assignments, tests, and deadlines. Organization prevents last-minute cramming and helps avoid missed deadlines.
      </li>
      <li class="bullet-point">
        <strong>Seek Help When Needed:</strong> Don’t hesitate to ask for assistance from professors, tutors, or classmates. Seeking help early can prevent falling behind and clarify any confusing topics.
      </li>
      <p class="chapter-sub-title">
        <strong>How to Plan Your Schedule:</strong>
      </p>
      <li class="bullet-point">
        <strong>Balance Your Courses:</strong> Mix challenging courses with easier ones each semester to maintain a manageable workload. Balancing your course load can help alleviate stress.
      </li>
      <li class="bullet-point">
        <strong>Include Breaks:</strong> Schedule regular breaks to avoid burnout and maintain productivity. Taking breaks can help recharge your focus and energy.
      </li>
      <li class="bullet-point">
        <strong>Consider Your Energy Levels:</strong> Plan challenging tasks for when you are most alert and focused. Aligning tasks with your natural energy levels enhances overall performance.
      </li>
      <p class="chapter-sub-title">
        <strong>Health and Wellbeing:</strong>
      </p>
      <li class="bullet-point">
        <strong>Exercise:</strong> Regular physical activity can boost your mental health and improve academic performance.
      </li>
      <li class="bullet-point">
        <strong>Nutrition:</strong> Maintain a balanced diet to stay energized and focused throughout your studies.
      </li>
      <li class="bullet-point">
        <strong>Mental Health:</strong> Take advantage of campus counseling services and prioritize self-care to manage stress effectively.
      </li>
      <p class="chapter-sub-title">
        <strong>Financial Planning:</strong>
      </p>
      <li class="bullet-point">
        <strong>Budgeting:</strong> Develop a budget to manage your expenses and avoid unnecessary debt during your college years.
      </li>
      <li class="bullet-point">
        <strong>Financial Aid:</strong> Investigate scholarships, grants, and work-study opportunities to help finance your education effectively.
      </li>
      <p class="chapter-sub-title">
        <strong>Building Relationships:</strong>
      </p>
      <li class="bullet-point">
        <strong>Professors:</strong> Foster relationships with your professors by attending office hours and engaging in class discussions. 
      </li>
      <li class="bullet-point">
        <strong>Peers:</strong> Build friendships and study groups to enhance your college experience and academic support system.
      </li>
    </ul>
  </div>
</div>




      </div>
    </div>
  );
}

export default CollegeJourney;
