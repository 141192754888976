import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function PostAwardSuccess() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 6</p>
            <p id = "sub-scholarship-ch-header-text">Post-Award Success Strategies</p>
            <p id = "sub-scholarship-ch-text-two">How to Maximize the Benefits</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            This chapter dives into the important aspects of managing your scholarship awards effectively, from expressing gratitude to maximizing your financial benefits. Scholarships are more than just funds; they represent an opportunity to further your education without the stress of financial burdens. By understanding how to navigate these awards, you can ensure that you get the most out of your scholarship experience.
        </p>
    </div>
</div>


      <div id="whole-section-page-container">


        
      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Expressing Gratitude</strong>
    </p>
    <p className="chapter-description">
      Upon receiving a scholarship award notification, it’s important to promptly express your gratitude and follow any instructions related to fund disbursement. This demonstrates professionalism and appreciation.
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Prompt Response:</strong> Send a thank-you email or letter as soon as you receive the award notification. Timely acknowledgment shows respect and appreciation for the opportunity.
      </li>
      <li className="bullet-point">
        <strong>Follow Instructions:</strong> Read and follow any instructions provided by the scholarship committee regarding next steps, such as submitting additional documents or coordinating the fund disbursement process.
      </li>
      <li className="bullet-point">
        <strong>Maintain Professionalism:</strong> Keep your communication respectful, professional, and appreciative. A well-written message strengthens your relationship with the scholarship provider.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Effective Management of Scholarship Funds</strong>
    </p>
    <p className="chapter-description">
      It’s important to understand the process of receiving and managing your scholarship funds, whether they’re deposited directly to you, sent to your school, or provided as a check.
    </p>
    
    <strong>Receiving Scholarship Funds:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Direct Deposit to You:</strong> If the scholarship check is made out to you, deposit it into your personal bank account. 
        <b> Note:</b> Some sources suggest you don’t need to report all scholarships to your school to avoid potential complications with your financial aid package.
      </li>
      <li className="bullet-point">
        <strong>Check Made Out to Your School:</strong> Visit your school’s financial aid office to have the check deposited directly into your student account.
      </li>
      <li className="bullet-point">
        <strong>Direct Deposit to the School:</strong> If the funds are sent straight to your school, monitor your financial aid portal to confirm receipt without needing to take additional steps.
      </li>
    </ul>

    <strong>Tips for Managing Funds:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Budget Wisely:</strong> Plan a budget to ensure your scholarship money lasts throughout the academic year and covers essential expenses.
      </li>
      <li className="bullet-point">
        <strong>Track Expenses:</strong> Keep a detailed record of how you spend the funds, especially if the scholarship comes with specific usage guidelines or stipulations.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Appeal for Cost of Attendance Increase</strong>
    </p>
    <p className="chapter-description">
      If you’ve been awarded multiple scholarships, you might want to consider appealing for a cost of attendance (COA) increase to prevent reductions in your financial aid package.
    </p>

    <strong>Steps to Appeal:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Request a Budget Increase:</strong> Visit your financial aid office and ask for an increase in your cost of attendance to better reflect your actual expenses.
      </li>
      <li className="bullet-point">
        <strong>Prevent Reductions:</strong> Clearly explain that this request is to prevent reductions in your financial aid due to receiving multiple scholarships.
      </li>
      <li className="bullet-point">
        <strong>Understand the Limits:</strong> Be aware that there are limits to how much your budget can increase, but this process can help preserve both your scholarship awards and your FAFSA grant.
      </li>
    </ul>

    <strong>Tips for Successful Appeals:</strong>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Prepare Documentation:</strong> Gather all necessary documents, including scholarship award letters and any other financial paperwork that supports your request.
      </li>
      <li className="bullet-point">
        <strong>Communicate Clearly:</strong> When presenting your case, clearly explain why the increase is needed and how it will help you manage your educational expenses.
      </li>
      <li className="bullet-point">
        <strong>Follow Up:</strong> Keep in contact with the financial aid office to monitor the status of your appeal and ensure nothing is overlooked.
      </li>
    </ul>
  </div>
</div>


      </div>
    </div>
  );
}

export default PostAwardSuccess;
