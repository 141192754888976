import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Tools/Ai.css";
import {
  getUserProfileData,
  getUserFinancialData,
} from "../../firebase/firebaseService";
import { auth } from "../../firebase/firebase";
import { GoogleGenerativeAI } from "@google/generative-ai";
import rob1 from "../../assets/header-images/rob1.jpg";
import ReactMarkdown from "react-markdown";
import { TypeAnimation } from "react-type-animation";

const API_KEY = process.env.REACT_APP_GEMINI_APIKEY;
const genAI = new GoogleGenerativeAI(API_KEY);
const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash-8b" });

function ChatbotModal() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([
    {
      message: `Hello! I'm Aza, your personalized college application assistant. How can I help you today? Here are some options:

1. **Exploring Majors and Careers**
2. **Build Your School List**
3. **Chance Your Acceptance**
4. **Essay Writing and Brainstorming**
5. **Extracurricular Writing and Brainstorming**
6. **Customized Roadmap for College Applications**
7. **Scholarships Guidance**
8. **Early Career Resources**
9. **Something else? Let me know!**

Please check out the CollegeNav website content for any specific resources or information.
      `,
      direction: "incoming",
      sender: "Aza",
      isMarkdown: true,
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [financialData, setFinancialData] = useState(null);
  const [inputText, setInputText] = useState("");
  const abortController = useRef(null);
  const messagesEndRef = useRef(null);

  const handleStop = () => {
    if (abortController.current) {
      abortController.current.abort();
      setIsTyping(false);
    }
  };

  const handleSend = async () => {
    if (inputText.trim() === "") return;

    const newMessage = {
      message: inputText,
      direction: "outgoing",
      sender: "user",
      isMarkdown: true,
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputText("");
    setIsTyping(true);

    await processMessageToGemini(inputText);
  };

  const processMessageToGemini = async (userInput) => {
    if (userInput.trim() === "") return;

    abortController.current = new AbortController();

    try {
      let personalizedMessage = `${userInput}\n\n`;

      if (userData) {
        personalizedMessage += `
Background Information:
- **Name**: ${userData?.name || "Unknown"}
- **Gender**: ${userData?.gender || "Unknown"}
- **Date of Birth**: ${userData?.dateOfBirth || "Unknown"}
- **University**: ${userData?.university || "Unknown"}
- **Major**: ${userData?.major || "Unknown"}
- **Educational Status**: ${userData?.educationalStatus || "Unknown"}
- **GPA**: ${userData?.gpa || "Unknown"}
- **Career Goal**: ${userData?.careerGoal || "Unknown"}
- **Location**: ${userData?.location?.city || "Unknown"}, ${
          userData?.location?.state || "Unknown"
        }
- **Race**: ${userData?.race || "Unknown"}
- **Demographics**: ${userData?.demographics?.join(", ") || "None"}
`;
      }

      if (financialData) {
        personalizedMessage += `
Financial Information:
- **Household Income**: ${financialData?.householdIncome || "Unknown"}
- **Financial Aid (FAFSA)**: ${financialData?.financialAid?.fafsa || "Unknown"}
- **State Grant**: ${financialData?.financialAid?.stateGrant || "Unknown"}
- **Scholarship**: ${financialData?.financialAid?.scholarship || "Unknown"}
- **Loan**: ${financialData?.financialAid?.loan || "Unknown"}
`;
      }

      const prompt = `If the student's profile information is needed, using the following detailed student profile:

${personalizedMessage}

Please provide a personalized and clear response to the query: ${userInput}

Guidelines:
- Tailor advice based on the question. Use student-specific details when provided, such as academic background, financial situation, or career goals.
- Provide practical and actionable steps to help the student make informed decisions.
- Use bullet points for clarity and bold key details for emphasis.
- Keep responses focused and concise for simple questions, expanding on more complex topics as needed.
- When applicable, suggest useful resources or tools that align with the student's needs (e.g., scholarship search tools, application checklists, career planning resources).
- Stay encouraging and positive, offering support while giving realistic guidance.

Adjust the length of the response based on the complexity of the query. Keep it concise but thorough when needed.
`;

      const result = await model.generateContent(
        {
          contents: [{ role: "user", parts: [{ text: prompt }] }],
        },
        { signal: abortController.current.signal }
      );

      const responseText = await result.response.text();

      setMessages((prev) => [
        ...prev,
        {
          message: responseText,
          direction: "incoming",
          sender: "Aza",
          isMarkdown: true,
        },
      ]);
    } catch (error) {
      if (error.name === "AbortError") {
        setMessages((prev) => [
          ...prev,
          {
            message: "Response stopped by user.",
            direction: "incoming",
            sender: "Aza",
            isMarkdown: false,
          },
        ]);
      } else {
        console.error("Error:", error);
        setMessages((prev) => [
          ...prev,
          {
            message: "Sorry, I encountered an error. Please try again.",
            direction: "incoming",
            sender: "Aza",
            isMarkdown: false,
          },
        ]);
      }
    } finally {
      setIsTyping(false);
      abortController.current = null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser) {
        navigate("/login-prompt");
        return;
      }

      setLoading(true);
      try {
        const [userProfileData, userFinancialData] = await Promise.all([
          getUserProfileData(),
          getUserFinancialData(),
        ]);
        setUserData(userProfileData);
        setFinancialData(userFinancialData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <div id="aza-body">
      <div id="Ch-face">
        <img id="header-image" src={rob1} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p className="TOC-header-text">Aza (AI Essay Assistant)</p>
          </div>
        </div>
      </div>

      <div className="outer-container">
        <div className="chat-window-container">
          <div className="chat-window">
            <div className="message-list">
              {messages.map((message, i) => (
                <div
                  key={i}
                  className={`message ${
                    message.direction === "outgoing"
                      ? "user-message"
                      : "bot-message"
                  }`}
                >
                  <div className="message-text">
                    {message.isMarkdown ? (
                      <ReactMarkdown>{message.message}</ReactMarkdown>
                    ) : (
                      <TypeAnimation
                        sequence={[message.message]}
                        wrapper="span"
                        speed={90}
                        omitDeletionAnimation={true}
                        repeat={0}
                      />
                    )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="input-container">
            <input
              className="input-field"
              placeholder="Type your message here..."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
            />
            <button
              className="send-button"
              onClick={isTyping ? handleStop : handleSend}
            >
              {isTyping ? "Stop" : "Send"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatbotModal;
