import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import maximizing from "../../../assets/header-images/maximizing.jpg";

import "../../../css/SubPage/SubPage.css";

function SchoolBenefits() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={maximizing} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Maximizing School Benefits </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Starting college is a huge milestone, full of excitement and a few challenges. Getting prepared can really help make this transition smoother and set you up for success. This guide is here to offer some practical advice and tips to help you navigate your journey from high school to college. Whether you’re tackling your first semester or getting ready to graduate, you’ll find helpful resources to support your journey. Dive into the sections below to enhance your college experience and make the most out of this important time in your life.
        </p>
    </div>
</div>



      <div id="sections-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Campus Facilities</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Libraries:</strong> Access a vast collection of books, journals, and digital resources in quiet study spaces. Libraries also provide study rooms, printing services, and academic databases essential for research and coursework.
      </li>
      <li class="bullet-point">
        <strong>Sports Facilities:</strong> Enjoy gyms, swimming pools, and sports fields that promote fitness and well-being. These facilities offer classes and intramural sports to keep you active and engaged.
      </li>
      <li class="bullet-point">
        <strong>Laboratories and Research Centers:</strong> Utilize state-of-the-art labs and research centers for hands-on experience in your field, fostering innovation through advanced equipment and technology.
      </li>
      <li class="bullet-point">
        <strong>Student Centers:</strong> Relax and socialize at student centers, which house dining options, recreational activities, and event spaces, creating a lively hub for campus life.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Student Services</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Academic Advising:</strong> Get guidance on course selection, degree requirements, and career planning. Academic advisors help you create a personalized plan and navigate challenges to make informed decisions about your education and career goals.
      </li>
      <li class="bullet-point">
        <strong>Counseling Services:</strong> Receive support for mental health, stress management, and personal development. Counseling services offer individual and group therapy sessions, workshops, and resources to help you manage stress and achieve emotional well-being.
      </li>
      <li class="bullet-point">
        <strong>Tutoring and Writing Centers:</strong> Access assistance with coursework, writing skills, and exam preparation. These centers provide personalized tutoring, writing workshops, and study groups to help you excel academically and develop strong writing and analytical skills.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Health and Wellness</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Campus Health Clinics:</strong> Access medical services, including routine check-ups, vaccinations, and urgent care. Health clinics provide essential healthcare to students, promoting overall physical well-being on campus.
      </li>
      <li class="bullet-point">
        <strong>Counseling and Psychological Services (CAPS):</strong> Receive mental health support through counseling, workshops, and group therapy. CAPS offers confidential counseling services, crisis intervention, and resources to support your emotional and psychological well-being.
      </li>
      <li class="bullet-point">
        <strong>Recreational Activities:</strong> Join fitness classes, intramural sports, and wellness programs that promote physical health. Engaging in these activities helps reduce stress, improve fitness, and build a sense of community among students.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Diversity & Inclusion Resources</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Cultural Centers:</strong> Dedicated spaces that celebrate and support diverse cultural identities. These centers offer programs, events, and resources to promote cultural awareness, understanding, and inclusion on campus.
      </li>
      <li class="bullet-point">
        <strong>Diversity Programs:</strong> Initiatives aimed at fostering inclusivity and equity. These programs address diversity issues and create an environment that respects and values all individuals.
      </li>
      <li class="bullet-point">
        <strong>Support Services:</strong> Resources for underrepresented and marginalized groups, providing advocacy, mentorship, and support to ensure equal access to educational and social opportunities for all students.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Financial Benefits</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>CalFresh:</strong> Assistance for eligible students to receive food benefits and access healthy meals, helping those facing food insecurity maintain a nutritious diet.
      </li>
      <li class="bullet-point">
        <strong>Microsoft Office:</strong> Free access to Microsoft Office applications, including Word, Excel, PowerPoint, and OneNote—essential tools for coursework and collaboration.
      </li>
      <li class="bullet-point">
        <strong>Adobe Creative Cloud:</strong> Free access to Adobe applications like Acrobat, Photoshop, and Illustrator, useful for academic projects and developing digital media skills.
      </li>
      <li class="bullet-point">
        <strong>Additional Free Software:</strong> Many universities provide access to various free software to enhance your learning experience. Check your university website for details.
      </li>
      <li class="bullet-point">
        <strong>Laptop Grants:</strong> Some schools, like UCLA, offer funds for high-achieving students to purchase laptops, reducing the financial burden of essential technology.
      </li>
      <li class="bullet-point">
        <strong>Free Bus Pass:</strong> Many universities offer complimentary public transportation passes for students, making commuting more convenient and affordable.
      </li>
      <li class="bullet-point">
        <strong>Handshake:</strong> A platform for finding internships, part-time jobs, and career opportunities, connecting students with potential employers.
      </li>
      <li class="bullet-point">
        <strong>Amazon Student Prime:</strong> Discounts on Amazon Prime membership, including free shipping and access to Prime Video, which can save you money on textbooks and supplies.
      </li>
      <li class="bullet-point">
        <strong>GitHub Education Pack:</strong> Access to GitHub Premium and additional tools for coding and development, enhancing your technical skills and project management capabilities.
      </li>
    </ul>
  </div>
</div>


      </div>
    </div>
  );
}

export default SchoolBenefits;
