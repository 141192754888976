import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import understanding from "../../../assets/header-images/understanding.jpg";

function FinancialAid() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={understanding} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Understanding Financial Aid </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            Navigating the world of financial aid can be crucial for many students, as it makes higher education more accessible and affordable. This guide will break down the different types of financial aid available, the application processes, and some helpful tips for managing student loan debt. From government programs and scholarships to grants and work-study options, there are plenty of resources to help you achieve your academic goals without the heavy financial burden.
        </p>
    </div>
</div>


      <div id="whole-section-page-container">


      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Types of Financial Aid</strong>
    </p>
    <p className="chapter-description">
      Financial aid is available in various forms, each with its own purpose and application process. Understanding these different types is essential for students aiming to make informed decisions about funding their education. Below is an overview of the primary types of financial aid, including their characteristics and benefits.
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Grants:</strong> Grants are need-based financial awards provided by federal and state governments, as well as colleges. Unlike loans, grants do not need to be repaid, making them a highly sought-after form of financial assistance for students with demonstrated financial need.
    </p>
    <li className="bullet-point">
      <strong>Federal Pell Grant:</strong> Awarded to undergraduate students with significant financial need. This grant is one of the most widely available forms of federal aid.
    </li>
    <li className="bullet-point">
      <strong>State-Specific Grants:</strong> Many states offer additional grants to residents. Students can explore their state’s higher education website for available opportunities.
    </li>
    <li className="bullet-point">
      <strong>Federal Supplemental Educational Opportunity Grant (FSEOG):</strong> Designed for students with exceptional financial need, this grant is often provided in addition to the Pell Grant.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Scholarships:</strong> Scholarships are awarded based on merit, financial need, or specific qualifications such as community involvement. Like grants, scholarships typically do not require repayment, making them a valuable resource for students looking to minimize debt.
    </p>
    <li className="bullet-point">
      <strong>Academic Scholarships:</strong> Granted to students based on their academic achievements, such as high GPA or standardized test scores.
    </li>
    <li className="bullet-point">
      <strong>Athletic Scholarships:</strong> Offered to students who demonstrate exceptional athletic ability. These scholarships often require the student to participate in collegiate athletics.
    </li>
    <li className="bullet-point">
      <strong>Community Scholarships:</strong> These scholarships are funded by local organizations or specific communities and are awarded to students who meet particular criteria related to community involvement or background.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Work-Study Programs:</strong> Work-study programs provide part-time employment to students, typically on-campus, allowing them to earn money to cover educational expenses. These positions often offer flexible hours that accommodate a student’s class schedule.
    </p>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Loans:</strong> Loans are borrowed funds that must be repaid with interest. Federal loans usually offer more favorable terms, such as lower interest rates and flexible repayment plans, compared to private loans.
    </p>
    <li className="bullet-point">
      <strong>Federal Direct Subsidized Loans:</strong> Available to undergraduate students with financial need. The federal government pays the interest while the student is enrolled at least half-time.
    </li>
    <li className="bullet-point">
      <strong>Federal Direct Unsubsidized Loans:</strong> Available to all students, regardless of financial need. Interest accrues from the time the loan is disbursed, including while the student is still in school.
    </li>
    <li className="bullet-point">
      <strong>Federal PLUS Loans:</strong> These loans are available to parents of dependent undergraduate students, as well as graduate and professional students. They typically offer larger loan amounts but require a credit check.
    </li>
    <li className="bullet-point">
      <strong>Private Loans:</strong> Private loans are offered by banks or other financial institutions. These loans usually have higher interest rates and less flexible repayment options compared to federal loans.
    </li>
  </ul>
</div>




<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>How to Apply for Financial Aid</strong>
    </p>
    <p className="chapter-description">
      The process of applying for financial aid typically follows these important steps:
    </p>
  </div>

  <ul className="bullet-point-list">
    <li className="bullet-point">
      <strong>FAFSA (Free Application for Federal Student Aid):</strong> Open from October 1st each year, the FAFSA is essential for determining your eligibility for federal grants, work-study, and loans. Submitting it early can improve your chances of receiving aid.
      <br />
      <b>Website:</b> 
      <a className="link" href="https://studentaid.gov/h/apply-for-aid/fafsa"> FAFSA Application</a>
    </li>
    <li className="bullet-point">
      <strong>CSS Profile:</strong> Required by many private colleges to award institutional aid. Be sure to review each school’s specific requirements and deadlines.
      <br />
      <b>Website:</b> 
      <a className="link" href="https://cssprofile.collegeboard.org/"> CSS Profile</a>
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>CSAC (WebGrants 4 Students)</strong>
    </p>
    <p className="chapter-description">
      The California Student Aid Commission (CSAC) provides tools and resources to help students in California manage their financial aid. Through CSAC’s WebGrants 4 Students platform, eligible students can track and maintain their financial aid status.
    </p>
  </div>

  <ul className="bullet-point-list">
    <li className="bullet-point">
      <strong>California Promise Grant:</strong> This grant offers financial assistance to eligible California community college students, helping to cover enrollment fees. It is a crucial resource for many students seeking affordable education.
      <br />
      <b>Website:</b> 
      <a className="link" href="https://mygrantinfo.csac.ca.gov/"> California Promise Grant</a>
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Financial Aid for Undocumented Students</strong>
    </p>
    <p className="chapter-description">
      Undocumented students may still qualify for specific financial aid programs that support their educational goals. One of the most significant options in California is the California Dream Act:
    </p>
  </div>

  <ul className="bullet-point-list">
    <li className="bullet-point">
      <strong>California Dream Act:</strong> This act allows eligible undocumented students to access state-funded financial aid, including grants, scholarships, and fee waivers, helping to make higher education more affordable.
      <br />
      <b>Website:</b> 
      <a className="link" href="https://www.csac.ca.gov/undocumented-dreamer-students"> California Dream Act</a>
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Scholarships</strong>
    </p>
    <p className="chapter-description">
      Scholarships provide an excellent opportunity to reduce college costs. We encourage you to explore a wide range of scholarships and useful application tips on our{" "}
      <Link to="/afford-college/scholarship" className="link">Scholarships Page</Link>. Even a single scholarship can significantly impact your financial future and help ease the burden of paying for college.
    </p>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Understanding and Comparing Financial Aid Packages</strong>
    </p>
    <p className="chapter-description">
      When you receive admission offers, take the time to carefully evaluate and compare the financial aid packages to ensure you’re making the best financial decision for your education.
    </p>
  </div>

  <ul className="bullet-point-list">
    <li className="bullet-point">
      <strong>Total Cost of Attendance:</strong> Beyond just tuition, consider the overall costs like housing, meals, books, and personal expenses when comparing offers.
    </li>
    <li className="bullet-point">
      <strong>Types of Aid:</strong> Review how much of your aid is gift aid, such as grants and scholarships, versus loans, which must be repaid.
    </li>
    <li className="bullet-point">
      <strong>Loan Terms:</strong> Examine loan interest rates and repayment terms, as these can significantly affect your long-term costs.
    </li>
    <li className="bullet-point">
      <strong>Ask Questions:</strong> Don’t hesitate to contact financial aid offices to clarify any details or negotiate your aid package.
    </li>
  </ul>
</div>










        
      </div>
    </div>
  );
}

export default FinancialAid;
