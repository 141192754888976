import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import recletter from "../../../assets/header-images/recletter.jpg";

function LettersOfRecommendation() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={recletter} alt="Header" />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Securing Letters of Recommendation </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Securing strong recommendation letters can truly set your scholarship application apart! While not every scholarship requires them, many do, and they provide invaluable insights that go beyond your essays. In this chapter, we’ll explore how to choose the right recommenders and craft compelling requests, ensuring your letters highlight your unique qualities and experiences. Let’s dive in and empower your application with impactful recommendations!
    </p>
  </div>
</div>

{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">Chapter 10: Recommendation Letters</h2>
            <div id="ch-base-page-underline"></div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Choosing the Right Recommenders</strong>
    </p>
  </div>
  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Identify Recommenders</strong>
    </p>
    <li className="bullet-point">
      <strong>Who to Choose:</strong> Look for recommenders like teachers, coaches, mentors, community leaders, or counselors who truly know you and can share personal insights into your academic strengths, work ethic, and character. Prioritize those who have seen your growth over time.
    </li>
    <li className="bullet-point">
      <strong>Ideal Candidates:</strong> The best recommenders are typically teachers in subjects related to your intended major, supervisors from internships or jobs, and leaders from clubs or activities where you've shown leadership, initiative, or unique contributions.
    </li>
    <li className="bullet-point">
      <strong>Questions to Consider:</strong> Reflect on whether this person is a strong writer, knows you well, and can effectively highlight your achievements and aspirations. If your recommender is a teacher, think about whether they teach a subject you plan to pursue in college, as this adds relevance and strength to their recommendation.
    </li>
  </ul>
</div>




<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>How to Request a Recommendation Letter</strong>
    </p>
  </div>
  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Request Recommendations Early</strong>
    </p>
    <li className="bullet-point">
      <strong>Timing:</strong> Reach out to your chosen recommenders well in advance, ideally at least one month before the scholarship or college application deadline. If possible, consider asking during the summer before your senior year, as this gives them ample time to craft a thoughtful letter.
    </li>
    <li className="bullet-point">
      <strong>Ask Permission First:</strong> Politely ask for their permission to be a recommender for your applications. Inform them early so they have time to consider your request.
    </li>
    <li className="bullet-point">
      <strong>Approach:</strong> Be respectful and explain why you value their perspective, highlighting why you believe they would be an excellent recommender for your application.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Provide Supportive Information</strong>
    </p>
    <p>
      Even if your recommender knows you well, they may not be familiar with all your accomplishments, both in and out of school. Providing helpful information will enable them to write a stronger and more detailed recommendation.
    </p>
    <li className="bullet-point">
      <strong>Resume and Achievements:</strong> Share your resume along with a brag sheet that highlights your achievements, extracurricular activities, and notable experiences for them to reference.
    </li>
    <li className="bullet-point">
      <strong>College List:</strong> Provide a list of the colleges or scholarships you're applying to, along with their deadlines, and specific points you’d like them to emphasize in their letter.
    </li>
    <li className="bullet-point">
      <strong>Template:</strong> Offer an "Information About Yourself" template summarizing your academic strengths, extracurricular involvement, personal qualities, and goals to help remind them of your background.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Important Things to Keep in Mind</strong>
    </p>
    <li className="bullet-point">
      Be sincere and open to any feedback from your recommender; making a positive impression is key.
    </li>
    <li className="bullet-point">
      Remember, your recommender may be writing letters for several students, so be polite yet persistent with reminders.
    </li>
    <li className="bullet-point">
      When scheduling meetings or check-ins, be respectful of their time. Show that you are serious about the process and value their input.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>The "Information About Yourself" Template</strong>
    </p>
    <p class="chapter-description">
      Create a flexible and comprehensive template that captures key information about yourself to streamline the application process. This will make it easier for your recommenders to write reference letters, ensuring they have all the details needed to highlight your strengths.
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>The Template Should Include:</strong>
    </p>

    <li class="bullet-point">
      <strong>Introduction Paragraph:</strong> Start by introducing yourself. Include your background, current academic standing, chosen major, and both your short-term and long-term goals. Aim for 250+ words that provide a clear snapshot of who you are and what you aspire to achieve.
    </li>
    <li class="bullet-point">
      <strong>Extracurricular Activities:</strong> Detail your extracurricular involvement. For each activity, write 50-100 words summarizing your role, any leadership positions held, and the impact of your contributions. This can include volunteering, school clubs, community service, and any awards you’ve received.
    </li>
    <li class="bullet-point">
      <strong>Research, Internship, or Work Experience:</strong> For any research projects, internships, or jobs you've completed, write 50-100 words highlighting your responsibilities, what you learned, key accomplishments, and any leadership roles you may have held. This section showcases your hands-on experience and commitment.
    </li>
  </ul>
</div>

   

<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>What Your Recommenders Need to Know</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Offer Materials</strong>
    </p>
    <li className="bullet-point">
      <strong>Transcripts and Samples:</strong> Provide your recommenders with access to your academic transcripts and any writing samples or project work that showcase your best efforts. This helps them fully understand your academic background and achievements.
    </li>
    <li className="bullet-point">
      <strong>Brag Sheet:</strong> Prepare a detailed brag sheet that outlines your accomplishments, extracurricular activities, leadership roles, and personal achievements. This comprehensive document will assist your recommenders in crafting a more personalized and thorough letter.
    </li>
    <li className="bullet-point">
      <strong>Information About Yourself Template:</strong> Share the template you created, highlighting your strengths, career goals, and aspirations. This gives your recommenders a clear picture of your objectives and key qualities.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Be Respectful of Their Time</strong>
    </p>
    <li className="bullet-point">
      <strong>Acknowledgement:</strong> Writing recommendation letters takes significant time and effort. Be sure to thank your recommenders for agreeing to help and offer any additional details or clarification they might need.
    </li>
    <li className="bullet-point">
      <strong>Follow-Up:</strong> As deadlines approach, politely check in with a reminder to ensure they have sufficient time to complete your recommendation. Provide any updates or extra materials they may request to assist them in finalizing the letter.
    </li>
    <li className="bullet-point">
      <strong>Thank You Note:</strong> Once your recommender has submitted your letter, be sure to send a thank you note expressing your gratitude for their time and effort. A handwritten note or thoughtful email can go a long way in showing your appreciation.
    </li>
  </ul>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Importance for Private Colleges</strong>
    </p>
    <p className="chapter-description">
      Letters of recommendation play a critical role in the admissions process for top private colleges in the U.S. They provide personal insights into your character, achievements, and potential in ways that grades and test scores cannot. A strong recommendation letter helps paint a complete picture of who you are as a student and an individual.
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Key Application Component:</strong> Strong letters of recommendation can be a deciding factor, especially when applicants have similar grades and test scores. These endorsements highlight what sets you apart from the competition.
      </li>
      <li className="bullet-point">
        <strong>Detailed Reports Matter:</strong> Endorsements from teachers and consultants reinforce your academic achievements and personal qualities, giving the admissions committee a well-rounded view of your strengths.
      </li>
      <li className="bullet-point">
        <strong>Supplemental Writing Role:</strong> When combined with your essays and personal statement, recommendation letters demonstrate how well you align with the school’s values and what you can contribute to the college community.
      </li>
      <li className="bullet-point">
        <strong>Holistic Admissions:</strong> Private colleges often take a holistic approach to admissions. Strong letters of recommendation are crucial in showcasing that you are a well-rounded individual, as even high test scores may not compensate for weak or impersonal recommendations.
      </li>
    </ul>
    <p className="chapter-description">
      In conclusion, recommendation letters are a vital component of applications to top private colleges. Choose recommenders who know you personally and can provide detailed, heartfelt insights into your abilities, work ethic, and character.
    </p>
  </div>
</div>


{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 10: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Securing strong recommendation letters is a critical step in the
              scholarship application process. By choosing the right
              recommenders, providing them with detailed information, and
              respecting their time, you can ensure that your letters of
              recommendation will significantly enhance your application. These
              letters offer a personal insight into your abilities and
              character, helping you stand out in the competitive scholarship
              landscape.
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default LettersOfRecommendation;
