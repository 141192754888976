import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import apprentice from "../../../assets/header-images/apprentice.jpg";

function Apprenticeship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={apprentice} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Apprenticeship Programs </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            Apprenticeships serve as an outstanding gateway for individuals eager to gain practical experience, hone their skills, and carve out a fulfilling career path in the tech industry. These programs seamlessly blend on-the-job training with classroom instruction, empowering participants to learn, grow, and thrive in a real-world environment while building a robust foundation for their future careers.
        </p>
    </div>
</div>
      <div id="sections-container">


      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Program Listings</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Google Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Google offers a variety of apprenticeships designed to help individuals gain hands-on experience and develop skills in fields like IT, software engineering, and data analysis. Participants receive mentorship and support to thrive in their chosen career paths!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://buildyourfuture.withgoogle.com/apprenticeships" class="link"> Google Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Microsoft Leap:</strong>
      </p>
      <p class="chapter-description">
        The Microsoft Leap program provides an immersive 16-week experience for individuals seeking to develop technical skills and start a career in technology. With training, mentorship, and hands-on projects, participants are well-prepared for full-time roles at Microsoft and beyond!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://leap.microsoft.com/en-US/" class="link"> Microsoft Leap
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Adobe Digital Academy:</strong>
      </p>
      <p class="chapter-description">
        This partnership between Adobe and General Assembly offers an apprenticeship program that combines training in digital skills with real-world experience at Adobe. Participants learn coding, design, and data science, followed by an impactful apprenticeship at Adobe!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://learn.generalassemb.ly/adobe-digital-academy/" class="link"> Adobe Digital Academy
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>IBM Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        IBM offers a range of apprenticeship programs in software development, cybersecurity, and data science. These programs provide hands-on experience, mentorship, and opportunities to work on exciting projects at IBM, setting you on a path to success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.ibm.com/careers/career-opportunities" class="link"> IBM Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>LinkedIn Reach:</strong>
      </p>
      <p class="chapter-description">
        LinkedIn Reach is an apprenticeship program designed to help individuals from diverse backgrounds gain skills in software engineering. This program includes training, mentorship, and real-world project experience to prepare participants for full-time roles at LinkedIn and other tech companies!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.linkedin.com/reach" class="link"> LinkedIn Reach
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Lyft Early Talent:</strong>
      </p>
      <p class="chapter-description">
        Lyft's apprenticeship program offers opportunities for individuals to gain experience in software engineering, data science, and other technical fields. Participants receive training, mentorship, and hands-on projects to kickstart their careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.lyft.com/careers/early-talent" class="link"> Lyft Early Talent
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Uber University:</strong>
      </p>
      <p class="chapter-description">
        Uber offers a variety of apprenticeship programs to help individuals gain experience in technology and engineering. Participants receive training, mentorship, and hands-on project experience to prepare them for fulfilling careers at Uber!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.uber.com/us/en/careers/teams/university/" class="link"> Uber University
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Airbnb Connect Engineering Apprenticeship:</strong>
      </p>
      <p class="chapter-description">
        The Airbnb Connect Engineering Apprenticeship is designed to help underrepresented individuals gain skills in software engineering. This program includes training, mentorship, and project work at Airbnb, preparing participants for exciting careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.airbnb.com/connect-engineering-apprenticeship/" class="link"> Airbnb Connect Engineering Apprenticeship
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Pinterest Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Pinterest offers apprenticeship programs in software engineering, data science, and other technical fields. Participants receive training, mentorship, and hands-on experience to develop their skills and start their careers at Pinterest and beyond!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.pinterestcareers.com/early-career/apprenticeships/" class="link"> Pinterest Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Snapchat University:</strong>
      </p>
      <p class="chapter-description">
        Snapchat's apprenticeship program provides opportunities for individuals to gain experience in software engineering and other technical fields. The program includes training, mentorship, and real-world project work at Snapchat, a fantastic place to grow your career!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.snap.com/university" class="link"> Snapchat University
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Accenture Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Accenture and Aon have partnered to offer apprenticeship programs that provide hands-on experience and training in various fields, including technology and consulting. Participants receive mentorship and support to thrive in their careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.accenture.com/us-en/about/company/apprenticeships" class="link"> Accenture Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Apprenti:</strong>
      </p>
      <p class="chapter-description">
        Apprenti offers apprenticeship programs in technology, providing training and job placement in fields such as software development, cybersecurity, and IT. This program aims to bridge the gap between education and employment for underrepresented individuals!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://apprenticareers.org/apply/" class="link"> Apprenti
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Catalyte Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Catalyte's apprenticeship program focuses on software development, providing training and hands-on experience to help individuals start their careers in tech. Participants work on real projects and receive mentorship to cultivate their skills!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.catalyte.io/apprenticeships/software-development/" class="link">  Catalyte Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Multiverse Programs:</strong>
      </p>
      <p class="chapter-description">
        Multiverse offers apprenticeship programs in various fields, including data analysis, software engineering, and digital marketing. These programs combine training with real-world experience to prepare participants for successful careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.multiverse.io/en-US/programs" class="link">  Multiverse Programs
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Next Chapter Apprentices:</strong>
      </p>
      <p class="chapter-description">
        Next Chapter provides apprenticeship programs for individuals transitioning from incarceration, offering training and job placement in technology. This program aims to provide second chances and support career development!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.nextchapterproject.org/apprentices" class="link">  Next Chapter Apprentices
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Niantic Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Niantic offers apprenticeship programs in partnership with Tech Elevator, providing training and hands-on experience in software development. Participants work on projects and receive mentorship to prepare for careers in tech!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.techelevator.com/workforce-transformation/niantic/" class="link">  Niantic Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Onramp Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Onramp offers apprenticeship programs in technology, providing training and real-world experience to help individuals start their careers. The programs include mentorship and support to ensure participant success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.onramp.io/" class="link">  Onramp Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Techtonica Full-Time Program:</strong>
      </p>
      <p class="chapter-description">
        Techtonica offers full-time apprenticeship programs for women and non-binary individuals, providing training in software engineering and job placement in tech roles. The program includes mentorship and hands-on project work to ensure success!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://techtonica.org/full-time-program/" class="link">  Techtonica Full-Time Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Tranzed Apprenticeships:</strong>
      </p>
      <p class="chapter-description">
        Tranzed provides apprenticeship programs in various fields, including technology and business. The programs offer training, mentorship, and hands-on experience to prepare participants for successful careers!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://tranzedapprenticeships.com/" class="link">  Tranzed Apprenticeships
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Twilio Hatch:</strong>
      </p>
      <p class="chapter-description">
        Twilio Hatch is an apprenticeship program designed to help underrepresented individuals gain skills in software engineering. The program includes training, mentorship, and project work to prepare participants for full-time roles at Twilio!
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.twilio.com/en-us/company/diversity/hatch" class="link">  Twilio Hatch
        </a>
      </li>
    </ul>
  </div>
</div>






      </div>
    </div>
  );
}

export default Apprenticeship;
