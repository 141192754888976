import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import actionVerbsImage from "../../../assets/inner-content-images-and-files/actionVerbs.png";

import resume from "../../../assets/header-images/resume.jpg";

import resumeGuide from "../../../assets/inner-content-images-and-files/ResumeTemplate.pdf";
import "../../../css/SubPage/SubPage.css";

function ResumeTips() {
  return (
    <div id="newest-background">
      {/* Header Section */}
      <div id="Ch-face">
        <img id="header-image" src={resume} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Crafting Your Resume </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      {/* Introduction Section */}
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Crafting a strong resume is essential for landing internships, scholarships, and job opportunities. This page offers templates, practical tips, and action verbs to help you create a standout resume. Remember, your resume is often the first impression you make on potential employers, so it’s crucial to get it right. Highlight your skills, experiences, and achievements clearly and concisely to stand out from the crowd and showcase what makes you unique.
        </p>
    </div>
</div>


      {/* Sections Container */}
      <div id="sections-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Resume Resources</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Resume Template:</strong> Utilize 
        <a
          href="https://www.overleaf.com/latex/templates/jakes-resume/syzfjbzwjncs"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        > Jake's Resume Template
        </a>, a highly regarded LaTeX template that offers a clean, professional design and is easy to customize for your specific needs.
      </li>
      <li class="bullet-point">
        <strong>In-depth Resume Guide:</strong> Download the comprehensive resume guide 
        <a
          href={resumeGuide}
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        > here
        </a>, which provides detailed tips, best practices, and examples to help you create an effective resume that stands out to employers.
      </li>
      <li class="bullet-point">
        <strong>Google's X-Y-Z Formula:</strong> Enhance your resume by following 
        <a
          href="https://www.inc.com/bill-murphy-jr/google-recruiters-say-these-5-resume-tips-including-x-y-z-formula-will-improve-your-odds-of-getting-hired-at-google.html"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        > these Google Resume Tips
        </a>. The X-Y-Z formula is especially effective: "Accomplished [X] as measured by [Y], by doing [Z]", helping you clearly articulate your achievements.
      </li>
      <li class="bullet-point">
        <strong>Reddit /resume:</strong> Join the community at 
        <a
          href="https://www.reddit.com/r/resumes/"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        > this link
        </a>, where you can find valuable information, resume samples, and feedback from others. Feel free to share your own resume for constructive advice and suggestions from the community!
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Resume Sections in Order</strong>
    </p>
    <p class="chapter-description">
      Follow these steps to create a professional and impactful resume:
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Contact Information:</strong> Start with your full name, phone number, email address, and LinkedIn profile. If you're in a STEM field, consider adding your GitHub to showcase your projects.
      </li>
      <li class="bullet-point">
        <strong>Education:</strong> List your highest degree first, including your GPA if it’s 3.0 or higher. Mention any clubs, programs, or honors that highlight your involvement and leadership.
      </li>
      <li class="bullet-point">
        <strong>Work Experience:</strong> Detail your work experience in reverse chronological order, using bullet points to clearly describe your responsibilities and achievements.
      </li>
      <li class="bullet-point">
        <strong>Skills:</strong> Highlight both technical and soft skills relevant to the job you’re applying for, tailoring this section to match the job description effectively.
      </li>
      <li class="bullet-point">
        <strong>Projects:</strong> Emphasize significant projects, especially those relevant to the position. Include details about your role and the outcomes achieved to demonstrate your impact.
      </li>
      <li class="bullet-point">
        <strong>Certifications / Achievements:</strong> List any relevant certifications, licenses, or awards that can help differentiate you from other candidates and showcase your commitment to professional growth.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Additional Tips for Resume Crafting</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Include Metrics:</strong> Use quantifiable achievements (e.g., "achieved a 30% increase in sales") to clearly showcase your impact.
      </li>
      <li class="bullet-point">
        <strong>Avoid Job Gaps:</strong> Label your current position as "CURRENT" to indicate ongoing employment and avoid any gaps in your resume.
      </li>
      <li class="bullet-point">
        <strong>No Photo:</strong> Refrain from including a photo on your resume, and ensure consistent punctuation throughout.
      </li>
      <li class="bullet-point">
        <strong>Proofread:</strong> Carefully check for grammar and spelling mistakes. Keep bullet point sentences concise, ideally between 3-5 points each.
      </li>
      <li class="bullet-point">
        <strong>Sell Your Experience:</strong> Demonstrate your experience rather than simply summarizing it. Use action-oriented language and avoid unnecessary fluff.
      </li>
      <li class="bullet-point">
        <strong>Keyword Analysis:</strong> Analyze job postings to identify frequently used keywords and incorporate them strategically into your resume.
      </li>
      <li class="bullet-point">
        <strong>Reference Availability:</strong> Allow potential employers to contact your most recent employer for references, showing your openness.
      </li>
      <li class="bullet-point">
        <strong>Accurate Job Titles:</strong> Use a title that reflects your responsibilities (e.g., "Full Stack Engineer") instead of your official title if it better represents your role.
      </li>
      <li class="bullet-point">
        <strong>Legibility:</strong> Ensure your text is easy to read by avoiding overly small fonts. Bold important keywords such as skills or tools (e.g., <strong>React.js</strong>, <strong>Python</strong>) for emphasis.
      </li>
      <li class="bullet-point">
        <strong>Highlight Achievements:</strong> Use bullet points with action verbs to emphasize your achievements and avoid listing skills or tools you’re not proficient in.
      </li>
      <li class="bullet-point">
        <strong>File Naming:</strong> Save your resume as FirstNameLastName.pdf to make it easily identifiable, instead of using generic names like resume.pdf.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Action Verbs for Resumes</strong>
    </p>
    <img
      id="timeline"
      src={actionVerbsImage}
      alt="Action Verbs for Resumes"
    />
    <p class="chapter-description">
      Start each statement describing your experiences with action verbs to create a more dynamic and impactful resume. These verbs convey confidence and a proactive attitude, making your accomplishments stand out.
    </p>
  </div>
</div>

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Common Resume Edits</strong>
    </p>
    <p class="chapter-description">
      When editing your resume, consider these suggestions to replace common phrases with stronger, more impactful language:
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        Worked on → Spearheaded / Facilitated
      </li>
      <li class="bullet-point">
        Did → Completed / Achieved
      </li>
      <li class="bullet-point">
        Got → Earned / Produced
      </li>
      <li class="bullet-point">
        Helped → Enabled / Empowered
      </li>
      <li class="bullet-point">
        Worked With → Partnered With
      </li>
      <li class="bullet-point">
        Made → Created / Designed / Conceptualized
      </li>
      <li class="bullet-point">
        Found → Identified
      </li>
      <li class="bullet-point">
        Fixed → Restructured
      </li>
      <li class="bullet-point">
        Changed → Transformed
      </li>
    </ul>
  </div>
</div>


        {/* Conclusion Section */}

{/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              A well-crafted resume can significantly enhance your chances of
              securing valuable opportunities. Use the resources and tips
              provided here to create a strong resume that highlights your
              skills and experiences. Remember, your resume is a living document
              that should evolve as you gain more experience and skills.
              Regularly update it to reflect your latest accomplishments and
              ensure it remains relevant to the roles you are applying for. Good
              luck!
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default ResumeTips;
