import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function UnderstandScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />


        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 1</p>
            <p id = "sub-scholarship-ch-header-text">Understanding Scholarships</p>
            <p id = "sub-scholarship-ch-text-two">Your Key to Educational Freedom</p>
          </div>
        </div>

        
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            This chapter lays the foundation for your scholarship journey by exploring the significance of scholarships, the different types available, and effective research strategies. You'll learn how to navigate application timelines, prioritize opportunities, and harness resources to maximize your chances of success in securing financial support for your education.
        </p>
    </div>
</div>

      <div id="whole-section-page-container">


        
      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>What is a Scholarship? Why is it important?</strong>
    </p>
    <p className="chapter-description">
      Scholarships are financial awards given to students based on things like academic performance, financial need, or specific talents. They’re incredibly important because they help make college more affordable and accessible. Here’s why scholarships matter:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Reducing Financial Burden:</strong> Scholarships can help cover costs like tuition, books, and living expenses, easing the financial strain on students and their families.
      </li>
      <li className="bullet-point">
        <strong>Preventing Student Debt:</strong> Since scholarships don’t need to be repaid, they help students avoid taking out large loans, which means less debt after graduation.
      </li>
      <li className="bullet-point">
        <strong>Encouraging Academic Excellence:</strong> Merit-based scholarships push students to excel in their academics and extracurriculars, rewarding their effort and hard work.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Types of Scholarships</strong>
    </p>
    <p className="chapter-description">
      Scholarships come in many forms, each with specific criteria and benefits. Knowing the different types can help you target the best opportunities that align with your academic strengths, financial needs, or personal talents:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Merit-based Scholarships:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Criteria:</strong> Awarded based on academic performance, leadership qualities, or extracurricular involvement. These scholarships recognize hard work and achievement.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> National Merit Scholarship, Rhodes Scholarship.
          </li>
          <li className="bullet-point">
            <strong>Application Tips:</strong> Focus on maintaining a high GPA, take on leadership roles in clubs or activities, and contribute to your community through volunteer work.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Need-based Scholarships:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Criteria:</strong> These scholarships are granted based on demonstrated financial need, typically assessed through forms like the FAFSA or similar documentation.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> Federal Pell Grant, State Pell Grants, and institutional need-based awards.
          </li>
          <li className="bullet-point">
            <strong>Application Tips:</strong> Make sure your financial aid forms are filled out accurately, provide all necessary documentation, and explore both federal and institutional aid options.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Additional Categories:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Ethnicity-based Scholarships:</strong> Designed to support students from underrepresented ethnic backgrounds, such as the Hispanic Scholarship Fund.
          </li>
          <li className="bullet-point">
            <strong>Field of Study Scholarships:</strong> Targeted at students pursuing degrees in specific fields like STEM, healthcare, or the arts.
          </li>
          <li className="bullet-point">
            <strong>Skill-based Scholarships:</strong> Awarded for unique talents, whether in athletics, music, visual arts, or other specialized areas.
          </li>
          <li className="bullet-point">
            <strong>Community Involvement Scholarships:</strong> These scholarships recognize students who have made a meaningful impact in their communities through volunteer work or civic engagement.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>The Value Beyond Financial Aid</strong>
    </p>
    <p className="chapter-description">
      Scholarships offer more than just financial assistance. They can provide a range of valuable benefits and opportunities that extend beyond covering the cost of education:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Mentorship Programs:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Description:</strong> Many scholarships provide access to mentors who are experienced professionals in various industries.
          </li>
          <li className="bullet-point">
            <strong>Benefits:</strong> Mentors can offer guidance on academic choices, career planning, and advice on entering the professional world.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> Gates Millennium Scholars, Coca-Cola Scholars Program.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Networking Opportunities:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Description:</strong> Scholarship programs often host events, workshops, and conferences that allow recipients to connect with others.
          </li>
          <li className="bullet-point">
            <strong>Benefits:</strong> Building lasting connections with fellow scholars, alumni, and industry leaders that can be valuable for your future career.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> Jack Kent Cooke Foundation, Fulbright Program.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Career Development Workshops:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Description:</strong> Workshops and seminars are offered to help you develop career skills and prepare for the job market.
          </li>
          <li className="bullet-point">
            <strong>Benefits:</strong> Improve resume writing, interview skills, and learn effective job search strategies to boost your employability.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> Microsoft Scholarship Program, Google Scholarships.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Internship Opportunities:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            <strong>Description:</strong> Some scholarship programs offer direct internship placements to help you gain real-world experience.
          </li>
          <li className="bullet-point">
            <strong>Benefits:</strong> Gaining hands-on experience through internships improves your skills and enhances your employability after graduation.
          </li>
          <li className="bullet-point">
            <strong>Examples:</strong> NASA Scholarship Programs, IBM Scholarships.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>




      </div>
    </div>
  );
}

export default UnderstandScholarship;
