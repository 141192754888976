import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/components/Navbar.css";
import logo from "../assets/Logo/Logo2White.jpg";
import { useAuth } from "../context/authContext";
import { doSignOut } from "../firebase/auth";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLogout = async () => {
    await doSignOut();
    navigate("/login");
  };

  return (
    <div id="navbar">
      <div id="left-nav">
        <div>
          <Link to="/">
            <img id="logo-pic" src={logo} alt="CollegeNav Logo" />
          </Link>
        </div>
      </div>

      <div id="mid-nav">
        <Link to="/" className="mid-category">
          Home
        </Link>

        <div className="mid-category">
          <Link to="/college-prep" className="mid-category">
            College Prep
          </Link>
          <div className="dropdown-content">
            {/* College Prep Links */}
            <Link
              to="/college-prep/highschool-roadmap"
              className={
                location.pathname === "/college-prep/highschool-roadmap"
                  ? "active"
                  : ""
              }
            >
              High School Roadmap
            </Link>
            <Link
              to="/college-prep/different-paths"
              className={
                location.pathname === "/college-prep/different-paths"
                  ? "active"
                  : ""
              }
            >
              Exploring Different Paths
            </Link>
            <Link
              to="/college-prep/majors"
              className={
                location.pathname === "/college-prep/majors" ? "active" : ""
              }
            >
              Choosing a Major
            </Link>
            <Link
              to="/college-prep/college-search-guide"
              className={
                location.pathname === "/college-prep/college-search-guide"
                  ? "active"
                  : ""
              }
            >
              College Search
            </Link>
            <Link
              to="/college-prep/high-school"
              className={
                location.pathname === "/college-prep/high-school"
                  ? "active"
                  : ""
              }
            >
              High School Planning
            </Link>
            <Link
              to="/college-prep/community-college"
              className={
                location.pathname === "/college-prep/community-college"
                  ? "active"
                  : ""
              }
            >
              Community College Pathways
            </Link>

            <Link
              to="/college-prep/activities-and-achievements"
              className={
                location.pathname ===
                "/college-prep/activities-and-achievements"
                  ? "active"
                  : ""
              }
            >
              Activities and Achievements
            </Link>
            <Link
              to="/college-prep/application-process"
              className={
                location.pathname === "/college-prep/application-process"
                  ? "active"
                  : ""
              }
            >
              Navigating the Application Process
            </Link>
            <Link
              to="/college-prep/college-essay"
              className={
                location.pathname === "/college-prep/college-essay"
                  ? "active"
                  : ""
              }
            >
              Crafting Your College Essay
            </Link>
            <Link
              to="/college-prep/letters-of-recommendation"
              className={
                location.pathname === "/college-prep/letters-of-recommendation"
                  ? "active"
                  : ""
              }
            >
              Securing Letters of Recommendation
            </Link>
            <Link
              to="/college-prep/international-students"
              className={
                location.pathname === "/college-prep/international-students"
                  ? "active"
                  : ""
              }
            >
              Guidance for International Students
            </Link>
            <Link
              to="/afford-college"
              className={
                location.pathname === "/afford-college" ? "active" : ""
              }
            >
              Afford College
            </Link>
          </div>
        </div>

        <div className="mid-category">
          <Link to="/afford-college" className="mid-category">
            Afford College
          </Link>
          <div className="dropdown-content">
            {/* Afford College Links */}
            <Link
              to="/afford-college/financial-aid"
              className={
                location.pathname === "/afford-college/financial-aid"
                  ? "active"
                  : ""
              }
            >
              Understanding Financial Aid
            </Link>
            <div className="mid-category has-submenu">
              <Link
                to="/afford-college/scholarship"
                className={
                  location.pathname === "/afford-college/scholarship"
                    ? "active"
                    : ""
                }
              >
                Scholarships +
              </Link>
              <div className="dropdown-sub-content">
                <Link
                  to="/afford-college/scholarship/understanding-scholarship"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/understanding-scholarship"
                      ? "active"
                      : ""
                  }
                >
                  Ch 1 - Understanding Scholarship
                </Link>
                <Link
                  to="/afford-college/scholarship/scholarship-search-guide"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/scholarship-search-guide"
                      ? "active"
                      : ""
                  }
                >
                  Ch 2 - Scholarship Search
                </Link>
                <Link
                  to="/afford-college/scholarship/application-tips"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/application-tips"
                      ? "active"
                      : ""
                  }
                >
                  Ch 3 - Application Tips
                </Link>
                <Link
                  to="/afford-college/scholarship/crafting-application"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/crafting-application"
                      ? "active"
                      : ""
                  }
                >
                  Ch 4 - Crafting Application
                </Link>
                <Link
                  to="/afford-college/scholarship/scholarship-interview"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/scholarship-interview"
                      ? "active"
                      : ""
                  }
                >
                  Ch 5 - Scholarship Interview
                </Link>
                <Link
                  to="/afford-college/scholarship/post-award-tips"
                  className={
                    location.pathname ===
                    "/afford-college/scholarship/post-award-tips"
                      ? "active"
                      : ""
                  }
                >
                  Ch 6 - Post Award Tips
                </Link>
              </div>
            </div>
            <Link
              to="/afford-college/student-work-opportunities"
              className={
                location.pathname ===
                "/afford-college/student-work-opportunities"
                  ? "active"
                  : ""
              }
            >
              Student Employment Opportunities
            </Link>
          </div>
        </div>

        <div className="mid-category">
          <Link to="/college" className="mid-category">
            College Life
          </Link>
          <div className="dropdown-content">
            {/* College Life Links */}
            <Link
              to="/college/before-college"
              className={
                location.pathname === "/college/before-college" ? "active" : ""
              }
            >
              Preparing for College Life
            </Link>
            <Link
              to="/college/college-tips"
              className={
                location.pathname === "/college/college-tips" ? "active" : ""
              }
            >
              Essential College Tips
            </Link>
            <Link
              to="/college/school-benefits"
              className={
                location.pathname === "/college/school-benefits" ? "active" : ""
              }
            >
              Maximizing School Benefits
            </Link>

            <Link
              to="/tools/gpa-calculator"
              className={
                location.pathname === "/tools/gpa-calculator" ? "active" : ""
              }
            >
              GPA Calculator
            </Link>

            <Link
              to="/college/stem-internships"
              className={
                location.pathname === "/college/stem-internships"
                  ? "active"
                  : ""
              }
            >
              STEM Internship Opportunities
            </Link>
            <Link
              to="/programs/freshmen-sophomore-programs"
              className={
                location.pathname === "/programs/freshmen-sophomore-programs"
                  ? "active"
                  : ""
              }
            >
              Freshmen and Sophomore Programs
            </Link>
            <Link
              to="/college/resume"
              className={
                location.pathname === "/college/resume" ? "active" : ""
              }
            >
              Crafting Your Resume
            </Link>
          </div>
        </div>

        <div className="mid-category">
          <Link to="/programs" className="mid-category">
            Programs
          </Link>
          <div className="dropdown-content">
            {/* Programs Links */}
            <Link
              to="/programs/research-opportunities"
              className={
                location.pathname === "/programs/research-opportunities"
                  ? "active"
                  : ""
              }
            >
              Research Opportunities
            </Link>
            <Link
              to="/programs/study-abroad"
              className={
                location.pathname === "/programs/study-abroad" ? "active" : ""
              }
            >
              Study Abroad Programs
            </Link>
            <Link
              to="/programs/mentorship-programs"
              className={
                location.pathname === "/programs/mentorship-programs"
                  ? "active"
                  : ""
              }
            >
              Mentorship Programs
            </Link>
            <Link
              to="/programs/tech-conferences"
              className={
                location.pathname === "/programs/tech-conferences"
                  ? "active"
                  : ""
              }
            >
              Tech Conferences
            </Link>
            <Link
              to="/programs/academic-stem-programs"
              className={
                location.pathname === "/programs/academic-stem-programs"
                  ? "active"
                  : ""
              }
            >
              Academic STEM Programs
            </Link>
            <Link
              to="/programs/all-stem-work-opportunities"
              className={
                location.pathname === "/programs/all-stem-work-opportunities"
                  ? "active"
                  : ""
              }
            >
              ALL STEM Work Opportunities
            </Link>
            <Link
              to="/programs/freshmen-sophomore-programs"
              className={
                location.pathname === "/programs/freshmen-sophomore-programs"
                  ? "active"
                  : ""
              }
            >
              Freshmen and Sophomore Programs
            </Link>
            <Link
              to="/programs/apprenticeship"
              className={
                location.pathname === "/programs/apprenticeship" ? "active" : ""
              }
            >
              Apprenticeship Programs
            </Link>
            <Link
              to="/programs/government-programs"
              className={
                location.pathname === "/programs/government-programs"
                  ? "active"
                  : ""
              }
            >
              Government Programs
            </Link>
            <Link
              to="/programs/free-learning-tools"
              className={
                location.pathname === "/programs/free-learning-tools"
                  ? "active"
                  : ""
              }
            >
              Free Educational Resources
            </Link>
          </div>
        </div>

        <div className="mid-category">
          <Link to="/tools" className="mid-category">
            Tools
          </Link>
          <div className="dropdown-content">
            {/* Tools Links */}

            <Link
              to="/tools/ai"
              className={location.pathname === "/tools/ai" ? "active" : ""}
            >
              Aza (AI Chatbot)
            </Link>

            <Link
              to="/tools/early-career-search"
              className={
                location.pathname === "/tools/early-career-search"
                  ? "active"
                  : ""
              }
            >
              Early Career Search
            </Link>

            <Link
              to="/tools/financial-tracker"
              className={
                location.pathname === "/tools/financial-tracker" ? "active" : ""
              }
            >
              Financial Tracker
            </Link>

            <Link
              to="/tools/gpa-calculator"
              className={
                location.pathname === "/tools/gpa-calculator" ? "active" : ""
              }
            >
              GPA Calculator
            </Link>

            <Link
              to="/tools/college-search"
              className={
                location.pathname === "/tools/college-search" ? "active" : ""
              }
            >
              College Search
            </Link>

            <Link
              to="/tools/scholarship-search"
              className={
                location.pathname === "/tools/scholarship-search"
                  ? "active"
                  : ""
              }
            >
              Scholarship Search
            </Link>

            <Link
              to="/tools/virtual-tour"
              className={
                location.pathname === "/tools/virtual-tour" ? "active" : ""
              }
            >
              Virtual College Tour
            </Link>

            <Link
              to="/tools/international-students-tool"
              className={
                location.pathname === "/tools/international-students-tool"
                  ? "active"
                  : ""
              }
            >
              International Students Tool
            </Link>
          </div>
        </div>

        <div class="mid-category">
          <Link to="/about-us" className="mid-category">
            About Us
          </Link>
        </div>

        <div class="mid-category">
          {userLoggedIn && (
            <Link to="/profile" className="mid-category">
              Profile
            </Link>
          )}
        </div>
      </div>

      <div id="right-nav">
        {userLoggedIn ? (
          <button onClick={handleLogout} className="logout-btn">
            Log Out
          </button>
        ) : (
          <>
            <Link className="login-btn" to="/login">
              Log In
            </Link>
          </>
        )}
      </div>

      <div id="right-nav-responsive">
        <label className="bar" htmlFor="check">
          <input
            type="checkbox"
            id="check"
            checked={isDropdownVisible}
            onChange={toggleDropdown}
          />
          <span className="top"></span>
          <span className="middle"></span>
          <span className="bottom"></span>
        </label>
      </div>

      <div id="dropdown-menu" className={isDropdownVisible ? "open" : ""}>
        <ul>
          <li>
            <Link to="/" onClick={toggleDropdown}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/college-prep" onClick={toggleDropdown}>
              College Prep
            </Link>
          </li>
          <li>
            <Link to="/afford-college" onClick={toggleDropdown}>
              Afford College
            </Link>
          </li>
          <li>
            <Link to="/college" onClick={toggleDropdown}>
              College Life
            </Link>
          </li>
          <li>
            <Link to="/programs" onClick={toggleDropdown}>
              Programs
            </Link>
          </li>
          <li>
            <Link to="/tools" onClick={toggleDropdown}>
              Tools
            </Link>
          </li>
          <li>
            <Link to="/about-us" onClick={toggleDropdown}>
              About Us
            </Link>
          </li>
          {userLoggedIn && (
            <li>
              <Link to="/profile" onClick={toggleDropdown}>
                Profile
              </Link>
            </li>
          )}
          {userLoggedIn ? (
            <li>
              <button onClick={handleLogout} className="logout-btn">
                Logout
              </button>
            </li>
          ) : (
            <>
              <li>
                <Link
                  className="mid-category"
                  to="/login"
                  onClick={toggleDropdown}
                >
                  Login
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
