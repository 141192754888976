import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";

import achievements from "../../../assets/header-images/achievements.jpg";

function ActivitiesAndAchievements() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={achievements} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Activities and Achievements </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Highlighting your activities and achievements is a vital part of the college application process! This chapter is designed to guide you on how to effectively present your extracurricular involvement, leadership roles, and notable accomplishments. By showcasing your dedication and the positive impact you've made, you can give admissions committees a clearer understanding of your character and potential. Let’s dive into the best strategies for making your achievements shine!
    </p>
  </div>
</div>

      {/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 7: Activities and Achievements
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">

        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Importance of Extracurricular Activities</strong>
    </p>
    <p class="chapter-description">
      Extracurricular activities play a crucial role in your college application by showcasing your passions, interests, and ability to juggle multiple responsibilities. They help paint a fuller picture of who you are beyond the classroom:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Show Commitment:</strong> Long-term involvement in extracurricular activities reflects your dedication and perseverance. Admissions committees appreciate students who demonstrate commitment, as it shows you can engage deeply and grow within your interests.
    </li>
    <li class="bullet-point">
      <strong>Develop Skills:</strong> Engaging in extracurriculars equips you with vital life skills like leadership, teamwork, and time management. These skills will serve you well in both your academic pursuits and future careers.
    </li>
    <li class="bullet-point">
      <strong>Make an Impact:</strong> Highlighting your contributions and the positive outcomes of your activities can help distinguish you from other applicants. Whether you're leading a club or volunteering, sharing how you’ve made a difference can significantly strengthen your application.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>How to Choose Activities to Highlight</strong>
    </p>
    <p class="chapter-description">
      When selecting extracurricular activities for your college application, it’s important to be strategic. Consider the following factors to showcase your most meaningful experiences:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Relevance:</strong> Opt for activities that align closely with your interests, passions, or future career goals. Highlighting experiences that connect to your intended college path can enhance your overall application narrative.
    </li>
    <li class="bullet-point">
      <strong>Leadership Roles:</strong> Showcase any leadership positions where you took on significant responsibility, whether in a club, sports team, or community organization. These roles highlight your initiative and ability to guide others—qualities that colleges truly value.
    </li>
    <li class="bullet-point">
      <strong>Achievements:</strong> Include awards, recognitions, or special accomplishments earned through your activities. These accolades demonstrate your commitment and excellence in your pursuits.
    </li>
    <li class="bullet-point">
      <strong>Personal Growth:</strong> Focus on activities that contributed to your personal development, whether by overcoming challenges, acquiring new skills, or broadening your perspective. Admissions committees appreciate seeing how you have evolved through your experiences.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Presenting Your Achievements</strong>
    </p>
    <p class="chapter-description">
      Effectively showcasing your achievements can significantly enhance your college application. Here are some key tips to help you present your accomplishments in a compelling way:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Quantify Impact:</strong> Whenever possible, use numbers to illustrate the scale and impact of your involvement. For example, mention that you led a team of 10 members or helped raise $5,000 for a charitable cause. Quantifying your contributions gives admissions committees a clearer picture of your achievements.
    </li>
    <li class="bullet-point">
      <strong>Be Specific:</strong> Instead of broad descriptions, provide concrete examples of your accomplishments. Detail what you did, the challenges you faced, and the results of your efforts to make your contributions truly stand out.
    </li>
    <li class="bullet-point">
      <strong>Reflect Growth:</strong> Highlight how each activity or achievement contributed to your personal development. Share how you’ve grown, what you’ve learned, and how these experiences have shaped you into a stronger, more capable individual.
    </li>
    <li class="bullet-point">
      <strong>Connect to Goals:</strong> Tie your achievements to your future aspirations and college goals. Demonstrate how your past experiences have prepared you for the next step in your academic and career journey, making a compelling case for your readiness to excel in college.
    </li>
  </ul>
</div>

 
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Tracking Your Activities and Achievements</strong>
    </p>
    <p class="chapter-description">
      Keeping an organized record of your activities and achievements is essential for showcasing your accomplishments effectively. By maintaining a document or spreadsheet, you can ensure that no important details slip through the cracks when it's time to apply to college. Here are key elements to track:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Activity Name:</strong> Record the name of each club, organization, or event you've participated in. This helps you keep track of your involvement across various activities.
    </li>
    <li class="bullet-point">
      <strong>Dates of Involvement:</strong> Include the start and end dates of your participation, along with an estimate of how many hours you dedicated to each activity. This demonstrates your commitment and the time invested.
    </li>
    <li class="bullet-point">
      <strong>Roles and Responsibilities:</strong> Document your specific roles and responsibilities within each activity. Whether you held a leadership position or contributed as a member, detailing your contributions showcases your impact.
    </li>
    <li class="bullet-point">
      <strong>Achievements and Awards:</strong> Note any significant achievements or awards you received. This could range from recognition within the organization to external honors, providing tangible evidence of your success.
    </li>
    <li class="bullet-point">
      <strong>Personal Reflections:</strong> Take time to reflect on what you learned from each experience and how you grew personally. These insights can be valuable for articulating the significance of your involvement in future essays and interviews.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Community Service</strong>
    </p>
    <p class="chapter-description">
      Participating in community service activities can significantly strengthen your college application. Admissions counselors may view a lack of such involvement as a red flag, as it suggests limited engagement beyond academics. Consider these key points:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Types of Activities:</strong> Get involved in meaningful volunteer work, such as assisting at a local library, participating in food drives, organizing community events, or mentoring younger students. Choose activities that resonate with your passions and where you can make a tangible impact.
    </li>
    <li class="bullet-point">
      <strong>Essay Prompts:</strong> Many colleges include supplemental essay prompts related to community service. Having meaningful experiences to draw from can enhance your responses and provide deeper insight into your values and commitment to helping others.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Getting Ideas for Extracurricular Activities</strong>
    </p>
    <p class="chapter-description">
      Many students find it challenging to know where to start when it comes to extracurricular activities. The key is to begin as early as possible, allowing you to explore your interests and discover your strengths. The sooner you start, the more time you have to build a meaningful portfolio of activities:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Guidance Counselors and Teachers:</strong> Speak with your guidance counselors or teachers about the various activities available at your school or in the community. They can provide valuable insights and suggestions based on your interests.
    </li>
    <li class="bullet-point">
      <strong>Community Projects:</strong> Look for local projects or organizations that align with your passions. Getting involved in community initiatives allows you to make a tangible impact while gaining valuable experience.
    </li>
    <li class="bullet-point">
      <strong>Personal Initiatives:</strong> If you notice issues or opportunities for improvement in your community, take the initiative to address them. Starting small—like organizing a neighborhood cleanup or fundraising event—can make a significant difference.
    </li>
    <li class="bullet-point">
      <strong>Related to College Interests:</strong> Consider your academic goals and what you plan to study in college. Finding activities that relate to those interests will not only help you explore potential careers but also enhance your college application.
    </li>
    <li class="bullet-point">
      <strong>Peer Inspiration:</strong> Observe what your peers are involved in and reach out to them for ideas. Sometimes, seeing others in action can spark inspiration for your own activities.
    </li>
    <li class="bullet-point">
      <strong>Determination:</strong> Focus on building an activity profile that reflects your values and what you find fulfilling. Stick with activities that matter to you, and your commitment will stand out to colleges.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Example Activities:</strong>
    </p>
    <p class="chapter-description">
      Engage in clubs, sports, volunteer work, or other extracurricular activities that demonstrate qualities such as time management, teamwork, and passion. Consider these examples:
    </p>
    <li class="bullet-point">High school library volunteer</li>
    <li class="bullet-point">Cafeteria volunteer position</li>
    <li class="bullet-point">Volunteer at a public library</li>
    <li class="bullet-point">Create your own club</li>
    <li class="bullet-point">Establish a GoFundMe foundation or charity</li>
    <li class="bullet-point">Become a teacher's assistant (counts as leadership)</li>
    <li class="bullet-point">Become a peer tutor</li>
    <li class="bullet-point">Volunteer at religious organizations (mosque, church)</li>
    <li class="bullet-point">Volunteer at a hospital</li>
    <li class="bullet-point">Find a part-time job</li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Internship and Work Experience:</strong>
    </p>
    <li class="bullet-point">
      <strong>Gain Experience:</strong> Internships and part-time jobs provide hands-on experience in fields related to your academic or career interests. These opportunities offer insight into potential career paths and help you develop practical skills.
    </li>
    <li class="bullet-point">
      <strong>Active Engagement:</strong> Extracurricular activities, including volunteering and internships, demonstrate to colleges your ability to manage time effectively and pursue interests outside of academics. These experiences highlight your commitment to both community service and professional growth.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Awards and Honors</strong>
    </p>
    <p class="chapter-description">
      Navigating the Awards and Honors section can be confusing, and many students either misinterpret its purpose or overlook it entirely. However, nearly every student has notable achievements worth including, even if they seem minor at first glance.
    </p>
    <p class="chapter-sub-title">
      <strong>Variety of Achievements:</strong>
    </p>
    <p class="chapter-description">
      This section isn’t limited to academic awards alone. Non-academic accomplishments—such as debate victories, business competition awards, and community service recognitions—are equally valuable and should definitely be included.
    </p>
    <p class="chapter-sub-title">
      <strong>Importance of Achievements:</strong>
    </p>
    <p class="chapter-description">
      Many students feel they lack significant awards to list. It’s important to remember that even smaller achievements are worth mentioning. Whether you excelled in a class exam or won a school-level competition, these accomplishments can be included alongside more prestigious awards.
    </p>
  </div>
</div>


        {/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 7: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Effectively highlighting your activities and achievements is
              crucial for creating a strong college application. By carefully
              selecting relevant activities, presenting your achievements with
              impact, and keeping detailed records, you can showcase your
              strengths and potential to admissions committees. This chapter's
              guidelines will help you to present a comprehensive and compelling
              profile.
            </p>
          </div>
        </div>

*/}
      </div>
    </div>
  );
}

export default ActivitiesAndAchievements;
