import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/InternshipTimeLine.png";
import starInterview from "../../../assets/inner-content-images-and-files/starInterview.png";

import steminternsihp from "../../../assets/header-images/steminternsihp.jpg";

import "../../../css/SubPage/SubPage.css";

function StemMajorInternships() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={steminternsihp} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> STEM Internship Opportunities </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            Securing an internship as a STEM major is a crucial step in gaining practical experience and building your professional network. Internships allow you to take what you've learned in class and apply it to real-world challenges, helping you develop new skills and gain insight into potential career paths. This guide walks you through the internship application process, from prepping your portfolio to keeping track of your applications. Whether you're a freshman looking for specific programs or a senior ready to dive into industry experience, you'll find valuable resources and tips here to help you land that internship.
        </p>
    </div>
</div>


   {/*   <img id="timeline" src={timeline} alt="Internship Timeline" />  */}

      <div id="sections-container">

     <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Preparation for Application</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Create Projects:</strong>
      </p>
      <li class="bullet-point">
        <strong>Look up YouTube tutorials:</strong> Explore project ideas on YouTube, such as Full Stack projects, to enhance your skills and build your portfolio.
      </li>
      <li class="bullet-point">
        <strong>Avoid simple projects:</strong> Steer clear of basic projects like Snake game or Tic Tac Toe on your resume, as they may not effectively showcase your abilities.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Resume:</strong>
      </p>
      <li class="bullet-point">
        For resume templates and guides, please refer to the{" "}
        <Link to="/college/resume" className="link">
          Resume Page
        </Link>.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>LinkedIn:</strong>
      </p>
      <li class="bullet-point">
        <strong>Make a Professional Profile:</strong> Ensure your LinkedIn profile is complete and polished. For tips, check out this{" "}
        <a
          href="https://www.linkedin.com/business/sales/blog/profile-best-practices/17-steps-to-a-better-linkedin-profile-in-2017"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          guide
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Connect with Professionals:</strong> Build connections with people in your industry until you reach 500+ connections, but be sure to avoid spamming.
      </li>
      <li class="bullet-point">
        <strong>Actively Connect on LinkedIn:</strong> Reach out with an initial message asking if they’re available to meet on Zoom. Once you’ve established rapport, consider asking for referrals.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Additional Things to Do:</strong>
      </p>
      <li class="bullet-point">
        <strong>Email Professors for Research Opportunities:</strong> Inquire about potential research positions and seek guidance on your academic interests.
      </li>
      <li class="bullet-point">
        <strong>Start Doing LeetCode:</strong> Regularly practice coding problems on platforms like LeetCode to strengthen your problem-solving skills.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Additional Resources:</strong>
      </p>
      <li class="bullet-point">
        Visit <a class="link" href="https://www.reddit.com/r/cscareerquestions/">r/cscareerquestions</a> for insights and advice on navigating your career path in computer science.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Internship Searching</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Regular Internships:</strong>
      </p>
      <li class="bullet-point">
        <strong>All Types of Internships:</strong> Explore a comprehensive list of internships across various fields through this{" "}
        <a
          href="https://github.com/SimplifyJobs/Summer2025-Internships"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          resource
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Internships Without Coding Assessments:</strong> Find opportunities that do not require coding assessments by checking this{" "}
        <a
          href="https://github.com/poteto/hiring-without-whiteboards"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          list
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Top Paid Internships:</strong> Discover top-paid internships to maximize your earning potential through this{" "}
        <a
          href="https://www.levels.fyi/internships/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          guide
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Startups:</strong> Explore exciting internship opportunities at startups by visiting this{" "}
        <a
          href="https://wellfound.com/login?after_sign_in=%2Fjobs%2Fhome"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          platform
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Researching Internships:</strong> Utilize the provided resources to effectively find internships that align with your career goals. Check this{" "}
        <a
          href="https://joinhandshake.com/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          resource
        </a>.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Freshmen and Sophomore Programs:</strong>
      </p>
      <li class="bullet-point">
        <strong>Fresh/Sophomore Only:</strong> Access programs specifically designed for freshmen and sophomores by visiting this{" "}
        <a
          href="https://www.collegenav.org/programs/freshmen-sophomore-programs"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          link
        </a>.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Referrals</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Why Referral:</strong>
      </p>
      <p class="chapter-description">
        Referrals can significantly boost your chances of landing an internship or job. When a current employee refers you, it shows the employer that you are a trusted candidate, helping your application stand out. Referrals often lead to quicker interview processes and provide valuable insights about the company culture and job expectations, giving you a competitive edge. Building a strong network and seeking referrals can be a crucial step in advancing your career.
      </p>
    </ul>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Referral Request Templates:</strong>
      </p>
      <p class="chapter-description">
        <b>Note:</b> If you are asking for a referral, wait for the person to send you a link to apply. Do not apply through the company website ahead of time, as it may hinder their ability to refer you.
      </p>
      <p class="sub-title">
        <strong>You Found Someone to Ask for a Referral from a Company You Want:</strong>
      </p>
      <li class="bullet-point">
        <strong>Example 1:</strong>{" "}
        <p class="referral-text">Hello [ReferralName],</p>
        <p class="referral-text">
          Great to connect with you! My name is [YourName], and I’m a third-year undergraduate student at UCLA studying computer science. I came across your profile while looking for mentors from UCLA and noticed your experience at Google. I’m interested in software internship positions and would love to discuss any roles you think I would be a good fit for. Attached is my resume, and I'm happy to provide any other information you need. Thank you for your help!
          <p class="referral-text">Best, [YourName]</p>
        </p>
      </li>
      <li class="bullet-point">
        <strong>Example 2:</strong>{" "}
        <p class="referral-text">Hello [ReferralName],</p>
        <p class="referral-text">
          My name is [YourName], a third-year computer science undergraduate student at UCLA. I’m interested in the software engineering internship positions at Apple and wanted to see if you could share insights on any roles that might be a fit. Attached is my resume, and I’d be glad to provide more information or hop on a call. Thank you!
          <p class="referral-text">Best, [YourName]</p>
        </p>
      </li>
      <p class="sub-title">
        <strong>If Someone Posted They Are Hiring in Their Team:</strong>
      </p>
      <li class="bullet-point">
        <strong>Example 1:</strong>{" "}
        <p class="referral-text">Hello [ReferralName],</p>
        <p class="referral-text">
          Great to connect with you! My name is [YourName], and I’m a third-year undergraduate student at UCLA studying computer science. I saw your post about your team hiring at Apple Display Team. I would love the chance to contribute and gain valuable experience. Attached is my resume, which highlights my past experiences and skills. I look forward to hearing from you—thank you!
          <p class="referral-text">Best, [YourName]</p>
        </p>
      </li>
      <li class="bullet-point">
        <strong>Example 2:</strong>{" "}
        <p class="referral-text">Hello [ReferralName],</p>
        <p class="referral-text">
          My name is [YourName], and I’m a third-year computer science undergraduate student at UCLA (go Bruins!). I noticed your post about the Recommendations and ML Infra team hiring and wanted to check if there are any open intern positions. I’d be excited to join and help with your work. Attached is my resume, and I’m happy to provide any other information or have a call. Thanks!
        </p>
        <p class="referral-text">Best, [YourName]</p>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Interview Prep</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Learning Resources:</strong>
      </p>
      <li class="bullet-point">
        <strong>DSA YouTube Series:</strong> Explore tutorials on{" "}
        <a
          href="https://www.youtube.com"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a> that cover Data Structures and Algorithms—essential topics for coding interviews.
      </li>
      <li class="bullet-point">
        <strong>GeeksforGeeks:</strong> Utilize extensive resources available on{" "}
        <a
          href="https://www.geeksforgeeks.org"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          GeeksforGeeks
        </a>, which provides articles, coding problems, and shared interview experiences from peers.
      </li>
      <li class="bullet-point">
        <strong>VisuAlgo:</strong> Visualize algorithms and data structures at{" "}
        <a
          href="https://visualgo.net"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          VisuAlgo
        </a> to enhance your understanding of their implementations.
      </li>
      <li class="bullet-point">
        <strong>HackerRank:</strong> Participate in coding challenges on{" "}
        <a
          href="https://www.hackerrank.com"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          HackerRank
        </a> to sharpen your skills before the interview.
      </li>
      <li class="bullet-point">
        <strong>Cracking the Coding Interview Book:</strong> This essential resource, available on{" "}
        <a
          href="https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Amazon
        </a>, covers crucial concepts and provides practice problems to help you prepare effectively for technical interviews.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Technical Assessment Prep:</strong>
      </p>
      <li class="bullet-point">
        <strong>Tech Interview Handbook:</strong> A comprehensive guide available at{" "}
        <a
          href="https://www.techinterviewhandbook.org"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tech Interview Handbook
        </a>, outlining strategies and key topics for technical interviews.
      </li>
      <li class="bullet-point">
        <strong>Leetcode 75 Questions:</strong> This curated list can be found at{" "}
        <a
          href="https://leetcode.com/study-plan/leetcode-75"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Leetcode
        </a>, covering essential topics to help you practice effectively.
      </li>
      <li class="bullet-point">
        <strong>Neetcode 150:</strong> Explore this collection of coding questions at{" "}
        <a
          href="https://neetcode.io/practice"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Neetcode
        </a>, which emphasizes critical concepts often encountered during interviews.
      </li>
      <li class="bullet-point">
        <strong>HackerRank Interview Preparation Kit:</strong> Access structured practice problems tailored for interview preparation at{" "}
        <a
          href="https://www.hackerrank.com/interview/preparation-kit"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          HackerRank
        </a>.
      </li>
      <li class="bullet-point">
        <strong>Cracking the Coding Interview:</strong> This essential book, also available on{" "}
        <a
          href="https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Amazon
        </a>, is a must-have for understanding key strategies and practicing effectively for coding interviews.
      </li>
      <li class="bullet-point">
        <strong>NOTE:</strong> Conduct mock interviews with friends to build confidence and practice articulating your thoughts under pressure.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Phone Interview Prep:</strong>
      </p>
      <li class="bullet-point">
        <strong>Reddit Phone Interview Advice:</strong> Find shared experiences and advice specific to phone interviews on{" "}
        <a
          href="https://www.reddit.com"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reddit
        </a>, providing useful insights for preparation.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Tips for Behavioral Interviews:</strong>
      </p>
      <li class="bullet-point">
        <strong>Look up on Reddit for company questions:</strong> Many companies' behavioral or technical interview questions can be found on Reddit, posted by previous interns, providing valuable insights.
      </li>
      <li class="bullet-point">
        <strong>Research the Company and Position:</strong> Be ready to answer questions like, “Why do you want to work for us?” by understanding the company's mission and culture.
      </li>
      <li class="bullet-point">
        <strong>Have Prepared Stories/Experiences:</strong> Answer questions effectively while showcasing your skills and work ethic. Keep each response concise (4-5 sentences) to fit within the interview time.
      </li>
      <li class="bullet-point">
        <strong>Use the STAR Method:</strong> Structure your responses using the Situation, Task, Action, Result framework to clearly convey your experiences.
      </li>
    </ul>

    <ol class="bullet-point-list">
      <p class="sub-title">
        <strong>EXP Behavioral Questions:</strong>
      </p>
      <li class="bullet-point">Tell me about a time you stepped up into a leadership role.</li>
      <li class="bullet-point">Tell me about a time you failed and what you learned from it.</li>
      <li class="bullet-point">Tell me about a time you had a disagreement/conflict with a teammate. How did you handle it?</li>
      <li class="bullet-point">Give me an example of when you had to go above and beyond your responsibilities to get a job done.</li>
      <li class="bullet-point">Have you come across a problem that you could not solve? How did you handle it?</li>
      <li class="bullet-point">Tell me about a time when you did something completely different from the plan. Why did you go that route?</li>
      <li class="bullet-point">Why us?</li>
    </ol>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Interview Tips</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Interview Structure</strong>
      </p>
      <li class="bullet-point">Total interview duration is typically 45-60 minutes.</li>
      <li class="bullet-point">Behavioral questions will take around 5-10 minutes.</li>
      <li class="bullet-point">Discussion of your resume and experiences follows.</li>
      <li class="bullet-point">Expect questions on both soft and hard skills.</li>
      <li class="bullet-point">Coding assessments will take about 30-45 minutes.</li>
      <li class="bullet-point">You may be asked 1-2 coding questions.</li>
      <li class="bullet-point">There may be 5 minutes for your questions at the end.</li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Insights on Interviews</strong>
      </p>
      <li class="bullet-point">
        Remember, interviewers genuinely want to assess your fit—they're not out to get you.
      </li>
      <li class="bullet-point">
        Feel free to ask for clarification or hints if you’re unsure; you can say, “Can we circle back to that?”
      </li>
      <li class="bullet-point">
        They’re interested in your thought process, not just the final answers you provide.
      </li>
      <li class="bullet-point">
        Maintain politeness throughout, and remember the importance of the behavioral aspect of the interview.
      </li>
      <li class="bullet-point">
        Strive to demonstrate that you’re someone they would enjoy working with.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Technical Tips</strong>
      </p>
      <li class="bullet-point">
        <strong>Don’t start coding immediately!</strong> Take a moment to think through the problem and clarify any ambiguities.
      </li>
      <li class="bullet-point">
        <strong>Break down the problem.</strong> Communicate your approach to the interviewer by writing pseudo-code and outlining an initial solution.
      </li>
      <li class="bullet-point">
        <strong>Differentiate between sub-optimal and optimal solutions.</strong> This shows your understanding of various approaches.
      </li>
      <li class="bullet-point">
        <strong>Keep talking out loud.</strong> Let the interviewers in on your thought process; it provides insight into how you tackle problems.
      </li>
      <li class="bullet-point">
        <strong>Listen to feedback.</strong> The interviewer is trying to help guide you through the process.
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="sub-title">
        <strong>Additional Technical Tips</strong>
      </p>
      <li class="bullet-point">
        <strong>TEST, TEST, TEST.</strong> Always run test cases to ensure your solution works. Be mindful of edge cases that may arise.
      </li>
      <li class="bullet-point">
        <strong>Analyze time and space complexity.</strong> Interviewers often inquire about this after your coding session.
      </li>
      <li class="bullet-point">
        <strong>Be prepared to optimize your code.</strong> Interviewers may ask you to suggest improvements after your initial solution.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Additional Resources</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Application Tracking:</strong> Use{" "}
        <a
          href="https://www.cscareers.dev/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          this link
        </a>{" "}
        to effectively track your applications, deadlines, and follow-ups. Alternatively, consider organizing your applications using a structured Google Spreadsheet for better management.
      </li>
      <li class="bullet-point">
        <strong>Tech Roadmap:</strong> If you’re exploring specific areas within the tech field, check out{" "}
        <a
          href="https://roadmap.sh/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{" "}
        for STEM-focused career roadmaps, comprehensive guides, and educational content to help you navigate your career path.
      </li>
      <li class="bullet-point">
        <strong>The Missing Semester of Your CS Education:</strong> Enhance your knowledge with{" "}
        <a
          href="https://missing.csail.mit.edu/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          this resource
        </a>, which offers classes on advanced topics in Computer Science that are often overlooked in traditional curricula.
      </li>
    </ul>
  </div>
</div>




      </div>
    </div>
  );
}

export default StemMajorInternships;
