import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import mentorship from "../../../assets/header-images/mentorship.jpg";

function MentorshipPrograms() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={mentorship} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Mentorship Programs </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <div id="sub-nav-container">
          <SubNavContainer />
        </div>
      </div>
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Mentorship programs are invaluable resources that connect students with experienced professionals, alumni, or peers who provide guidance, support, and advice. These relationships significantly enhance personal, academic, and career development by offering valuable insights and fostering meaningful connections. Engaging in mentorship can empower you to navigate your academic journey, explore career paths, and cultivate the skills needed for success in your chosen field.
        </p>
    </div>
</div>

      <div id="sections-container">

        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Benefits of Mentorship Programs</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Industry Insights:</strong> Dive into valuable insights about your industry while receiving personalized career advice tailored just for you! Mentors share firsthand knowledge about trends and help you navigate exciting potential career paths.
      </li>
      <li class="bullet-point">
        <strong>Professional Development:</strong> Elevate your professional skills and expand your networking opportunities through mentorship connections! Engaging with mentors not only enhances your resume but also opens doors to exciting future possibilities.
      </li>
      <li class="bullet-point">
        <strong>Personalized Guidance:</strong> Enjoy one-on-one support that effectively guides you through your career journey! A mentor can assist in setting realistic goals, identifying your strengths, and working on areas for improvement.
      </li>
    </ul>
  </div>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Finding Mentorship Programs</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Identifying Opportunities:</strong>
      </p>
      <li class="bullet-point">
        <strong>University Programs:</strong> Many universities offer formal mentorship programs through career services, alumni associations, and academic departments. Be sure to check with these offices or clubs for exciting available programs!
      </li>
      <li class="bullet-point">
        <strong>Professional Organizations:</strong> Join professional organizations related to your field of interest! These groups often have valuable mentorship programs and networking events that can enhance your connections.
      </li>
      <li class="bullet-point">
        <strong>Online Platforms:</strong> Utilize online platforms like LinkedIn to connect with professionals and message Alumni from your university for mentorship opportunities!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Researching Programs:</strong>
      </p>
      <li class="bullet-point">
        <strong>Program Options:</strong> Explore different types of mentorship programs, such as peer mentoring, faculty mentoring, and industry professional mentoring. Consider the format (e.g., one-on-one, group mentoring) and duration (e.g., short-term, long-term) that suits you best!
      </li>
      <li class="bullet-point">
        <strong>Mentor Profiles:</strong> Review profiles of potential mentors to find a great match based on your academic and career goals. Look for mentors with experience and expertise in areas that resonate with your interests!
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Types of Mentorship Programs</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Peer Mentorship:</strong>
      </p>
      <li class="bullet-point">
        <strong>Overview:</strong> Peer mentorship programs pair students with experienced peers who provide invaluable guidance on academics, campus life, and personal development!
      </li>
      <li class="bullet-point">
        <strong>Benefits:</strong> Peer mentors offer relatable advice, unwavering support, and encouragement, fostering a strong sense of community and belonging among students!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Faculty Mentorship:</strong>
      </p>
      <li class="bullet-point">
        <strong>Overview:</strong> Faculty mentorship programs connect students with professors who provide academic guidance, research opportunities, and career advice that can shape your future!
      </li>
      <li class="bullet-point">
        <strong>Benefits:</strong> Faculty mentors offer valuable insights into your field of study, help you navigate academic challenges, and support your professional development in meaningful ways!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Professional Mentorship:</strong>
      </p>
      <li class="bullet-point">
        <strong>Overview:</strong> Professional mentorship programs match students with industry professionals who provide career advice, networking opportunities, and essential industry insights!
      </li>
      <li class="bullet-point">
        <strong>Benefits:</strong> Professional mentors share real-world experiences, assist in building a robust professional network, and guide you in making informed career decisions!
      </li>
    </ul>
  </div>
</div>

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Example Programs</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>UCLA Mentorship Program:</strong>
      </p>
      <li class="bullet-point">
        <strong>Description:</strong> Join the UCLA Mentorship Program to connect with experienced alumni and professionals in your field of study! This program facilitates invaluable guidance, career path advice, and networking opportunities that can significantly enhance your academic and professional journey.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong> <a class="link" href="https://uclaone.com/"> UCLA ONE</a>
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>CodePath:</strong>
      </p>
      <li class="bullet-point">
        <strong>Description:</strong> CodePath offers resource guides, technical mentorship, virtual workshops, and networking events designed to prepare college students for a successful career in tech! Through hands-on projects and expert guidance, participants can build essential skills and connect with industry leaders.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong> <a class="link" href="https://www.codepath.org/career-services"> CodePath</a>
      </li>
    </ul>
  </div>
</div>




<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>How to Reach Out to a Mentor</strong>
    </p>
    <p class="referral-text">Hello [Mentor Name],</p>
    <p class="referral-text">
      I hope this message finds you well! My name is [Your Name], and I am a [Your Year] year undergraduate student at [Your University] studying computer science. I recently became a mentee of the [Program Name] program, and as I explored different mentors in various fields, your profile truly stood out to me. Your extensive experience in the [Industry Name] industry, particularly your long-term commitment at [Mentor’s Company], caught my attention. I am reaching out with the hope of gaining insights into your journey in the tech field, especially your time at [Mentor’s Company], which aligns closely with my own aspirations. As a mentee, I am eager to seek advice and guidance from seasoned professionals like yourself. Learning from your experiences would be invaluable, and it has sparked my interest in potential opportunities at [Mentor’s Company]. I would be grateful for the chance to connect with you, hear your story, and receive any advice you may have for someone aspiring to follow a similar path. Your time and insights would be highly appreciated. Thank you for considering my message, and I look forward to the possibility of connecting with you!
    </p>
    <p class="referral-text">Best regards, [Your Name]</p>
  </div>
  <p>
    Note: Reaching out to someone you don't know and asking them to be your mentor may seem daunting, but receiving responses from even 2 out of 5 people is a success! Many individuals responded to me using this template, demonstrating that it is indeed possible. Take it slow initially, learn about one another, provide value, and build the relationship over 3-4 weeks. The right mentor can truly change your life!
  </p>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Engaging in a Mentorship</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Building the Relationship:</strong>
      </p>
      <li class="bullet-point">
        <strong>Initial Meeting:</strong> Schedule an initial meeting with your mentor to discuss your goals, expectations, and preferred communication methods. Establishing a regular meeting schedule that works for both of you will ensure consistent engagement and progress throughout the mentorship.
      </li>
      <li class="bullet-point">
        <strong>Open Communication:</strong> Maintain open and honest communication with your mentor. Sharing your progress, challenges, and any feedback you have fosters a collaborative and supportive mentorship environment.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Setting Goals:</strong>
      </p>
      <li class="bullet-point">
        <strong>SMART Goals:</strong> Collaborate with your mentor to set Specific, Measurable, Achievable, Relevant, and Time-bound (SMART) goals. These goals will provide a clear roadmap for your mentorship journey, helping you stay focused and motivated.
      </li>
      <li class="bullet-point">
        <strong>Action Plan:</strong> Develop an action plan with your mentor to achieve your goals. Outline specific steps, resources, and timelines to facilitate accountability and effectively track your progress.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Maximizing the Experience:</strong>
      </p>
      <li class="bullet-point">
        <strong>Active Participation:</strong> Take a proactive approach in your mentorship by seeking advice, asking questions, and initiating discussions and activities. This demonstrates your commitment to growth and enhances the overall mentorship experience.
      </li>
      <li class="bullet-point">
        <strong>Continuous Learning:</strong> Embrace the knowledge and experience your mentor offers. Be open to feedback and willing to learn from their insights, as this will enrich your learning and development journey.
      </li>
    </ul>
  </div>
</div>



        {/*
        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Engaging in a mentorship program can be a transformative
              experience that enhances your personal, academic, and career
              development. By connecting with experienced professionals, alumni,
              or peers, you gain valuable insights, build a robust support
              network, and develop the skills needed to achieve your goals.
              Explore the available mentorship programs, reach out to potential
              mentors, and make the most of these opportunities to foster your
              growth and success. Remember, the relationships you build through
              mentorship can have a lasting impact on your journey.
            </p>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}

export default MentorshipPrograms;
