import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/HSTimeLine.png";

import hsplanning from "../../../assets/header-images/hsplanning.jpg";

function HighSchool() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={hsplanning} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> High School Planning </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Preparing for college is an exciting journey that begins early in high school! Each year presents new opportunities and challenges, and making the most of them is essential to building a strong foundation for your future. This guide offers year-by-year tips to help you navigate high school successfully, from your freshman year through senior year, including the benefits of dual enrollment. By following these guidelines, you can enhance your academic and extracurricular achievements, paving the way for a smoother and more successful college application process. Let's embark on this journey together and unlock the doors to your future!
    </p>
  </div>
</div>


    {/*  <img id="timeline" src={timeline} /> */}



{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 5: Preparing for College: From High School to Higher
              Education{" "}
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


       <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        High School Course Requirements and Recommendations
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Balance Core Subjects:</strong> Aim to take a well-rounded selection of core academic subjects, including English, Math, Science, and History, alongside challenging electives that align with your interests and potential college major.
    </li>
    <li class="bullet-point">
      <strong>Consider Dual Enrollment:</strong> Look into dual enrollment programs at local community colleges to earn college credits while still in high school. This opportunity can give you a head start on fulfilling college requirements and provide valuable exposure to college-level coursework. Plus, many community college courses are often free for high school students, allowing you to save significantly on future tuition costs.
    </li>
    <li class="bullet-point">
      <strong>Advanced Courses:</strong> Enroll in Advanced Placement (AP) or International Baccalaureate (IB) courses to gain a competitive edge. These rigorous classes can offer college credits and showcase your ability to handle advanced academic material.
    </li>
    <li class="bullet-point">
      <strong>Specific College Requirements:</strong> Research the specific course prerequisites for your intended major at the colleges you are interested in. Certain fields of study may require particular high school courses, such as foreign languages or advanced mathematics.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        High School Course Requirements and Recommendations
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Balance Core Subjects:</strong> Strive for a well-rounded mix of core subjects like English, Math, Science, and History, while also diving into electives that spark your interest and align with your future college major. This balance not only keeps things interesting but also prepares you for the diverse challenges ahead.
    </li>
    <li class="bullet-point">
      <strong>Consider Dual Enrollment:</strong> If you have the opportunity, look into dual enrollment programs at nearby community colleges. Earning college credits while still in high school can give you a head start on your academic journey and help you ease into college-level coursework. Plus, many of these courses are free for high school students, which is a fantastic way to save money!
    </li>
    <li class="bullet-point">
      <strong>Advanced Courses:</strong> Think about taking Advanced Placement (AP) or International Baccalaureate (IB) courses. Not only do these classes provide the chance to earn college credits, but they also show colleges that you can handle challenging material, giving you that extra edge in your applications.
    </li>
    <li class="bullet-point">
      <strong>Specific College Requirements:</strong> It’s essential to check the specific course requirements for the majors you’re considering at different colleges. Some programs may require certain high school classes, like foreign languages or advanced math, so knowing this can help you plan your coursework effectively.
    </li>
  </ul>
</div>



{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 5: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
          </div>
        </div>

        <div id="ch-base-page-container">
          <div id="ch-base-page-text-container">
            <div id="ch-base-page-title-container">
              <h2 id="ch-base-page-title"> Chapter 6: Standardized Tests </h2>
              <div id="ch-base-page-underline"> </div>
            </div>
          </div>
        </div>

*/}
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Overview of SAT, ACT, AP Tests, and IB Tests</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Standardized Tests:</strong>
    </p>
    <li class="bullet-point">
      <strong>Common Tests:</strong> The SAT and ACT are the primary assessments that significantly influence college admissions in the U.S. Depending on your chosen programs, some colleges may also require or recommend SAT Subject Tests, AP exams, or IB exams—particularly for competitive majors. It's beneficial to verify the specific requirements for each school you're interested in.
    </li>
    <li class="bullet-point">
      <strong>Testing Policies:</strong> Be sure to explore the testing requirements for each college on your list. Many institutions have adopted test-optional policies, meaning you may not need to submit your scores. It's always a good idea to double-check whether submitting your scores is necessary or entirely up to you.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Test Preparation Tips and Resources: Conquering the Tests</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Familiarize Yourself with Test Format:</strong> Take advantage of practice tests available online and in prep books to become comfortable with the test format and question types. Official practice tests from the College Board (for SAT) or ACT.org can be especially helpful in simulating the real test experience.
    </li>
    <li class="bullet-point">
      <strong>Utilize Test Prep Resources:</strong> Dive into a variety of test prep resources, including free options like Khan Academy for SAT prep or ACT Academy. If you’re looking for more structured support, consider enrolling in preparation courses or using paid study guides to boost your readiness.
    </li>
    <li class="bullet-point">
      <strong>Balanced Approach:</strong> While standardized test scores are important, remember that they represent just one piece of your application puzzle. Focus on strengthening other aspects as well, like your extracurricular activities, essays, and letters of recommendation.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Understanding Test Scores and Their Impact on Your Application</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Role of Test Scores:</strong> Standardized test scores play a vital role in helping admissions officers compare applicants from various educational backgrounds. High scores can significantly enhance your application, opening doors to merit-based scholarships and invitations to honors programs.
    </li>
    <li class="bullet-point">
      <strong>Balanced Perspective:</strong> While high scores can bolster your application, a lower score doesn't automatically disqualify you. Strong academics, compelling essays, and active involvement in extracurriculars can effectively offset lower scores, especially at test-optional colleges.
    </li>
  </ul>
</div>


{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 6: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Preparing for college throughout high school is a process that
              involves careful planning, hard work, and taking advantage of
              available opportunities. By following these year-by-year tips, you
              can build a strong academic and extracurricular foundation, making
              the college application process smoother and more successful.
              Remember, the key to success lies in balancing your academic
              commitments with extracurricular activities, developing essential
              skills, and being proactive in your preparation for standardized
              tests. Utilize the resources available to you, seek guidance from
              counselors and mentors, and stay focused on your goals. Dual
              enrollment can provide a significant head start, and
              extracurricular activities showcase your well-rounded character.
              As you prepare, keep your future aspirations in mind and work
              towards achieving them step by step. Your efforts will pay off,
              leading to a fulfilling college experience and a bright future.
              Embrace each step of the journey, seek support when needed, and
              stay focused on your goals. Good luck on your path to college and
              beyond!
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default HighSchool;
