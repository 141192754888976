import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import LinkedInIconAboutUs from "../../../assets/Icons/LinkedInIconAboutUs.png";

import international from "../../../assets/header-images/international.jpg";

function InternationalStudents() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={international} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Guidance for International Students </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title"> Introduction </h2>
    </div>

    <p id="base-page-introduction-text-main">
      Welcome to a chapter that’s all about empowering international students on their educational journey! We understand the unique challenges you face, from navigating visa requirements to embracing a new cultural environment. This guide is designed to equip you with the knowledge and tools you need to thrive while studying abroad. Dive in as we explore practical strategies and valuable insights tailored just for you!
    </p>

    <p></p>

    <p id="introduction-text">
      <b>Note:</b> If you're eager to learn more as an international student, 
      <a className="link" href="https://www.internationalstudent.com/"> click here </a>
      for additional information.
    </p>
  </div>
</div>

{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 11: Navigating the College Application Process for
              International Students
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">



      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Understanding Visa Requirements and Regulations</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Student Visas:</strong>
    </p>

    <li className="bullet-point">
      <strong>F-1 Visa:</strong> Required for academic studies at accredited U.S. institutions, including universities and colleges. This visa also applies to students enrolling in English language programs, enabling full-time academic study in degree or certificate programs.
    </li>
    <li className="bullet-point">
      <strong>M-1 Visa:</strong> Designed for students pursuing vocational or technical training, this visa is valid for non-academic programs and cannot be transferred to academic study. Students on an M-1 visa must ensure their training aligns with their intended field of work.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Visa Application Process:</strong>
    </p>

    <li className="bullet-point">
      <strong>Secure Admission:</strong> Acceptance into a SEVP-certified U.S. school is the first step in the visa application process, as you will need necessary documents from the school.
    </li>
    <li className="bullet-point">
      <strong>Receive Form I-20:</strong> After acceptance, the school will provide Form I-20, your "Certificate of Eligibility for Nonimmigrant Student Status," which is essential for your visa application and interview.
    </li>
    <li className="bullet-point">
      <strong>Pay SEVIS Fee:</strong> Once you have Form I-20, pay the SEVIS (Student and Exchange Visitor Information System) fee via the I-901 form to support the maintenance of your student records.
    </li>
    <li className="bullet-point">
      <strong>Complete DS-160:</strong> Fill out the online nonimmigrant visa application (Form DS-160), providing personal, academic, and travel information.
    </li>
    <li className="bullet-point">
      <strong>Schedule a Visa Interview:</strong> Book your visa interview at the nearest U.S. Embassy or Consulate, as interviews are a requirement for visa approval.
    </li>
    <li className="bullet-point">
      <strong>Prepare Documents:</strong> Gather essential documentation for your visa interview, including Form I-20, DS-160 confirmation page, passport, visa fee receipt, proof of financial support, and academic records.
    </li>
    <li className="bullet-point">
      <strong>Attend Visa Interview:</strong> Be ready to articulate your reasons for studying in the U.S., how you will finance your education, and demonstrate strong ties to your home country, indicating your intent to return after your studies.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Tips for a Successful Visa Interview:</strong>
    </p>

    <li className="bullet-point">
      <strong>Be Honest:</strong> Provide truthful responses consistent with your application. Inconsistencies could lead to a visa denial.
    </li>
    <li className="bullet-point">
      <strong>Be Prepared:</strong> Familiarize yourself with your application and bring all required documents. Understanding your academic program and financial plan is crucial.
    </li>
    <li className="bullet-point">
      <strong>Be Confident:</strong> Clearly explain your choice to study in the U.S., how it will benefit your future career, and how you plan to utilize your education upon returning to your home country.
    </li>
  </ul>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>English Language Proficiency</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Testing Requirements</strong>
    </p>

    <li className="bullet-point">
      <strong>TOEFL (Test of English as a Foreign Language):</strong> Widely accepted by U.S. colleges and universities, the TOEFL evaluates reading, listening, speaking, and writing skills. Most institutions require scores between 61 and 100+, with higher-ranked schools generally expecting higher results. Some programs may have specific score requirements for each section of the test.
    </li>
    <li className="bullet-point">
      <strong>IELTS (International English Language Testing System):</strong> Increasingly recognized by U.S. institutions, the IELTS measures English proficiency across listening, reading, writing, and speaking. Scores typically range from 6.0 to 7.5, depending on the institution, and many schools now accept the IELTS alongside or instead of the TOEFL.
    </li>
    <li className="bullet-point">
      <strong>PTE (Pearson Test of English):</strong> A newer option for proving English proficiency, the PTE is gaining acceptance among U.S. institutions. This fully computer-based test assesses the four key language skills, with required scores typically ranging from 50 to 70, depending on the institution and program of study.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Preparation Tips</strong>
    </p>

    <li className="bullet-point">
      <strong>Practice Tests:</strong> Regularly taking practice tests can help you familiarize yourself with the test structure, timing, and question types. This practice can identify areas needing improvement and boost your confidence for the actual test day.
    </li>
    <li className="bullet-point">
      <strong>Language Courses:</strong> Consider enrolling in English language courses, either online or in person. These courses offer structured learning and valuable feedback from instructors on areas such as grammar, vocabulary, or pronunciation.
    </li>
    <li className="bullet-point">
      <strong>Language Labs and Tutoring:</strong> Utilize resources like language labs and tutoring services, often provided by colleges or community centers. These resources can assist in improving specific skills, such as listening comprehension or academic writing, and can be especially beneficial if you need extra help preparing for standardized tests.
    </li>
  </ul>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Financial Planning and Scholarships</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Cost Considerations:</strong>
    </p>

    <li className="bullet-point">
      <strong>Tuition Fees:</strong> Tuition varies significantly among institutions. Private universities typically have higher fees than public ones, and international students often face higher tuition rates than domestic students. Be sure to review the total costs for each school you're considering.
    </li>
    <li className="bullet-point">
      <strong>Living Expenses:</strong> Don't forget to include housing, food, utilities, transportation, and other personal costs. Cities like New York or San Francisco have higher living costs compared to smaller towns or rural areas, so factor this into your planning.
    </li>
    <li className="bullet-point">
      <strong>Health Insurance:</strong> Most international students are required to have health insurance. Costs can vary based on the provider and level of coverage, so research plans that meet your needs and fit your budget.
    </li>
    <li className="bullet-point">
      <strong>Travel Expenses:</strong> Besides flights, visa fees, and other travel costs, consider expenses for trips home during holidays or emergencies, as well as local transportation costs while studying.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Scholarship Opportunities:</strong>
    </p>

    <li className="bullet-point">
      <strong>Government Scholarships:</strong> Many countries provide scholarships to assist their citizens studying abroad. Investigate government programs in your home country, as they may cover tuition, living expenses, and even travel costs.
    </li>
    <li className="bullet-point">
      <strong>College Scholarships:</strong> Numerous U.S. colleges and universities offer scholarships specifically for international students. Check each school's financial aid page to see what is available, and take note of eligibility requirements and application deadlines.
    </li>
    <li className="bullet-point">
      <strong>Private Scholarships:</strong> Organizations like Fulbright, Rotary International, and the Ford Foundation provide scholarships for international students. These can be highly competitive, so apply early and carefully review the application requirements.
    </li>
    <li className="bullet-point">
      <strong>Work-Study Programs:</strong> Some schools allow international students to participate in work-study programs, enabling you to work part-time on campus to help finance your education. Be sure to check your eligibility and the application process, as these programs may have limited availability for international students.
    </li>
  </ul>
</div>

       

<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Cultural Adjustment</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Adjusting to New Educational Systems:</strong>
    </p>

    <li className="bullet-point">
      <strong>Classroom Culture:</strong> Prepare for a dynamic and participatory learning environment. U.S. classrooms encourage active student engagement, where discussions, questions, and collaboration play significant roles.
    </li>
    <li className="bullet-point">
      <strong>Teaching Styles:</strong> Expect diverse teaching methods, including lectures, group projects, and interactive assignments. Many courses emphasize hands-on learning through case studies and labs.
    </li>
    <li className="bullet-point">
      <strong>Student Responsibilities:</strong> Effective time management is crucial. You'll need to stay on top of assignments, participate in discussions, and seek help from professors or peers when necessary.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Social Integration:</strong>
    </p>

    <li className="bullet-point">
      <strong>Making Friends:</strong> One of the best ways to meet new people is by joining campus clubs, attending social events, or engaging in study groups. Universities offer numerous opportunities to connect with students who share similar interests.
    </li>
    <li className="bullet-point">
      <strong>Understanding Cultural Nuances:</strong> Take time to learn about U.S. cultural customs, gestures, and conversational norms. This knowledge will help you feel more comfortable in social settings and avoid unintentional misunderstandings.
    </li>
    <li className="bullet-point">
      <strong>Getting Involved:</strong> Participating in extracurricular activities like student organizations, volunteer work, or internships can help you build friendships, develop leadership skills, and feel more integrated into campus life.
    </li>
    <li className="bullet-point">
      <strong>Support Services:</strong> Utilize campus resources such as international student offices, counseling centers, and academic advisors. These services are designed to assist international students in navigating academic and cultural transitions.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Health and Well-being:</strong>
    </p>

    <li className="bullet-point">
      <strong>Mental Health Resources:</strong> Many colleges offer free or low-cost counseling services to support students' mental health. If you're feeling overwhelmed or homesick, don't hesitate to reach out for help.
    </li>
    <li className="bullet-point">
      <strong>Physical Health Services:</strong> Most campuses have student health centers where you can access medical care, health advice, and vaccinations. Familiarize yourself with the available services.
    </li>
    <li className="bullet-point">
      <strong>Stay Connected:</strong> Regular contact with family and friends back home can ease the transition and provide emotional support during challenging times.
    </li>
  </ul>
</div>

   
<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>5 Things to Know Before Coming to the U.S.</strong>
    </p>
  </div>
  <p>
    Connect with this individual on LinkedIn for additional insights and
    guidance for international students:
    <a className="footer-category-pic" href="https://www.linkedin.com/in/sohansethi/">
      <img
        className="linkedin-image"
        src={LinkedInIconAboutUs}
        alt="LinkedIn"
      />
    </a>
  </p>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>1. Landing in the U.S. is just the start of your journey:</strong>
    </p>
    <li className="bullet-point">
      The real challenges often begin after your arrival. Adapting to a new culture, navigating the complexities of legal and academic systems, and managing homesickness can be more demanding than expected.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>2. No country is a guaranteed land of opportunity:</strong>
    </p>
    <li className="bullet-point">
      Job prospects for international students are highly competitive, and securing employment can be one of the most challenging and stressful aspects of studying abroad.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>3. Education loans can become a heavy burden:</strong>
    </p>
    <li className="bullet-point">
      With high interest rates on education loans and relatively low starting salaries, repaying student loans can quickly become overwhelming, leading to long-term financial strain.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>4. You may experience periods of loneliness:</strong>
    </p>
    <li className="bullet-point">
      International students often face feelings of loneliness, depression, and anxiety, especially when dealing with academic pressure, being far from family, and adjusting to a new environment.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>5. Life can feel unpredictable, leaving you between two worlds:</strong>
    </p>
    <li className="bullet-point">
      Visa regulations, job uncertainty, and the constant balancing act between life back home and life in the U.S. can create an ongoing sense of instability, making it difficult to feel fully settled in either place.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>However, on the other side:</strong>
    </p>
    <li className="bullet-point">
      Despite the challenges, life in the U.S. can expose you to diverse cultures, new opportunities, and transformative experiences. It offers a chance to grow, build a future of freedom, and explore paths to personal and professional success in ways that can be deeply rewarding.
    </li>
  </ul>
</div>


       
<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Resources</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>1. Job Search Portals for Internships and Full-Time Jobs:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://lnkd.in/eJkwtXpS" target="_blank">
        Handshake
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://www.indeed.com" target="_blank">
        Indeed
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://www.builtin.com" target="_blank">
        BuiltIn
      </a>
    </li>
    <p>
      These websites are essential for job and internship searches, in addition to LinkedIn. They offer numerous opportunities across various industries. Handshake caters specifically to students and recent graduates, while BuiltIn focuses on tech jobs. Indeed provides a broad range of listings.
    </p>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>2. Websites to Find Jobs with H-1B Sponsorship:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://h1bgrader.com/" target="_blank">
        H1BGrader
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://h1bdata.info/" target="_blank">
        H1BData
      </a>
    </li>
    <p>
      For international students, securing job opportunities that provide H-1B visa sponsorship can be challenging. These websites help identify companies with a history of sponsoring H-1B visas, enabling you to align your job search with organizations open to hiring international candidates.
    </p>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>3. Networking:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://www.meetup.com" target="_blank">
        Meetup
      </a>
    </li>
    <p>
      Networking is crucial for job success, especially for international students. Using Meetup, you can attend events and connect with professionals in your field. These gatherings help you build connections, gain referrals, and discover job leads that may not be advertised online.
    </p>
  </ul>
</div>


{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 11: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Studying abroad as an international student presents unique
              challenges, but with careful planning and utilization of available
              resources, you can navigate the process successfully. From
              securing a visa to adapting to a new cultural environment, this
              guide provides the essential information you need to make your
              transition smoother. Embrace the opportunities and experiences
              that come with studying in a new country, and take advantage of
              the support systems available to help you succeed academically and
              personally.
            </p>
          </div>
        </div>

*/}
      </div>
    </div>
  );
}

export default InternationalStudents;
