import React, { useState, useEffect } from "react";
import "../../css/Tools/EarlyCareerSearch.css";

// Importing data from multiple JSON files
import apprenticeships from "../../assets/Data/Types/apprenticeships.json";
import events from "../../assets/Data/Types/events.json";
import fellowships from "../../assets/Data/Types/fellowships.json";
import governmentPrograms from "../../assets/Data/Types/government-programs.json";
import internships from "../../assets/Data/Types/internships.json";
import professionalOrganizations from "../../assets/Data/Types/professional-organizations.json";
import graduate_level from "../../assets/Data/Types/graduate_level.json";
import programs from "../../assets/Data/Types/programs.json";
import research from "../../assets/Data/Types/research.json";
import scholarships from "../../assets/Data/Types/scholarships.json";
import volunteers from "../../assets/Data/Types/volunteers.json";
import courses from "../../assets/Data/Types/courses.json";
import professional_developement_programs from "../../assets/Data/Types/professional-developement-programs.json";

import earlysearch from "../../assets/header-images/earlysearch.jpg";
import headerpic from "../../assets/header-images/headerpic.png";

const EarlyCareerSearch = () => {
  // Combine all imported data into a single array for easier management
  const allData = [
    ...internships,
    ...apprenticeships,
    ...programs,
    ...fellowships,
    ...professional_developement_programs,
    ...professionalOrganizations,
    ...graduate_level,
    ...governmentPrograms,
    ...scholarships,
    ...courses,
    ...events,
    ...research,
    ...volunteers,
  ];

  // State to control the number of items visible on the page
  const [visibleItems, setVisibleItems] = useState(20);

  // State to hold the current search term entered by the user
  const [searchTerm, setSearchTerm] = useState("");

  // State to hold the selected filters
  const [filters, setFilters] = useState({
    type: "",
    year: [],
    race: "",
    field: "",
  });

  // State to hold the filtered and searched data based on user input
  const [filteredItems, setFilteredItems] = useState([]);

  // Effect hook to filter and search data whenever search term or filters change
  useEffect(() => {
    const filteredData = allData.filter((item) => {
      const matchesSearch = item.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const matchesType = filters.type
        ? typeof item.type === "string"
          ? item.type.toLowerCase().includes(filters.type.toLowerCase())
          : Array.isArray(item.type)
          ? item.type.some((type) =>
              type.toLowerCase().includes(filters.type.toLowerCase())
            )
          : false
        : true;

      const matchesYear = filters.year.length
        ? Array.isArray(item.year)
          ? item.year.some((year) =>
              filters.year.includes(
                typeof year === "string" ? year.toLowerCase() : year.toString()
              )
            )
          : filters.year.includes(
              typeof item.year === "string"
                ? item.year.toLowerCase()
                : item.year?.toString()
            )
        : true;

      // Add these checks to ensure item.race and item.field are strings before calling toLowerCase
      const matchesRace = filters.race
        ? typeof item.race === "string" &&
          item.race.toLowerCase().includes(filters.race.toLowerCase())
        : true;

      const matchesField = filters.field
        ? typeof item.field === "string" &&
          item.field.toLowerCase().includes(filters.field.toLowerCase())
        : true;

      return (
        matchesSearch &&
        matchesType &&
        matchesYear &&
        matchesRace &&
        matchesField
      );
    });

    setFilteredItems(filteredData);
  }, [searchTerm, filters]);

  // Function to load more items when the "Show More" button is clicked
  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
  };

  // Function to handle changes in the search input field
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setVisibleItems(20);
  };

  // Function to handle changes in the filters
  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    // Check if the filter being changed is the "year" filter
    if (name === "year") {
      // Toggle the selected year
      setFilters((prevFilters) => {
        const selectedYears = prevFilters.year.includes(value)
          ? prevFilters.year.filter((year) => year !== value) // Deselect if already selected
          : [...prevFilters.year, value]; // Select if not already selected

        return { ...prevFilters, year: selectedYears };
      });
    } else {
      // For other filters, just update the filter normally (single selection)
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value, // Only one value for other filters
      }));
    }

    setVisibleItems(20); // Reset visible items if needed
  };

  // Get the currently visible items from the filtered data
  const visibleFilteredItems = filteredItems.slice(0, visibleItems);

  const toggleYearOutsideDropdown = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year: prevFilters.year.filter((selectedYear) => selectedYear !== year), // Remove the clicked year
    }));
  };

  return (
    <div id="ecr-background">
      <div id="Ch-face">
        <img id="header-image" src={earlysearch} alt="GPA Calculator" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p className="TOC-header-text">Early Career Search</p>
          </div>
        </div>
      </div>

      <div className="career-search-container">
        <div className="career-search-content">
          <aside className="career-search-sidebar">
            <div className="career-search-sidebar-section">
              {/* Input field for searching opportunities by name */}
              <input
                type="text"
                placeholder="Search opportunities..."
                className="career-search-input"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className="career-search-sidebar-section">
              {/* Dropdown for filtering opportunities by type */}
              <label htmlFor="type-filter" className="career-filter-label">
                Filter by Type:
              </label>
              <select
                id="type-filter"
                name="type"
                className="career-filter-select"
                value={filters.type}
                onChange={handleFilterChange}
              >
                <option value="">All Types</option>
                <option value="internship">Internships</option>
                <option value="apprenticeship">Apprenticeships</option>
                <option value="program">Programs</option>
                <option value="fellowship">Fellowships</option>
                <option value="professional-organization">
                  Professional Organization
                </option>
                <option value="professional-development-program">
                  Professional Development Programs
                </option>
                <option value="government-program">Government Programs</option>
                <option value="scholarship">Scholarships</option>
                <option value="course">Courses</option>
                <option value="event">Events</option>
                <option value="research">Research</option>
                <option value="volunteer">Volunteer</option>
              </select>
            </div>

            <div className="career-search-sidebar-section">
              {/* Dropdown for filtering opportunities by field */}
              <label htmlFor="field-filter" className="career-filter-label">
                Filter by Field:
              </label>
              <select
                id="field-filter"
                name="field"
                className="career-filter-select"
                value={filters.field}
                onChange={handleFilterChange}
              >
                <option value="">All Fields</option>
                <option value="stem">STEM</option>
                <option value="health">Health</option>
                <option value="business & finance">Business & Finance</option>
              </select>
            </div>

            <div className="career-search-sidebar-section">
              {/* Dropdown for filtering opportunities by year */}
              <label htmlFor="year-filter" className="career-filter-label">
                Filter by Year:
              </label>
              <select
                id="year-filter"
                name="year"
                className="career-filter-select"
                onChange={handleFilterChange}
                value="" // Keep this empty to allow toggling without auto-selecting
              >
                <option value="" disabled>
                  Select Years
                </option>
                {[
                  "open-to-all",
                  "highschool",
                  "community-college",
                  "freshmen",
                  "sophomore",
                  "junior",
                  "senior",
                  "graduate",
                  "postdoctoral",
                ].map((year) => (
                  <option
                    key={year}
                    value={year}
                    className={
                      filters.year.includes(year) ? "selected-option" : ""
                    }
                  >
                    {year.charAt(0).toUpperCase() + year.slice(1)}
                  </option>
                ))}
              </select>

              {/* Display selected years as clickable spans with an X */}
              <div>
                {filters.year.length > 0 && (
                  <div>
                    <p>Selected Years:</p>
                    {filters.year.map((year) => (
                      <span
                        key={year}
                        className="selected-year"
                        style={{
                          cursor: "pointer",
                          padding: "5px 10px",
                          backgroundColor: "#d3eafd", // Same color as in dropdown
                          marginRight: "10px",
                          borderRadius:
                            "15px" /* Rounded borders for better look */,
                          display: "inline-block",
                          position: "relative",
                          paddingRight: "25px" /* Ensure space for the X */,
                        }}
                      >
                        {year.charAt(0).toUpperCase() + year.slice(1)}

                        {/* X icon to remove the selected year */}
                        <span
                          onClick={() => toggleYearOutsideDropdown(year)} // Handle unselecting when X is clicked
                          style={{
                            color: "#ff0000" /* Red for the X */,
                            fontWeight: "bold",
                            fontSize:
                              "18px" /* Slightly larger X for visibility */,
                            cursor: "pointer",
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform:
                              "translateY(-50%)" /* Center vertically */,
                            padding: "0 5px",
                            borderRadius: "50%" /* Circular hover area */,
                            transition: "background-color 0.3s ease",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#ffcccc")
                          } /* Light red background on hover */
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                          } /* Reset background on hover out */
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="career-search-sidebar-section">
              {/* Dropdown for filtering opportunities by race */}
              <label htmlFor="race-filter" className="career-filter-label">
                Filter by Race:
              </label>
              <select
                id="race-filter"
                name="race"
                className="career-filter-select"
                value={filters.race}
                onChange={handleFilterChange}
              >
                <option value="">All Races</option>
                <option value="latino/hispanic">Latino/Hispanic</option>
                <option value="black/african-american">
                  Black/African American
                </option>
                <option value="asian/pacific-islander">
                  Asian/Pacific Islander
                </option>
                <option value="native-american/indigenous">
                  Native American/Indigenous
                </option>
              </select>
            </div>
          </aside>

          <div className="career-card-grid">
            {/* Mapping through the visible filtered items and rendering them as cards */}
            {visibleFilteredItems.map((item, index) => (
              <div className="career-card" key={index}>
                <div className="career-card-image-container">
                  <img
                    src={`/CompaniesLogo/${item.image}`}
                    alt={item.name}
                    className="career-card-image"
                  />
                </div>
                <div className="career-card-content">
                  <h2 className="career-card-title">{item.name}</h2>

                  <p className="career-card-type">
                    <strong>Type:</strong>{" "}
                    {Array.isArray(item.type)
                      ? item.type.join(", ")
                      : item.type}
                  </p>

                  <p className="career-card-field">
                    <strong>Field:</strong>{" "}
                    {Array.isArray(item.field)
                      ? item.field.join(", ")
                      : item.field || "N/A"}
                  </p>

                  <p className="career-card-description">{item.description}</p>

                  <p className="career-card-field">
                    <strong>Year:</strong>{" "}
                    {Array.isArray(item.year)
                      ? item.year.join(", ")
                      : item.year || "N/A"}
                  </p>

                  <p className="career-card-application-period">
                    <strong>Application Period:</strong>{" "}
                    {item["application-period"] || "N/A"}
                  </p>

                  <a
                    href={item.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="career-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Button to load more items if there are more than currently visible */}
        {filteredItems.length > visibleItems && (
          <div className="career-search-footer">
            <button className="load-more-button" onClick={loadMoreItems}>
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EarlyCareerSearch;
