import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import essaycraft1 from "../../../assets/header-images/essaycraft1.jpg";

function CollegeEssay() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={essaycraft1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Crafting Your College Essay </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Welcome to a vital chapter that equips you with the tools to craft a winning college application essay! This essay is your opportunity to shine, allowing you to express your unique voice and personality to the admissions committee. Here, you'll find essential tips and strategies to create a compelling narrative that highlights your strengths and captures their attention. Let's embark on this journey to elevate your application and make a lasting impression!
    </p>
  </div>
</div>

      {/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 9: Crafting a standout Application - Writing Your College
              Essay: Beyond grades and scores{" "}
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Importance of the College Essay</strong>
    </p>
    <p class="chapter-description">
      The college essay is a vital part of your application, offering a unique chance to showcase your voice, personality, and the qualities that set you apart as a candidate. It’s your opportunity for admissions officers to get to know you on a personal level, often serving as a pivotal factor in their decisions.
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Unique Voice:</strong> This essay is your moment to share your story and let your personality shine. It’s a chance to convey aspects of yourself that might not be evident elsewhere in your application, like your passions, values, and experiences.
    </li>
    <li class="bullet-point">
      <strong>Deciding Factor:</strong> In a competitive admissions landscape, where many applicants have similar academic profiles, a compelling essay can truly set you apart. A well-crafted essay can leave a lasting impression on admissions officers and elevate your overall application.
    </li>
    <li class="bullet-point">
      <strong>Be Specific, Not Generic:</strong> Steer clear of writing a generic essay that could apply to anyone. Admissions officers read thousands of essays, so focus on your unique story. Highlight specific experiences, challenges, or goals that reflect who you are and why you’re a great fit for the school. Personal and authentic writing is not only memorable but also impactful.
    </li>
  </ul>
</div>


       
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Tips for Writing and Revising Your Essay</strong>
    </p>
    <p class="chapter-description">
      Crafting a strong college essay takes time and dedication. Start early, allow for multiple drafts, and seek constructive feedback from trusted teachers or mentors. A well-written essay should be personal, authentic, and reflective, providing admissions committees with insight into your growth, individuality, and perspective.
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Start Early:</strong> Give yourself ample time to brainstorm, write, and revise. Starting early allows you to explore various topics, refine your ideas, and create multiple drafts. Engaging with teachers or counselors early on ensures that your essay is on the right track.
    </li>
    <li class="bullet-point">
      <strong>Be Specific and Personal:</strong> While experiences like volunteering at a library or community center are common, what makes your journey unique are the specific lessons and personal growth you gained. Focus on how these experiences shaped you—be personal, share your insights, and let your authentic voice shine.
    </li>
    <li class="bullet-point">
      <strong>Demonstrate, Don’t Just Tell:</strong> Instead of merely stating your qualities or achievements, use storytelling and detailed examples to bring them to life. Show admissions committees how your actions reflect your character, leadership, or growth, helping them envision your potential as a valuable part of their community.
    </li>
    <li class="bullet-point">
      <strong>Proofread and Edit:</strong> After completing several drafts, carefully review your essay for clarity, flow, and grammatical accuracy. Ensure it adheres to the word count. Seek feedback from mentors or teachers to catch any errors you might have missed, and polish your final essay to make a lasting impact.
    </li>
  </ul>
</div>

 

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Examples of Successful College Essays</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Read Examples:</strong> Take time to explore successful college essays, whether online or in college prep books. These examples can provide valuable insights into what makes an essay stand out and how other students effectively communicated their personal stories.
    </li>
    <li class="bullet-point">
      <strong>Analyze Structure:</strong> As you read these essays, pay attention to their structure, themes, and voice. Notice how the authors organize their thoughts, introduce personal anecdotes, and maintain a consistent tone throughout. This analysis will help you understand effective techniques for your own college essay.
    </li>
    <li class="bullet-point">
      <strong>Find Inspiration:</strong> Use these examples as a source of inspiration, but remember not to replicate someone else’s work. Your goal is to develop your unique voice and tell your personal story. Authenticity is key to making a strong impression, so let these examples spark ideas that lead to an essay that is distinctly yours.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Steps to Craft a Compelling College Essay</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Start Early and Manage Time Wisely:</strong>
    </p>

    <li class="bullet-point">
      <strong>Begin Early:</strong> Allow yourself plenty of time to develop your essay. Starting early enables you to brainstorm, draft, and revise your work without the pressure of last-minute deadlines. A thoughtful, polished essay takes time, so begin as soon as possible.
    </li>
    <li class="bullet-point">
      <strong>Exceed Word Count Initially:</strong> When drafting your essay, aim to write beyond the required word count. This approach gives you a wealth of content to work with, making it easier to refine and trim down during the editing process, ensuring you retain only the most important and impactful elements.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Strong Essay Prompts:</strong>
    </p>

    <li class="bullet-point">
      <strong>Leadership Experience:</strong> Reflect on a time when you took on a leadership role. Describe the challenges you faced, the decisions you made, and the qualities you developed during this experience. Highlight how these moments have shaped you as a leader.
    </li>

    <li class="bullet-point">
      <strong>Challenges/Obstacles:</strong> Share a personal challenge or significant obstacle you have overcome. Explain how this experience shaped your resilience and determination, along with the lessons learned. Admissions officers value stories that demonstrate growth and perseverance.
    </li>

    <li class="bullet-point">
      <strong>Community Involvement:</strong> Discuss how your involvement in community service, extracurricular activities, or volunteer work has influenced your perspective and aspirations. This is an opportunity to showcase how your contributions have impacted others and how these experiences have shaped your future goals.
    </li>

    <li class="bullet-point">
      <strong>Personal Statement:</strong> In your personal statement, share your background, personal interests, and educational and career goals. Use this essay to provide admissions officers with a better understanding of who you are as a person and what drives your ambitions.
    </li>

    <li class="bullet-point">
      <strong>Academic Goals:</strong> Outline your academic interests and explain how the university will help you achieve your academic and professional aspirations. Be specific about how the school's programs, resources, or faculty align with your goals.
    </li>
  </ul>
</div>

  

<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Writing Essays That Win</strong>
    </p>
    {/* IMAGE HERE */}
    <ul className="bullet-point-list">
      <p>
        <strong>Draft 1:</strong> Start by reading the prompt carefully and writing freely about anything related to the topic. Aim to write at least 1.5 to 2 times the required word count, providing ample material to refine later.
      </p>
      <li className="bullet-point">
        <strong>Utilize ChatGPT or Gemini for Enhancement:</strong>
        <ul>
          <li>
            <b>Note:</b> While tools like ChatGPT can help improve your essay, ensure your voice and originality remain intact. Use these tools to enhance, not replace, your writing.
          </li>
          <li>
            <strong>How to Leverage ChatGPT or Gemini:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                Copy your draft and paste it into ChatGPT.
              </li>
              <li className="bullet-point">
                Include the essay prompt and desired word count to guide ChatGPT’s suggestions.
              </li>
              <li className="bullet-point">
                <strong>Commands:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    Paste your essay and add:
                    <br /><br />
                    "Using the text above, answer the essay prompt"
                    <br /><br />
                    [Insert essay prompt] in [Insert word count].
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                ChatGPT will provide suggestions for clarity, flow, and conciseness. Always review its suggestions to ensure they align with your style and voice.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <hr />
      <br />

      <p>
        <strong>Draft 2 - Refine and Focus:</strong> After your initial draft, carefully review and cut unnecessary information. Focus on refining key details and streamlining your essay for better coherence and impact.
      </p>
      <li className="bullet-point">
        <strong>Utilize ChatGPT for Enhancement:</strong>
        <ul>
          <li>
            Once you're satisfied with the content, you can use ChatGPT for final enhancements. For example, ask it to "polish the essay" while maintaining a natural and human tone.
            <ul className="bullet-point-list">
              <li className="bullet-point">
                If the language becomes too complex, request ChatGPT to "simplify the tone" or "make it sound human" for a more conversational style.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <br />
      <hr />
      <br />

      <p>
        <strong>Final Review:</strong> Conduct a thorough final review to ensure your essay effectively addresses the scholarship prompt and presents your best work.
      </p>
      <li className="bullet-point">
        <strong>Seek Feedback from ChatGPT:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            Open a new chat and ask ChatGPT to rate your essay out of 10 based on its alignment with the prompt. Aim for a rating of 8 or higher.
          </li>
          <li className="bullet-point">
            <strong>Commands:</strong> Start a fresh chat and use:
            <ul className="bullet-point-list">
              <li className="bullet-point">
                "Please review the essay below and provide feedback: [Insert your essay]."
              </li>
              <li className="bullet-point">
                "Does this essay address the prompt: [Insert prompt]?"
              </li>
              <li className="bullet-point">
                "Please rate this essay out of 10."
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Seek Feedback from Others:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            Ask a teacher, counselor, or mentor to review your essay and provide feedback on structure, content, and overall impression.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Request Essay Review from Us:</strong>
        <ul>
          <li>
            We offer detailed essay reviews. Submit your essay for in-depth feedback on how well it addresses the prompt and ways to refine it further for submission.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>



{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 9: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Crafting a standout college essay involves thoughtful preparation,
              personal reflection, and continuous revision. By starting early,
              seeking feedback, and utilizing resources like AI tools and
              trusted mentors, you can create a compelling narrative that
              showcases your unique qualities and enhances your college
              application.
            </p>
          </div>
        </div>
*/}

      </div>
    </div>
  );
}

export default CollegeEssay;
