import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import Roadmap1 from "../../../assets/header-images/Roadmap1.jpg";

function HighSchoolRoadMap() {
  return (
    <div id="newest-background">

      <div id="Ch-face">
        <img id="header-image" src={Roadmap1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> High School Roadmap </p>
          </div>
        </div>


      </div>


      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Preparing for college is a comprehensive journey that begins early in high school. Each academic year presents new opportunities and challenges that contribute to your readiness for higher education. This guide offers year-by-year strategies to navigate high school effectively, from freshman through senior year, highlighting essential steps such as course selection, extracurricular involvement, and financial planning. By taking a proactive approach and leveraging available resources, you can build a strong foundation for a successful transition to college.
    </p>
  </div>
</div>


      <div id="whole-section-page-container">

        
          

          
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Prepare for College as a Freshman in High School</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Choose a Challenging but Balanced Course Load:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Core and Elective Mix: Opt for a diverse blend of core subjects alongside electives that resonate with your interests and strengths. This balance will not only help you excel academically but also allow you to explore your passions.
          </li>
          <li class="bullet-point">
            Plan for Advanced Courses: As you map out your high school career, aim to enroll in Honors, accelerated, or AP courses during your junior and senior years. Early planning keeps your academic options open.
          </li>
          <li class="bullet-point">
            AP Courses: AP courses reflect university-level content and provide an excellent chance to earn college credit while still in high school, giving you a head start on your degree journey.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get a Head Start: Dual Enrollment allows you to take college-level courses while in high school, enabling you to earn both high school and college credits. It's a fantastic way to begin building your academic resume early. Learn more about this option at the bottom of the page.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Meet Core Requirements:</strong> Ensure you're fulfilling your school’s core graduation requirements early:
        <ul class="bullet-point-list">
          <li class="bullet-point">Four years of English</li>
          <li class="bullet-point">Three years of social studies, including history and government</li>
          <li class="bullet-point">Three years of mathematics</li>
          <li class="bullet-point">Three years of science, including lab-based courses</li>
          <li class="bullet-point">Two years of foreign language to enhance global understanding</li>
          <li class="bullet-point">Two years of Physical Education for overall well-being</li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Get Extra Help Where Needed:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Seek Support: Don’t hesitate to reach out for help, whether from teachers, classmates, older students, or professional tutors. Asking for assistance is a sign of strength, not weakness.
          </li>
          <li class="bullet-point">
            Online Resources: Utilize free online learning platforms like Khan Academy or Coursera, which offer tutorials on a variety of subjects you might need extra support with.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Begin Building Your Extracurriculars:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Join Activities: Get involved in clubs, sports teams, or volunteer opportunities that reflect your interests. This is your chance to start building a well-rounded profile and developing valuable leadership skills.
          </li>
          <li class="bullet-point">
            Part-Time Jobs: Taking on a part-time job demonstrates responsibility and time management, and it counts as a valuable extracurricular activity when applying for college.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Enjoy High School:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Balance Work and Fun: While preparing for college is important, don't forget to enjoy the high school experience. Balance your academics with relaxation and social activities. Remember, high school is about learning, growing, and having fun!
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>


          

          
        

        
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Sophomore in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Check In With Your Guidance Counselor:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Track Your Progress: Regular meetings with your guidance counselor can help ensure you're on track to meet both your high school graduation requirements and the prerequisites necessary for college admission.
          </li>
          <li class="bullet-point">
            Plan Ahead: Discuss upcoming course options and make necessary adjustments to keep your academic goals aligned. Early planning not only helps you stay organized but also focuses your efforts on long-term success.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep Your Grades and Courses Up:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Maintain Strong Grades: Focus on achieving high grades this year, as sophomore year marks an important contribution to your overall GPA—an essential factor for college admissions.
          </li>
          <li class="bullet-point">
            Prepare for Junior and Senior Years: Opt for challenging courses now to set yourself up for advanced classes later. A strong academic record will create more opportunities when applying to colleges.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Ahead with College Credits: Consider Dual Enrollment to take college-level courses while in high school. This opportunity not only allows you to earn college credits early but also eases your future academic load. More information about this can be found at the bottom of this page.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep Building on Your Extracurriculars:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Join Clubs and Activities: Continue your involvement in clubs, sports teams, leadership roles, or volunteer work. Engaging in these activities not only enhances your resume but also allows you to explore your interests and develop crucial skills.
          </li>
          <li class="bullet-point">
            Part-Time Jobs: If your schedule allows, consider taking on a part-time job. Work experience demonstrates responsibility and time management—qualities that colleges and future employers highly value.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Take the PSAT:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Prepare for the SAT: Taking the PSAT is an excellent way to prepare for the SAT. It familiarizes you with the format and content of standardized tests and automatically enters you into the National Merit Scholarship contest.
          </li>
          <li class="bullet-point">
            Website:{" "}
            <a className="link" href="https://www.collegeboard.org/">
              CollegeBoard
            </a>
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Have an Idea About College:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Explore Interests: Start reflecting on the subjects or fields of study that intrigue you the most. You don’t need to decide right away, but exploring your options now will guide your decisions later.
          </li>
          <li class="bullet-point">
            Stay Open-Minded: Understand that it's completely normal for your interests to evolve over time. Keeping an open mind allows you to discover new possibilities while having a general direction can be beneficial.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Look Into College Costs:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Research Costs: Begin investigating the potential costs of college, including tuition, room and board, and other expenses. Use university websites to find detailed cost breakdowns to plan ahead effectively.
          </li>
          <li class="bullet-point">
            Plan Financially: Discuss savings strategies with your family if they haven’t already started. Understanding the financial implications early on will prepare you to explore scholarship and financial aid options down the line.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Visit a College:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Familiar with College Environment: If possible, take the opportunity to visit a nearby college campus. Experiencing campus life firsthand can help you visualize your future and inspire you to stay focused on your academic goals.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>
          Begin Preparing for College Entrance Exams and College Applications:
        </strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Learn About Exams: Familiarize yourself with the SAT, ACT, and college application processes. Knowing what to expect will help alleviate stress as you transition into your junior year.
          </li>
          <li class="bullet-point">
            Prepare Early: Start studying for these exams and reviewing sample questions to feel more comfortable and confident when test day arrives.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

          
        

       
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Junior in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>
          Keep Your Grades and Extracurricular Activities Up:
        </strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Maintain Strong Grades: Your junior year is a pivotal moment for college admissions. Focus on keeping your grades high, as they significantly impact your GPA, which is essential for your future applications.
          </li>
          <li class="bullet-point">
            Build Extracurricular Accomplishments: Stay actively involved in clubs, sports, leadership roles, and community service. Colleges seek well-rounded students who excel academically while showing dedication to extracurricular activities that demonstrate growth and commitment.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Take Challenging Classes:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Enroll in Accelerated Courses and AP Classes: Choosing advanced courses such as Honors or AP classes showcases your ability to tackle rigorous coursework. These classes not only provide a taste of college-level material but may also earn you college credits, easing your academic load later on.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Ahead with College Units: Dual Enrollment gives you the chance to take actual college courses and earn credits while still in high school. This experience not only prepares you for the rigors of university studies but also provides an excellent way to lighten your future course load. Learn more about this opportunity here.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Explore Colleges:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Research Factors: Begin exploring various colleges and universities, keeping in mind factors like school size, location, available majors, campus culture, and extracurricular opportunities. This exploration will help you find a college that feels like the right fit for you.
          </li>
          <li class="bullet-point">
            Use Search Tools: Utilize online resources like{" "}
            <a
              className="link"
              href="https://bigfuture.collegeboard.org/college-search/filters"
            >
              Big Future's College Search
            </a>{" "}
            to refine your college options based on your preferences and needs.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>The "Information About Yourself" Template:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Choose Between SAT and ACT:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Evaluate Your Strengths: The SAT focuses on reading, writing, and math, while the ACT includes an additional science section. Determine which exam suits your skills and study habits better.
              </li>
              <li class="bullet-point">
                Research College Requirements: Some colleges prefer one test over the other, so check the requirements of your target schools before making a decision.
              </li>
              <li class="bullet-point">
                Take Practice Tests: Use practice tests to familiarize yourself with both exams and identify which one you feel more comfortable with.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Plan Your Exam Date:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Aim for End of Junior Year: Schedule your chosen exam (SAT or ACT) for the end of your junior year, allowing ample time to study and prepare.
              </li>
              <li class="bullet-point">
                Schedule a Retake if Necessary: If your initial score doesn't meet your expectations, plan to retake the exam early in your senior year for a better result.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Prepare for the Exam:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Use Study Resources: Regularly study using platforms like Magoosh, Khan Academy, and other SAT/ACT prep tools to strengthen your knowledge and improve your test-taking skills.
              </li>
              <li class="bullet-point">
                Practice Regularly: Take practice exams and review areas where you need improvement. Consistent practice is vital for building confidence by test day.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Useful Links:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Website:{" "}
                <a
                  className="link"
                  href="https://www.collegeboard.org/"
                >
                  SAT
                </a>
              </li>
              <li class="bullet-point">
                Website:{" "}
                <a className="link" href="https://www.act.org/">
                  ACT
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Start Keeping an Eye Out for Scholarships:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Scholarships Page: Visit the{" "}
            <a className="link" href="/afford-college/scholarship">
              Scholarships page
            </a>{" "}
            on this website for a comprehensive guide on finding and applying for scholarships. Early preparation is crucial for securing financial aid.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Meet With Your Guidance Counselor:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Verify Graduation Requirements: Confirm that you are on track to meet all of your high school’s graduation requirements, ensuring there are no surprises as you approach senior year.
          </li>
          <li class="bullet-point">
            Plan for the Final Stretch: Discuss any remaining tasks or courses you need to complete for a smooth transition into graduation and college application season.
          </li>
          <li class="bullet-point">
            Stay Motivated: While junior year can be challenging, remember that you are nearing the finish line! Stay focused on your goals to set yourself up for success.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Start Visiting Colleges:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Visit Colleges Over Summer or During the School Year: If possible, schedule visits to nearby colleges to gain insight into campus life, dorms, and facilities. Experiencing the campus atmosphere can help you envision your future and motivate you to stay focused on your goals.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Begin Working on College Applications:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Start Brainstorming: Use your summer to begin brainstorming your college applications, especially potential essay topics. Reflect on what makes you unique and how you want to present yourself to admissions committees.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>


          
       

        
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Senior in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Ask Teachers for Recommendations Early:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Request Recommendations Early: Approach your teachers during the summer to give them ample time to craft thoughtful letters of recommendation. Early requests increase your chances of receiving strong, personalized letters.
          </li>
          <li class="bullet-point">
            Provide Necessary Information: Share your resume, academic achievements, and specific qualities you want them to emphasize. This helps your teachers write a comprehensive and compelling recommendation tailored to your strengths.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Retake Your SAT or ACT if Needed:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Study Over the Summer: If you aim to improve your score, plan to retake the exam in early fall. Even a modest score boost can significantly impact your college applications.
          </li>
          <li class="bullet-point">
            Use Targeted Resources: Take advantage of platforms like Khan Academy and other SAT/ACT prep materials to focus on areas needing improvement. Practice tests will help gauge your progress.
          </li>
          <li class="bullet-point">
            Create a Study Plan: Develop a detailed study schedule to ensure you cover all necessary content before the exam, so you feel confident on test day.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Get Your College Applications Done Early:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Aim for Early Submission: Target late summer or early fall for application submissions to avoid last-minute stress and ensure all materials are polished and complete.
          </li>
          <li class="bullet-point">
            Review Application Requirements: Carefully check each college's application requirements and deadlines to ensure you don’t miss anything crucial, like supplemental essays or portfolio submissions.
          </li>
          <li class="bullet-point">
            Use the College Application Page: Refer to this page for detailed guidance on completing and submitting your applications effectively.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Remind Your Recommendation Writers:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Send Polite Reminders: A few weeks before deadlines, gently remind your recommendation writers to finalize their letters, giving them enough time to do so.
          </li>
          <li class="bullet-point">
            Offer Additional Support: Be available to provide any extra information they might need to write a strong letter.
          </li>
          <li class="bullet-point">
            Follow Up with Gratitude: Afterward, send a thank-you note to express your appreciation for their support and time.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep an Eye on Application Deadlines:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Create a Deadline Tracker: Develop a detailed calendar or spreadsheet to track all college application deadlines, including early decision or early action programs.
          </li>
          <li class="bullet-point">
            Set Personal Deadlines: Establish your own deadlines a few days before the official ones, allowing time to review and ensure everything is accurate and complete.
          </li>
          <li class="bullet-point">
            Double-Check Submissions: Carefully review each application to confirm all required components, such as essays and recommendation letters, are included and properly formatted.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Complete Your Scholarship Applications:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Apply Early: Begin your scholarship applications as soon as possible to maximize your chances of securing financial aid, keeping in mind that many scholarships have early deadlines.
          </li>
          <li class="bullet-point">
            Tailor Your Applications: Research scholarships that align with your background and interests, customizing your applications to highlight why you are a strong candidate for each opportunity.
          </li>
          <li class="bullet-point">
            Use the Scholarships Page: Visit this page for a comprehensive guide on effectively finding and applying for scholarships.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Fill Out Your FAFSA:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Complete the FAFSA Early: Submit the Free Application for Federal Student Aid (FAFSA) as soon as it opens on October 1st. Early submission increases your eligibility for financial aid, including grants and scholarships.
          </li>
          <li class="bullet-point">
            Gather Financial Documents: Prepare all necessary financial documents, such as tax returns and income statements, in advance to streamline the application process.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Visit Colleges You Are Accepted To:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Familiar with Campus Life: If possible, visit colleges where you have been accepted. Experiencing the campus atmosphere firsthand will help you make a more informed decision about where to spend your next four years.
          </li>
          <li class="bullet-point">
            Attend Events and Interact: Participate in open houses, attend classes, and engage with current students to gain insights into each school’s academic and social environment.
          </li>
          <li class="bullet-point">
            Evaluate Programs and Support Services: Take the time to assess the programs, facilities, and support services offered at each college to ensure they align with your goals and needs.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Review Financial Aid Packages:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Analyze Your Offers: Carefully review the financial aid packages from the colleges that accepted you, comparing the total cost of attendance and the breakdown of grants, scholarships, loans, and work-study options.
          </li>
          <li class="bullet-point">
            Discuss Options with Family: Engage in conversations with your family about these financial options to determine which offer best fits your financial situation and educational aspirations.
          </li>
          <li class="bullet-point">
            Seek Clarification When Needed: Don’t hesitate to contact the financial aid office at any institution where you require additional information or clarification.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Notify Your Chosen School and Submit the Deposit:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Secure Your Spot: Once you’ve made your decision, notify your chosen school and submit the deposit by the deadline to confirm your place in the incoming freshman class.
          </li>
          <li class="bullet-point">
            Inform Other Schools: Politely notify the other colleges that you will not be attending, allowing them to offer your spot to another student.
          </li>
          <li class="bullet-point">
            Celebrate Your Achievement: Take a moment to enjoy this accomplishment and start preparing for the exciting journey ahead in college!
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

         
       

        
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Dual Enrollment</strong>
    </p>
    <p id="ch-base-page-text-main">
      Dual Enrollment offers high school students a unique chance to take college courses and earn valuable credits. This pathway not only provides a head start on your college education but can also be more advantageous than AP classes for many. By participating in Dual Enrollment, you can save both time and money on your college journey, making it a strategic option worth considering. To explore the benefits and requirements of Dual Enrollment, check out this resource:{" "}
      <a
        className="link"
        href="https://www.cccco.edu/About-Us/Chancellors-Office/Divisions/Educational-Services-and-Support/What-we-do/Dual-Enrollment"
      >
        Dual Enrollment
      </a>.
    </p>
  </div>
</div>


          
       


 {/*     

        <div id="toc-conclusion-container">
          <div id="toc-conclusion-text-container">
            <div id="toc-conclusion-title-container">
              <h2 id="toc-conclusion-title">Conclusion</h2>
              <div id="toc-page-conclusion-underline"></div>
            </div>
            <p id="toc-conclusion-text-main">
              Preparing for college throughout high school is a process that
              involves careful planning, hard work, and taking advantage of
              available opportunities. By following these year-by-year tips, you
              can build a strong academic and extracurricular foundation, making
              the college application process smoother and more successful.
              Embrace each step of the journey, seek support when needed, and
              stay focused on your goals. Your efforts will pay off, leading to
              a fulfilling college experience and a bright future.
            </p>
            <div id="toc-conclusion-end-decorator"></div>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default HighSchoolRoadMap;















