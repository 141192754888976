import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function MaximizeScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 3</p>
            <p id = "sub-scholarship-ch-header-text">Maximizing Your Chances of Success</p>
            <p id = "sub-scholarship-ch-text-two">Strategic Tips for Winning</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            This chapter explores effective strategies to significantly enhance your chances of success in scholarship applications while helping you avoid common pitfalls that could hinder your efforts. By applying these tips and insights, you'll be better prepared to present your best self to scholarship committees, thereby increasing your opportunities for funding your education.
        </p>
    </div>
</div>


      <div id="whole-section-page-container">


        
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Application Tips</strong>
            </p>
            <ul className="bullet-point-list">
              <p className="chapter-description">
                <strong>Start Locally:</strong>
              </p>
              <li className="bullet-point">
                Apply to local scholarships first to reduce competition and
                increase your chances of success.
              </li>
              <p className="chapter-description">
                <strong>Avoid Rushing and Spamming:</strong>
              </p>
              <li className="bullet-point">
                After applying to multiple scholarships, you may feel
                fatigued. Take some time to recharge, but make sure there
                are no deadlines coming up.
              </li>
              <li className="bullet-point">
                Never rush through applications; quality matters. The more
                polished your application, the greater your likelihood of
                success.
              </li>
              <p className="chapter-description">
                <strong>Application Tips:</strong>
              </p>
              <li className="bullet-point">
                <strong>If You Fit the Requirement, Just Apply:</strong>
                Don’t second-guess; if you meet the criteria, submit your
                application.
              </li>
              <li className="bullet-point">
                <strong>Turn in Scholarships Early:</strong> Start looking
                for spring scholarships now!
              </li>
              <li className="bullet-point">
                <strong>Don’t underestimate scholarships with unknown value:</strong> Don't underestimate scholarships that don't list how much they're worth. You might think some are worth $500 and it could actually be worth $10k renewable.
              </li>
              <li className="bullet-point">
                <strong>Prioritize ones you have the highest chances:</strong> Apply to as many as possible, but remember that it's not possible to apply to all of them. You only have so much time, so prioritize it wisely and focus on ones you actually have a serious shot at winning.
              </li>
              <li className="bullet-point">
                <strong>Complete FAFSA Early:</strong> Fill out your FAFSA as soon as it opens in January, because lots of other scholarships will ask for it.
              </li>
              <li className="bullet-point">
                <strong>Draft and Refine Your Essay:</strong> Draft your
                essays and get at least two people at school to look over
                them. Read it out loud. Make a draft that's longer than the
                essay supposed to be, and find bad parts to take out after
                finishing the draft.
              </li>
              <li className="bullet-point">
                <strong>Be Detailed in Your Essay:</strong> Be sure to
                include something in your essays about how the career you're
                going into will help the community. If you're undecided,
                just pick one to write about because they know that over 50%
                of students switch majors anyway. Obviously don't apply for
                scholarships specifically for a major you have no interest
                in, but if you're just deciding between a couple careers
                write about the one that is most in-tune with the
                scholarship.
              </li>
              <li className="bullet-point">
                <strong>Quality over Quantity:</strong> Focus on crafting
                strong applications for relevant scholarships rather than
                applying to a massive number of irrelevant ones.
              </li>
              <li className="bullet-point">
                <strong>Read Application Requirements Carefully:</strong> Ensure you meet all eligibility criteria before applying.
                Don't waste time on scholarships you can't qualify for.
              </li>
              <li className="bullet-point">
                <strong>Avoid Scholarship Scams:</strong> Be wary of
                websites that request application fees, guarantee
                scholarships, or no essay scholarship. Legitimate
                scholarships are free to apply for.
              </li>
              <li className="bullet-point">
                <strong>Additional Tips:</strong> Visit{" "}
                <a
                  className="link"
                  href="https://www.dvc.edu/student-services/financial-aid/scholarships/scholarship-tips.html"
                >
                  DVC Scholarship Tips
                </a>{" "}
                for more insights.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Avoiding Common Pitfalls and Scams</strong>
    </p>
    <p className="chapter-description">
      Be cautious of scholarship scams that take advantage of students' financial concerns. Knowing the red flags can help you avoid falling into these traps.
    </p>
    <p className="chapter-description">
      <strong>Red Flags to Watch Out For:</strong>
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        Be cautious of "no essay" scholarships—these often have high competition and may not be worth your time.
      </li>
      <li className="bullet-point">
        Be wary of untrustworthy or suspicious websites that seem sketchy.
      </li>
      <li className="bullet-point">
        Never apply to scholarships that require application fees—legitimate scholarships don’t charge fees.
      </li>
      <li className="bullet-point">
        Avoid scholarships with unclear eligibility requirements or confusing application processes.
      </li>
    </ul>
    <p className="chapter-description">
      <strong>Websites that didn’t work for us:</strong>
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <a className="link" href="https://www.scholarships.com/">
          Scholarships.com
        </a>
      </li>
      <li className="bullet-point">
        <a className="link" href="https://www.fastweb.com/">
          Fastweb
        </a>
      </li>
      <li className="bullet-point">
        <a className="link" href="https://www.goingmerry.com/">
          Going Merry
        </a>
      </li>
      <li className="bullet-point">
        <a className="link" href="https://www.scholarshipowl.com/">
          Scholarship Owl
        </a>
      </li>
      <li className="bullet-point">
        <a className="link" href="https://bold.org/">
          Bold.org
        </a>
      </li>
    </ul>
    <p className="chapter-description">
      <strong>Note:</strong> While these websites are widely used, they may not always yield significant results. Consider them after exploring more targeted resources and recommended scholarships.
    </p>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Organization and Time Management</strong>
    </p>

    <p className="chapter-description">
      <strong>
        Stay organized throughout the process! Here are some tips to help you manage your time efficiently and stay on track:
      </strong>
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Create a Spreadsheet:</strong> Use this <a className="link" href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing">Scholarship Tracker</a> template to keep track of deadlines, requirements, and your progress for each scholarship.
      </li>
      <li className="bullet-point">
        <strong>Set Calendar Reminders:</strong> Use calendar reminders to alert you about upcoming deadlines and scholarship-related events so you don’t miss important dates.
      </li>
      <li className="bullet-point">
        <strong>Prioritize Early Applications:</strong> Some scholarships are awarded on a rolling basis, meaning the sooner you apply, the better your chances. Make early applications a priority to improve your odds.
      </li>
    </ul>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>The Power of Persistence</strong>
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Keep Applying:</strong> Don’t let rejections discourage you. Persistence is key to eventually securing the right opportunities.
      </li>
      <li className="bullet-point">
        <strong>Learn and Refine:</strong> Treat each application as a chance to learn. Take note of what worked and refine your materials for future submissions.
      </li>
      <li className="bullet-point">
        <strong>Stay Motivated:</strong> Keep your goals in mind and stay motivated. Refine your approach along the way, and remember that persistence pays off.
      </li>
    </ul>
  </div>
</div>



      </div>
    </div>
  );
}

export default MaximizeScholarship;
