import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import coursera_audit from "../../../assets/inner-content-images-and-files/coursera_audit.png";
import "../../../css/SubPage/SubPage.css";

import educationalres from "../../../assets/header-images/educationalres.jpg";

function FreeLearningTools() {
  return (
    <div id="newest-background">

      <div id="Ch-face">
        <img id="header-image" src={educationalres} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Free Educational Resources </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>


      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            In today’s digital age, accessing quality educational resources has become easier than ever! With an abundance of free online learning platforms at your fingertips, you can explore courses, tutorials, and certifications that empower you to build skills and expand your knowledge across various fields. Whether you’re looking to enhance your technical expertise or dive into new subjects, these platforms offer invaluable opportunities for personal and professional growth.
        </p>
    </div>
</div>

      <div id="sections-container">



        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Program Listings</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Coursera:</strong>
      </p>
      <p class="chapter-description">
        Coursera provides free online courses, specializations, and degrees from top universities and companies. While some courses require payment for certification, many offer free access to course materials, including lectures and assignments.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/" class="link">  Coursera
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Khan Academy:</strong>
      </p>
      <p class="chapter-description">
        Explore a wide range of free courses covering subjects from math and science to humanities and economics. The platform offers personalized learning profiles, exercises, and instructional videos to support self-paced learning.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.khanacademy.org/" class="link">  Khan Academy
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>edX:</strong>
      </p>
      <p class="chapter-description">
        edX offers free online courses from universities and institutions around the world. Subjects range from computer science and data analysis to humanities and business, with many courses providing optional paid certifications.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.edx.org/" class="link">  edX
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Udemy:</strong>
      </p>
      <p class="chapter-description">
        Udemy offers a vast array of free and paid courses on numerous subjects, including technology, business, personal development, and more. While many courses require payment, there are numerous free options available that provide quality content and learning opportunities.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.udemy.com/" class="link"> Udemy
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Udacity:</strong>
      </p>
      <p class="chapter-description">
        Udacity offers free courses in programming, data science, artificial intelligence, and more, with a focus on career advancement. While some nanodegree programs require payment, many individual courses are available for free.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.udacity.com/" class="link">  Udacity
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>MIT OpenCourseWare:</strong>
      </p>
      <p class="chapter-description">
        MIT OpenCourseWare offers free lecture notes, exams, and videos from MIT. This platform provides a comprehensive resource for learners looking to study a wide range of subjects, from introductory courses to advanced topics.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://ocw.mit.edu/" class="link">  MIT OpenCourseWare
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Harvard Online Learning:</strong>
      </p>
      <p class="chapter-description">
        Harvard Online Learning provides free courses from Harvard University in various subjects, including computer science, business, and humanities. Learners can access course materials, video lectures, and readings at no cost.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://online-learning.harvard.edu/" class="link">  Harvard Online Learning
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Stanford Online:</strong>
      </p>
      <p class="chapter-description">
        Stanford Online offers free courses covering topics in health, technology, and business. The platform provides high-quality course materials, lectures, and assignments to support independent learning.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://online.stanford.edu/" class="link">  Stanford Online
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Free Code Camp:</strong>
      </p>
      <p class="chapter-description">
        Free Code Camp offers free coding courses, projects, and certifications to help you build your programming skills. The platform covers a wide range of topics, including HTML, CSS, JavaScript, and more advanced subjects like data visualization and machine learning.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.freecodecamp.org/" class="link"> Free Code Camp
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Codecademy:</strong>
      </p>
      <p class="chapter-description">
        Codecademy allows you to learn to code interactively, for free. The platform offers courses in various programming languages, including Python, JavaScript, and SQL, with hands-on exercises and real-world projects to practice your skills.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.codecademy.com/" class="link">  Codecademy
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Top Google Coursera Courses by
        <a class="link" href="https://www.linkedin.com/in/gabag26/">  @Sahil Gaba
        </a>
      </strong>
    </p>

    <p>
      If you are a student, you can audit the courses to enroll for free!
    </p>
    <img src={coursera_audit} alt="Coursera Audit" />

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>1. Crash Course on Python</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/python-crash-course" class="link">  Python Crash Course
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>2. Introduction to Git and GitHub</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/introduction-git-github" class="link">  Introduction to Git and GitHub
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>3. Troubleshooting and Debugging Techniques</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/troubleshooting-debugging-techniques" class="link">  Troubleshooting and Debugging Techniques
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>4. Tools of the Trade: Linux and SQL</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/linux-and-sql" class="link">  Tools of the Trade: Linux and SQL
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>5. Master Data Structures and Algorithms</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://techdevguide.withgoogle.com/paths/interview/" class="link">  Master Data Structures and Algorithms
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>6. Android Development for Beginners</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://developer.android.com/courses/android-basics-compose/course" class="link">  Android Development for Beginners
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>7. Firebase in a Weekend</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.udacity.com/course/firebase-in-a-weekend-by-google-android--ud0352" class="link">  Firebase in a Weekend
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>8. Machine Learning Crash Course</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://developers.google.com/machine-learning/crash-course" class="link">  Machine Learning Crash Course
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>9. Foundations of Cybersecurity</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/foundations-of-cybersecurity" class="link"> Foundations of Cybersecurity
        </a>
      </li>

      <p class="chapter-sub-title">
        <strong>10. Introduction to Generative AI</strong>
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.coursera.org/learn/introduction-to-generative-ai" class="link">  Introduction to Generative AI
        </a>
      </li>
    </ul>
  </div>
</div>


        
      </div>


    </div>
  );
}

export default FreeLearningTools;
