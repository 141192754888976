import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import government from "../../../assets/header-images/government.jpg";

function GovernmentPrograms() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={government} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Government Programs </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
        </div>
        <p id="base-page-introduction-text-main">
            Government programs open doors to invaluable opportunities, providing individuals with essential technical skills, professional training, and real-world work experience across various fields. These initiatives often include internships, scholarships, and full-time positions, all designed to equip participants with the tools needed for successful careers in public service and beyond.
        </p>
    </div>
</div>

      <div id="sections-container">


      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Program Listings</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Year Up:</strong>
      </p>
      <p class="chapter-description">
        Year Up is a year-long program that equips young adults with the technical and professional skills needed to bridge the opportunity divide. Participants gain hands-on experience and mentorship to help them succeed in their careers.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.yearup.org/" class="link">  Year Up
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Pathways Program:</strong>
      </p>
      <p class="chapter-description">
        The Federal Pathways Programs offer internships and career opportunities for students and recent graduates to work in federal agencies. This includes the Internship Program, Recent Graduates Program, and Presidential Management Fellows (PMF) Program.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://careers.state.gov/interns-fellows/pathways-programs/" class="link">  Pathways Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Air Force PAQ Program:</strong>
      </p>
      <p class="chapter-description">
        The Air Force Palace Acquire (PAQ) Program aims to attract quality talent by providing developmental positions in various fields within the Air Force. Participants receive on-the-job training, mentorship, and career development support.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://afciviliancareers.com/paq-scienceengineering/" class="link">  Air Force PAQ Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>SMART DOD Scholarship Program:</strong>
      </p>
      <p class="chapter-description">
        The Science, Mathematics, and Research for Transformation (SMART) Scholarship-for-Service Program offers scholarships and job placements in Department of Defense facilities. This program supports students pursuing degrees in STEM fields and provides opportunities for internships and employment with the DOD.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.smartscholarship.org/smart" class="link">  SMART DOD Scholarship Program
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>WIOA Program:</strong>
      </p>
      <p class="chapter-description">
        The Workforce Innovation and Opportunity Act (WIOA) Program provides government-funded academic and professional development support to help individuals gain skills, credentials, and employment. The program focuses on education, training, and employment services for eligible participants.
      </p>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a href="https://www.dol.gov/agencies/eta/wioa" class="link">  WIOA Program
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Additional Tips for Applying to Government Programs</strong>
    </p>

    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Research Thoroughly:</strong> Make sure to understand the requirements and benefits of each program. Visit their official websites and carefully review the application process and eligibility criteria to ensure a strong application.
      </li>
      <li class="bullet-point">
        <strong>Prepare Your Documents:</strong> Commonly required documents include resumes, transcripts, personal statements, and letters of recommendation. Ensure these are up-to-date and tailored to the specific program you are applying for to make a positive impression.
      </li>
      <li class="bullet-point">
        <strong>Seek Guidance:</strong> Take advantage of your school’s career services or consult with mentors who can offer valuable advice and help review your application materials for clarity and impact.
      </li>
      <li class="bullet-point">
        <strong>Meet Deadlines:</strong> Government programs often have strict application deadlines. Plan ahead and submit your application well before the deadline to avoid any last-minute issues that could hinder your chances.
      </li>
      <li class="bullet-point">
        <strong>Follow Up:</strong> After submitting your application, consider following up with the program to confirm receipt and to ask any further questions you may have about the selection process. This shows your interest and enthusiasm!
      </li>
    </ul>
  </div>
</div>





      </div>
    </div>
  );
}

export default GovernmentPrograms;
