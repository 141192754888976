import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import research from "../../../assets/header-images/research.jpg";

function ResearchOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={research} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Research Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Research opportunities during college are essential for students looking to deepen their understanding of their chosen field, develop critical thinking skills, and gain hands-on experience. Engaging in research can be a transformative experience, complementing classroom learning and preparing students for future academic and professional pursuits. These opportunities not only enhance your educational experience but also pave the way for potential career advancements.
        </p>
    </div>
</div>


      <div id="sections-container">

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Benefits of Research Opportunities</strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Developing Practical Skills:</strong> Dive into hands-on experiences that enhance crucial skills like data analysis, problem-solving, and technical writing—skills that are super important for boosting your employability!
      </li>
      <li class="bullet-point">
        <strong>Building a Professional Network:</strong> Create valuable relationships with peers, mentors, and industry contacts. These connections can offer guidance and open doors to potential job opportunities down the line.
      </li>
      <li class="bullet-point">
        <strong>Enhancing Your Resume:</strong> Strengthen your academic credentials and make your resume stand out by showcasing your research experiences and achievements. It’s a great way to show what you can bring to the table!
      </li>
      <li class="bullet-point">
        <strong>Gaining Deeper Understanding:</strong> Explore your field and contribute to meaningful advancements. This not only enriches your knowledge but also helps you discover what you’re truly passionate about.
      </li>
    </ul>
  </div>
</div>




<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Finding Research Opportunities</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Identifying Opportunities:</strong>
      </p>
      <li class="bullet-point">
        <strong>Campus Resources:</strong> Make the most of resources available on campus, like career services, academic departments, and faculty advisors. These resources often have valuable information on research positions and can guide you through the application process.
      </li>
      <li class="bullet-point">
        <strong>Online Databases:</strong> Check out platforms like Handshake and your university department's research portal. They’re excellent places to search for various research opportunities that fit your interests.
      </li>
      <li class="bullet-point">
        <strong>Networking:</strong> Attend academic conferences, join student organizations related to your field, and connect with professors and fellow students. Networking can often lead to uncovering unadvertised research opportunities!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Approaching Professors:</strong>
      </p>
      <li class="bullet-point">
        <strong>Email Etiquette:</strong> When reaching out to professors, keep your email professional and concise. Introduce yourself, explain your interest in their research, and share your enthusiasm for contributing to their work.
      </li>
      <li class="bullet-point">
        <strong>Meeting Preparation:</strong> Before meeting with a professor, take some time to familiarize yourself with their research. Prepare questions and be ready to discuss how your interests align with their projects to make a great impression!
      </li>
    </ul>
  </div>
</div>

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Types of Research Opportunities</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>On-Campus Research:</strong>
      </p>
      <li class="bullet-point">
        <strong>Lab Assistants:</strong> Lab assistants typically help with experiments, data collection, and maintaining laboratory equipment. These positions offer a great opportunity to gain hands-on experience and learn valuable lab techniques.
      </li>
      <li class="bullet-point">
        <strong>Research Assistants:</strong> Research assistants support faculty members with tasks like literature reviews, data analysis, and preparing research publications. These roles allow for deeper involvement in research projects, which can enrich your academic journey.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Off-Campus Research:</strong>
      </p>
      <li class="bullet-point">
        <strong>Internships:</strong> Research internships with companies, non-profits, and government agencies provide practical experience in a professional environment. These internships often focus on applied research and can offer valuable insights into the industry.
      </li>
      <li class="bullet-point">
        <strong>External Labs:</strong> Opportunities in external research labs or institutions, such as national laboratories or private research firms, can broaden your research experience and expose you to diverse methodologies and technologies.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Application Process</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Preparation:</strong>
      </p>
      <li class="bullet-point">
        <strong>Resume:</strong> Create a resume that highlights your academic achievements, relevant coursework, and any research or lab experience. Tailor it specifically to the research opportunity you’re applying for to make a strong impression.
      </li>
      <li class="bullet-point">
        <strong>Cover Letter:</strong> Write a compelling cover letter that expresses your interest in the research position, outlines how your skills and experiences align with the project, and shares your long-term academic and career goals.
      </li>
      <li class="bullet-point">
        <strong>Personal Statement:</strong> Your personal statement should detail your research interests, past experiences, and why you’re passionate about this specific research area. Don’t forget to include any relevant coursework or projects that showcase your skills!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Recommendation Letters:</strong>
      </p>
      <li class="bullet-point">
        <strong>Who to Ask:</strong> Choose recommenders who know you well and can speak to your academic abilities and research potential. Professors, research supervisors, and academic advisors are great choices.
      </li>
      <li class="bullet-point">
        <strong>How to Request:</strong> When asking for a recommendation letter, give your recommender plenty of time, provide a copy of your resume, and share details about the research position. Remember to thank them for their support!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Interview Preparation:</strong>
      </p>
      <li class="bullet-point">
        <strong>Common Questions:</strong> Prepare to discuss your previous research experiences, your interest in the specific project, and your long-term goals. You might also encounter technical questions related to the research.
      </li>
      <li class="bullet-point">
        <strong>Tips:</strong> Practice your responses to common questions, research the project and the professor’s work thoroughly, and prepare thoughtful questions to ask during the interview. Don’t forget to follow up with a thank-you email afterward!
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Funding and Scholarships</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Funding Sources:</strong>
      </p>
      <li class="bullet-point">
        <strong>University Grants:</strong> Many universities offer grants and funding for student research projects. Be sure to check with your academic department and the university’s research office for available options.
      </li>
      <li class="bullet-point">
        <strong>External Funding:</strong> Numerous external organizations provide grants and fellowships for student researchers. Notable examples include the Fulbright Program, the Goldwater Scholarship, and various grants from professional societies in your field.
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Scholarships:</strong>
      </p>
      <li class="bullet-point">
        <strong>Overview:</strong> Scholarships specifically for research students can offer financial support and recognition for your efforts. These scholarships may be based on merit, need, or specific research interests.
      </li>
      <li class="bullet-point">
        <strong>Application Tips:</strong> Pay attention to scholarship requirements and deadlines. Tailor your application to showcase your research experiences and future goals. Don’t hesitate to seek feedback on your materials from mentors or advisors. For more guidance, check the Scholarship Page 
        <a class="link" href="/afford-college/scholarship"> here </a> for additional scholarship resources.
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Additional Tips</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Balancing Research with Academics:</strong>
      </p>
      <li class="bullet-point">
        <strong>Time Management:</strong> Balance your coursework and research commitments effectively by creating a detailed schedule and prioritizing tasks to keep yourself on track.
      </li>
      <li class="bullet-point">
        <strong>Seek Support:</strong> If you encounter challenges, don’t hesitate to reach out to your academic advisor or research supervisor for guidance. Collaboration is key to overcoming obstacles!
      </li>
    </ul>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Maximizing Research Experience:</strong>
      </p>
      <li class="bullet-point">
        <strong>Documentation:</strong> Keep detailed records of your research activities, including data collected, methodologies used, and outcomes. This documentation will be invaluable when writing reports and publications.
      </li>
      <li class="bullet-point">
        <strong>Continuous Learning:</strong> Stay updated with the latest developments in your field by regularly reading research papers, attending seminars, and engaging in meaningful discussions with your peers.
      </li>
    </ul>
  </div>
</div>



        {/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Participating in research during your college years can
              significantly enhance your academic and professional journey. By
              actively seeking out research opportunities, preparing thoroughly,
              and making the most of available resources, you can gain
              invaluable experience and make meaningful contributions to your
              field. Whether you are interested in on-campus research,
              internships, or external lab positions, the skills and connections
              you develop will serve you well in your future endeavors.
              Remember, the key to a successful research experience is proactive
              engagement, continuous learning, and a passion for discovery.
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default ResearchOpportunities;
