import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import navigating1 from "../../../assets/header-images/navigating1.jpg";

function ApplicationProcess() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={navigating1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Navigating the Application Process </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
  <div id="base-page-introduction-text-container">
    <div id="base-page-introduction-title-container">
      <h2 id="base-page-intro-title">Introduction</h2>
    </div>
    <p id="base-page-introduction-text-main">
      Embarking on the college application journey can feel like navigating a maze, but fear not! This guide is here to illuminate your path with a step-by-step overview designed to simplify the application process. From mastering the timeline to understanding various platforms, we’ll help you ensure that every component of your application is polished and well-organized. With these insights, you can streamline your approach and boost your chances of acceptance into your dream colleges. Let’s dive in and make your college aspirations a reality!
    </p>
  </div>
</div>

      {/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title"> Chapter 8: The Application Process</h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Timeline for College Applications</strong>
    </p>
    <p class="chapter-description">
      The college application process typically begins in your junior year and wraps up with submissions during your senior year. Following a well-structured timeline will help you stay organized and ensure you're ready for each step. Here’s a detailed breakdown:
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Junior Year</strong>
    </p>
    <li class="bullet-point">
      <strong>Research Colleges:</strong> Start exploring colleges that align with your academic goals and personal interests. Attend college fairs and connect with admissions representatives to gather essential information and consider your options.
    </li>
    <li class="bullet-point">
      <strong>Standardized Tests:</strong> Take standardized tests like the SAT or ACT if required by your chosen colleges. Use this year to prepare and take the tests early, allowing time for retakes if needed.
    </li>
    <li class="bullet-point">
      <strong>College Visits:</strong> Schedule visits to potential colleges for a firsthand look at their campus, facilities, and culture. These visits can help you assess whether a school feels like the right fit for you.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Summer Before Senior Year</strong>
    </p>
    <li class="bullet-point">
      <strong>Finalize College List:</strong> By the end of summer, narrow down your college list to a manageable number of schools that you plan to apply to, ensuring a good mix of safety, target, and reach schools.
    </li>
    <li class="bullet-point">
      <strong>Request Transcripts:</strong> Begin the process of requesting your academic transcripts to be sent to your prospective colleges. Taking this step early can prevent delays later on.
    </li>
    <li class="bullet-point">
      <strong>Brainstorm Essay Topics:</strong> Start brainstorming and drafting your college essays. Reflect on personal experiences that can make your application truly stand out.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Senior Year Fall</strong>
    </p>
    <li class="bullet-point">
      <strong>Application Essays:</strong> Dedicate time to refining and polishing your application essays. Seek feedback from teachers or mentors to ensure your essays are compelling and well-crafted.
    </li>
    <li class="bullet-point">
      <strong>Recommendation Letters:</strong> Request letters of recommendation from teachers, counselors, or mentors who know you well. Be sure to give them ample time to write thoughtful letters that highlight your strengths.
    </li>
    <li class="bullet-point">
      <strong>Submit Applications:</strong> Submit your college applications by early deadlines if applying through Early Action or Early Decision. Stay organized and double-check each application for accuracy.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Spring Semester</strong>
    </p>
    <li class="bullet-point">
      <strong>Follow Up on Applications:</strong> Track the status of your applications to ensure all required materials have been received. Follow up on any outstanding items as necessary.
    </li>
    <li class="bullet-point">
      <strong>Compare Financial Aid Offers:</strong> Once you begin receiving acceptance letters, review and compare financial aid packages from different schools, as this can significantly impact your final decision.
    </li>
    <li class="bullet-point">
      <strong>Make Enrollment Decision:</strong> After considering your options, decide which college you will attend and notify them of your decision by the enrollment deadline. Celebrate your hard work and look forward to the exciting journey ahead!
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Common Application Platforms</strong>
    </p>
    <p class="chapter-description">
      A variety of platforms are designed to streamline the college application process, making it easier to apply to multiple schools efficiently. Familiarizing yourself with these platforms can save you valuable time and effort:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>The Common Application (Common App):</strong> The Common App is accepted by numerous colleges and allows you to submit one application to multiple institutions. While this significantly simplifies the process, it’s important to personalize each application by tailoring your essays and supplemental materials to the specific colleges you’re applying to. Always check and meet individual school requirements, even when using the Common App.
    </li>
    <li class="bullet-point">
      <strong>University-Specific Applications:</strong> Some colleges have their own unique applications that are not part of the Common App. Be sure to pay close attention to deadlines, requirements, and essay prompts for these schools to ensure you fulfill all necessary criteria. This may involve navigating different platforms and submitting multiple applications, so staying organized is key.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Components of a College Application</strong>
    </p>
    <p class="chapter-description">
      Each component of a college application serves a unique purpose, collectively presenting a comprehensive picture of who you are to admissions committees. Understanding how each piece contributes can help you build a well-rounded and compelling application:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Academic Transcripts:</strong> Your academic transcripts are crucial to your application, reflecting not only your grades but also the rigor of your coursework. Admissions committees will evaluate your academic performance over time and how you've challenged yourself in key subjects.
    </li>
    <li class="bullet-point">
      <strong>Standardized Test Scores (if required):</strong> While not all colleges require standardized test scores, strong SAT or ACT results (if applicable) can enhance your application. Many schools are adopting test-optional policies, so focus on what best showcases your academic abilities.
    </li>
    <li class="bullet-point">
      <strong>Personal Statement/Essays:</strong> Your essays are a chance to share your personal story. Use them to highlight your passions, unique experiences, and motivations. A well-crafted essay can provide deeper insight into who you are beyond your grades and test scores.
    </li>
    <li class="bullet-point">
      <strong>Extracurricular Activities:</strong> Admissions committees seek well-rounded students who engage in interests outside the classroom. Highlight your involvement in clubs, sports, community service, and leadership roles to demonstrate your potential contributions to campus life.
    </li>
    <li class="bullet-point">
      <strong>Letters of Recommendation:</strong> Choose teachers, counselors, or mentors who know you well and can speak to both your academic abilities and personal character. Strong recommendations provide valuable context for your achievements and help distinguish you from other applicants.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Tracking Your Applications</strong>
    </p>
    <p class="chapter-description">
      Staying organized is crucial during the college application process. By creating a spreadsheet or Google Doc to track key details for each college, you can ensure that nothing falls through the cracks. Here’s what to include in your tracking document:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Application Deadlines:</strong> Record the early action, early decision, and regular decision deadlines for each college. Keeping track of these deadlines ensures you submit all materials on time and don’t miss any important dates.
    </li>
    <li class="bullet-point">
      <strong>Submission Dates:</strong> Log the exact dates when you submit each application. This will help you monitor your progress and confirm that all applications are submitted before their respective deadlines.
    </li>
    <li class="bullet-point">
      <strong>Interview Dates (if applicable):</strong> If a college requires or offers interviews, schedule and prepare for them in advance. Keeping track of these dates allows you to plan ahead and be fully ready for the interview process.
    </li>
    <li class="bullet-point">
      <strong>Financial Aid Deadlines:</strong> Track the deadlines for financial aid forms such as the FAFSA, CSS Profile, and any college-specific financial aid applications. Submitting these forms on time can significantly impact your financial aid package.
    </li>
    <li class="bullet-point">
      <strong>Acceptance and Rejection Notices:</strong> Keep a log of the responses you receive from each college. This helps you track your overall progress and make informed decisions as acceptance letters begin to arrive.
    </li>
    <li class="bullet-point">
      <strong>Scholarship Applications:</strong> Track the submission dates and results for any scholarship applications you submit. This ensures you stay on top of potential funding opportunities and meet all relevant deadlines.
    </li>
  </ul>
</div>


        {/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 8: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Navigating the college application process can be overwhelming,
              but staying organized and adhering to a well-planned timeline can
              help you manage it effectively. Utilize common application
              platforms, understand each component of the application, and track
              your progress diligently. By following these steps, you can
              present a strong, comprehensive profile to admissions committees
              and increase your chances of acceptance to your desired colleges.
            </p>
          </div>
        </div>
*/}
      </div>
    </div>
  );
}

export default ApplicationProcess;
