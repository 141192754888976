import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer";
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import conferences from "../../../assets/header-images/conferences.jpg";

function Conferences() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={conferences} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Tech Conferences </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>
        <p id="base-page-introduction-text-main">
            Welcome! Here, you'll find a curated list of essential conferences and events tailored for students aspiring to build successful careers. These events provide invaluable opportunities for learning, networking, mentorship, and career advancement. Whether you’re looking to connect with industry professionals, gain insights into the latest tech trends, or explore potential job opportunities, attending these conferences is a fantastic way to kickstart your journey in the tech world!
        </p>
    </div>
</div>


      <div id="sections-container">

        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Scholarships to Cover Some Conferences</strong>
    </p>
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Google Conference Scholarships:</strong>
      </p>
      <li class="bullet-point">
        <strong>Description:</strong> Google offers conference scholarships to help students attend a range of influential tech conferences. These scholarships provide essential financial support, allowing students to fully engage in networking, learning, and career advancement opportunities. Be sure to check the eligibility criteria and application deadlines for each conference to maximize your chances of receiving funding.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://buildyourfuture.withgoogle.com/scholarships/google-conference-scholarships"
          target="_blank"
          rel="noopener noreferrer"
        > Google Conference Scholarships
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>General Tech Conferences</strong>
    </p>
    
    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>ACM Richard Tapia Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>When:</strong> September each year
      </li>
      <li class="bullet-point">
        <strong>Focus:</strong> This conference connects undergraduate and graduate students, faculty, researchers, and professionals in computing from diverse backgrounds and ethnicities. It’s a fantastic opportunity to network and engage with a vibrant community dedicated to inclusion in tech.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://tapiaconference.cmd-it.org/"
          target="_blank"
          rel="noopener noreferrer"
        > ACM Richard Tapia Conference
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>ACM SIGGRAPH:</strong>
      </p>
      <li class="bullet-point">
        <strong>When:</strong> August each year
      </li>
      <li class="bullet-point">
        <strong>Focus:</strong> This conference brings together researchers, artists, developers, filmmakers, and professionals passionate about computer graphics and animation. It's a great platform for sharing ideas and exploring the latest trends in the industry.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://www.siggraph.org/"
          target="_blank"
          rel="noopener noreferrer"
        > ACM SIGGRAPH
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Great Minds in STEM Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> This conference celebrates diversity in STEM fields, offering resources, networking opportunities, and career advancement for underrepresented groups. It’s a wonderful space to connect with like-minded individuals and foster meaningful relationships in the STEM community.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://greatmindsinstem.org/gmis-conference/"
          target="_blank"
          rel="noopener noreferrer"
        > Great Minds in STEM Conference
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Conferences for Racial and Ethnic Groups</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Society of Hispanic Professional Engineers National Convention (SHPE):</strong>
      </p>
      <li class="bullet-point">
        <strong>When:</strong> Late October each year
      </li>
      <li class="bullet-point">
        <strong>Focus:</strong> The largest gathering of Hispanics in STEM, fostering community and collaboration.
      </li>
      <li class="bullet-point">
        <strong>Features:</strong> Enjoy two weeks of professional resume reviews and five full days of virtual face-to-face interviews for jobs and internships, helping you take the next step in your career!
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://shpe.org/engage/events/"
          target="_blank"
          rel="noopener noreferrer"
        > Society of Hispanic Professional Engineers National Convention (SHPE)
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Association of Latino Professionals For America Conference (ALPFA):</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> Connecting Latino professionals across various fields, providing opportunities for networking, mentorship, and career advancement.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://www.alpfa.org/"
          target="_blank"
          rel="noopener noreferrer"
        > Association of Latino Professionals For America Conference (ALPFA)
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>National Black MBA Association Conference (NBMBAA):</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> Providing resources, networking, and career opportunities tailored for Black professionals in business and related fields.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://nbmbaaconference.org/"
          target="_blank"
          rel="noopener noreferrer"
        > National Black MBA Association Conference (NBMBAA)
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>American Indian Science and Engineering Society Conference (AISES):</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> This conference connects American Indian, Alaska Native, and Native Hawaiian STEM professionals and students, providing a space for networking and career opportunities.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://www.aises.org/news/events/2024-aises-national-conference"
          target="_blank"
          rel="noopener noreferrer"
        > American Indian Science and Engineering Society Conference (AISES)
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Society for Advancement of Chicanos/Hispanics & Native Americans in Science National Diversity in STEM Conference (SACNAS):</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> Promoting diversity in STEM by connecting Chicanos/Hispanics and Native Americans with opportunities for networking, mentorship, and career advancement.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://www.sacnas.org/conference"
          target="_blank"
          rel="noopener noreferrer"
        > Society for Advancement of Chicanos/Hispanics & Native Americans in Science National Diversity in STEM Conference (SACNAS)
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>ADCOLOR Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> Highlighting diversity in the creative industries, this conference offers networking, mentorship, and career opportunities to foster growth and collaboration.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://adcolor.org/conference/"
          target="_blank"
          rel="noopener noreferrer"
        > ADCOLOR Conference
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>AFROTECH Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> Bringing together Black professionals in tech, this conference provides essential networking, mentorship, and career opportunities to support professional growth.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://experience.afrotech.com/"
          target="_blank"
          rel="noopener noreferrer"
        > AFROTECH Conference
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>National Society of Black Engineers National Convention (NSBE):</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> This convention provides resources, networking, and career opportunities for Black engineers and engineering students, creating a supportive environment for growth.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://convention.nsbe.org/welcome/"
          target="_blank"
          rel="noopener noreferrer"
        > National Society of Black Engineers National Convention (NSBE)
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Conferences for Women</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Grace Hopper Celebration:</strong>
      </p>
      <li class="bullet-point">
        <strong>When:</strong> Late September to early October each year
      </li>
      <li class="bullet-point">
        <strong>Focus:</strong> The largest gathering of women technologists, where you can learn, network, and celebrate remarkable achievements in the tech field.
      </li>
      <li class="bullet-point">
        <strong>Opportunity:</strong> Even if you can't attend, submit your resume to their database up to four months before the event to connect with potential employers!
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://ghc.anitab.org/"
          target="_blank"
          rel="noopener noreferrer"
        > Grace Hopper Celebration
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Society of Women Engineers Annual Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>When:</strong> Late October to early November each year
      </li>
      <li class="bullet-point">
        <strong>Focus:</strong> This conference supports women engineering students as they transition into the corporate world by providing training, guidance, and access to a wealth of career opportunities in academia and industry.
      </li>
      <li class="bullet-point">
        <strong>Opportunity:</strong> You can submit your resume to their database, even if you’re not attending, to increase your visibility to recruiters.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://we24.swe.org/"
          target="_blank"
          rel="noopener noreferrer"
        > Society of Women Engineers Annual Conference
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Latinas in Tech Summit:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> This summit is dedicated to empowering Latinas in tech through networking, mentorship, and career opportunities, fostering a supportive community for professional growth.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://latinasintechsummit.org/"
          target="_blank"
          rel="noopener noreferrer"
        > Latinas in Tech Summit
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Additional Conferences</strong>
    </p>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>HPAIR:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> The Harvard College Project for Asian and International Relations is a student-led, not-for-profit organization affiliated with Harvard University's Faculty of Arts and Sciences. This program is designed for both undergraduate and graduate students seeking to engage with global issues.
      </li>
      <li class="bullet-point">
        <strong>Best Advice:</strong> Take advantage of networking opportunities and connect with like-minded peers and professionals to broaden your horizons.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://www.hpair.org/"
          target="_blank"
          rel="noopener noreferrer"
        > HPAIR
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Yale Young Global Scholars:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> The Yale Young Global Scholars program offers an enriching academic experience for outstanding high school students from around the world, fostering critical thinking and global awareness.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://globalscholars.yale.edu/"
          target="_blank"
          rel="noopener noreferrer"
        > Yale Young Global Scholars
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>TechGirls:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> TechGirls is a U.S.-based summer exchange program designed to empower young women (ages 15-17) from the United States and select countries around the world. This initiative encourages participants to pursue higher education and careers in science, technology, engineering, and math (STEM) fields through hands-on skills development training.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://techgirlsglobal.org/"
          target="_blank"
          rel="noopener noreferrer"
        > TechGirls
        </a>
      </li>
    </ul>

    <ul class="bullet-point-list">
      <p class="chapter-sub-title">
        <strong>Business Today Conference:</strong>
      </p>
      <li class="bullet-point">
        <strong>Focus:</strong> The Business Today Conference brings together top business leaders and undergraduate students to discuss and explore current business trends and challenges, providing a platform for insightful dialogue and networking.
      </li>
      <li class="bullet-point">
        <strong>Website:</strong>
        <a
          class="link"
          href="https://businesstoday.org/conference/"
          target="_blank"
          rel="noopener noreferrer"
        > Business Today Conference
        </a>
      </li>
    </ul>
  </div>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Conference Tips</strong>
    </p>
    <ol class="bullet-point-list">
      <li class="bullet-point">Ask questions and show curiosity; it demonstrates your interest and eagerness to learn.</li>
      <li class="bullet-point">Remember, communication is key to success—express your thoughts clearly and listen actively!</li>
      <li class="bullet-point">Be confident in your abilities; your unique perspective is valuable in discussions.</li>
      <li class="bullet-point">Network effectively: connect on LinkedIn and follow up after the conference to maintain those connections.</li>
      <li class="bullet-point">Take notes on important points from sessions and conversations; they will be helpful for your future endeavors.</li>
      <li class="bullet-point">Dress appropriately: choose professional and comfortable attire to make a positive impression.</li>
    </ol>
  </div>
</div>


        {/* Conclusion Section */}

        {/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Attending conferences is an excellent way to enhance your
              knowledge, expand your professional network, and discover new
              career opportunities. These events bring together industry
              leaders, innovators, and aspiring technologists, creating an
              environment rich with learning and growth potential. Take
              advantage of these opportunities to connect with mentors, gain
              insights into the latest trends, and showcase your talents.
              Remember to apply for scholarships and plan ahead to make the most
              of your conference experiences. Embrace the chance to grow both
              personally and professionally as you pave the way for a successful
              career in technology.
            </p>
          </div>
        </div>


*/}
      </div>
    </div>
  );
}

export default Conferences;
