import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function PrepareScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 2</p>
            <p id = "sub-scholarship-ch-header-text">Preparing for the Scholarship Search</p>
            <p id = "sub-scholarship-ch-text-two">A Strategic Approach</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
    <div id="base-page-introduction-text-container">
        <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
        </div>

        <p id="base-page-introduction-text-main">
            This chapter provides essential guidance to help you navigate your scholarship search effectively. By establishing targeted goals and employing strategic planning and research techniques, you'll enhance your chances of securing funding for your education. Explore practical insights and resources that will empower you to approach your scholarship applications with confidence and clarity.
        </p>
    </div>
</div>

      <div id="whole-section-page-container">


        
      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Navigating Timelines and Application Periods</strong>
    </p>
    <p className="chapter-description">
      Scholarship applications generally open around August or September and continue until the following June or July, with many results being announced during the summer. However, some scholarships are available year-round, so it’s important to stay proactive. Start researching and identifying scholarships early to maximize your chances. Use the Scholarship Tracker to keep track of deadlines and requirements.
    </p>
    <p className="chapter-description">
      <strong>Tip:</strong> It’s a good idea to start your scholarship search at least six months before deadlines. This gives you enough time to gather necessary materials, write essays, and refine your applications without rushing.
    </p>
    <p className="chapter-description">
      <strong>Resource:</strong> Use this <a className="link" href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing">Scholarship Tracker</a> Google Sheet to stay organized. It’s helpful for logging deadlines, tracking application progress, and ensuring you meet all requirements on time.
    </p>
  </div>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Effective Scholarship Research Strategies</strong>
    </p>
    <p className="chapter-description">
      <strong>Prioritize Local Opportunities:</strong> Start by applying to local scholarships before expanding to national searches. Here's why this strategy works in your favor:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Reduced Competition:</strong> Local scholarships tend to have fewer applicants compared to national ones, which can significantly increase your chances of receiving an award.
      </li>
      <li className="bullet-point">
        <strong>Unique Opportunities:</strong> Many local organizations offer scholarships tailored to your community, background, or region, often focusing on underrepresented groups or specific interests.
      </li>
      <li className="bullet-point">
        <strong>Stronger Applications:</strong> Local scholarships often align more closely with your experiences, allowing you to create more personalized and compelling applications.
      </li>
    </ul>
    <p className="chapter-description">
      <strong>How to Find Local Scholarships:</strong> Use these strategies to discover local opportunities:
    </p>
    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>School Resources:</strong> Explore scholarships offered by your school. Speak with your counselor or research opportunities through local organizations that may offer scholarships.
      </li>
      <li className="bullet-point">
        <strong>Community Foundations:</strong> If you live in or near a major city, look into community foundations that often manage multiple scholarships.
      </li>
      <li className="bullet-point">
        <strong>Online Search:</strong> Use the <a href="https://www.communityfoundationlocator.com" className="link">Community Foundation Locator</a> to identify local foundations that may have scholarship opportunities.
      </li>
      <li className="bullet-point">
        <strong>City/State Searches:</strong> Search online for “[Your City/State] Scholarships” and review the first 5-10 pages of results to find additional options.
      </li>
      <li className="bullet-point">
        <strong>Religious Organizations:</strong> If you are part of a religious community, check with your place of worship or religious governing bodies for potential scholarships.
      </li>
      <li className="bullet-point">
        <strong>Employer-Based Scholarships:</strong> Ask your or your parents' employers if they offer any scholarship programs for employees or their families.
      </li>
      <li className="bullet-point">
        <strong>Library Resources:</strong> Visit your local library, as they often have scholarship resources available for the community.
      </li>
      <li className="bullet-point">
        <strong>College-Specific Scholarships:</strong> Once you start college, consult with your professors or department office to learn about scholarships available for your major.
      </li>
      <li className="bullet-point">
        <strong>Research Grants:</strong> If you're attending a research university, explore grants for independent research projects through your Honors College, Office of Undergraduate Research, or similar offices.
      </li>
    </ul>
  </div>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Scholarship Websites</strong>
    </p>
    <p className="chapter-description">
      After exploring local scholarship options, broaden your search by looking into national scholarships and online resources. Below are some reputable websites to help you get started:
    </p>

    <ul className="bullet-point-list">
      <li className="bullet-point">
        <a href="https://www.scholarshipamerica.org" className="link">
          Scholarship America
        </a> – One of the largest nonprofit organizations dedicated to managing and providing scholarships for students across the U.S.
      </li>
      <li className="bullet-point">
        <a href="https://www.mykaleidoscope.com" className="link">
          Kaleidoscope
        </a> – A platform that helps connect students with scholarships tailored to their background, skills, and interests.
      </li>
      <li className="bullet-point">
        <a href="https://bigfuture.collegeboard.org/scholarship-search" className="link">
          College Board Scholarship Website
        </a> – A widely trusted resource for finding scholarships, based on personal and academic details.
      </li>
      <li className="bullet-point">
        <a href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing" className="link">
          CollegeNav’s Top Choices Spreadsheet
        </a> – A curated list of top scholarship options to help you stay organized and track important deadlines.
      </li>
    </ul>
  </div>
</div>



      </div>
    </div>
  );
}

export default PrepareScholarship;
