import React from "react";
import { Link } from "react-router-dom";
import "../../css/MainPage/HomePage.css";
import headerpic from "../../assets/header-images/headerpic.png";

import quotepic from "../../assets/Logo/LogoCopy.png";
import quotesicon from "../../assets/inner-content-images-and-files/QuotePic.png";

import home1 from "../../assets/header-images/home1.jpg";

function HomePage() {
  return (
    <div id="home">

      
      <div id="home-face">
        <img id="home-pic" src={home1} alt="Header" />
        <div id="home-pic-text-container">
          <div id="home-pic-text">
            <h1>CollegeNav</h1>
            <h3>
              Ultimate Guide to Navigate Your College Journey with Confidence
            </h3>
          </div>
        </div>
      </div>

      <div id="introduction-page-main-container">
        {/*           
        <div id="home-page-title-container">
          <h1 id="home-page-title">Home</h1>
          <div id="home-page-title-underline"></div>
        </div>





        <div id="home-introduction-container">
          <div id="home-introduction-text-container">
            <h2 id="home-intro-title">Empower Your College Dreams!</h2>
            <p id="home-introduction-text-main">
              Welcome to CollegeNav - your trusted companion in navigating the
              complex world of college applications and beyond. Our mission is
              to empower every student with the tools, resources, and guidance
              they need to confidently pursue their academic and career
              aspirations.
            </p>
          </div>
          <div id="inspirational-quote">
            <blockquote>
              “Every student deserves the opportunity to reach their full
              potential. At CollegeNav, we're here to help you navigate your
              path to success."
            </blockquote>
            
          </div>
        </div>


*/}

        <div id="home-table-of-contents">
          <div id="home-table-content-container">
            <div id="smaller-toc-cont">
              <h1 id="new-TOC-name">Unveil the Steps to Achievement</h1>
            </div>

            <p id="new-toc-description">
              {" "}
              This section guides you through the key milestones of your college
              journey, helping you confidently navigate from preparation to
              application, all while keeping your goals in focus.{" "}
            </p>
          </div>

          {/*    

          <div id="home-introduction-container">
            <div id="home-introduction-text-container">
              <h2 id="get-involved-title">Navigating College Nav</h2>

              <p id="home-introduction-text-main">
                Whether you're in high school, community college, or already
                enrolled in college, CollegeNav offers tailored guidance to help
                you at every step of your journey:
              </p>
              <ul>
                <li id="home-introduction-text-main">
                  <Link to="/college-prep" className="link">
                    College Prep:
                  </Link>{" "}
                  Start here if you're preparing for your college applications.
                  Explore essential tips and resources to guide you through the
                  process.
                </li>
                <li id="home-introduction-text-main">
                  <Link to="/afford-college" className="link">
                    Afford College:
                  </Link>{" "}
                  Learn about financial aid, scholarships, and other resources
                  to help finance your education.{" "}
                </li>
                <li id="home-introduction-text-main">
                  <Link to="/college" className="link">
                    College Life:
                  </Link>{" "}
                  Discover tips and advice for navigating the college
                  experience, whether you're a newcomer or a current student.{" "}
                </li>
                <li id="home-introduction-text-main">
                  <Link to="/programs" className="link">
                    Programs:
                  </Link>{" "}
                  Find internships, academic programs, and unique opportunities
                  offered by companies and institutions.{" "}
                </li>
              </ul>

              <p>
                {" "}
                <i>
                  <b>NOTE:</b> Pages marked with ❋❋❋ are highly recommended.
                </i>
              </p>
            </div>
          </div>


          */}

          <div id="new-TOC-categories-container">
            <div class="pricing-card">
              <p class="plan-type">Step 1</p>
              <h3>College Prep</h3>

              <ul class="features-list">
                <li>
                  {" "}
                  <Link to="/college-prep/highschool-roadmap" className="link">
                    High School Roadmap
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/different-paths" className="link">
                    Exploring Different Paths
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/majors" className="link">
                    Choosing a Major
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/college-search" className="link">
                    College Search
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/high-school" className="link">
                    High School Planning
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/community-college" className="link">
                    Community College Pathways
                  </Link>
                </li>
                <li>
                  <Link
                    to="/college-prep/activities-and-achievements"
                    className="link"
                  >
                    Activities and Achievements
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/application-process" className="link">
                    Navigating the Application Process
                  </Link>
                </li>
                <li>
                  <Link to="/college-prep/college-essay" className="link">
                    Crafting Your College Essay
                  </Link>
                </li>
                <li>
                  <Link
                    to="/college-prep/letters-of-recommendation"
                    className="link"
                  >
                    Securing Letters of Recommendation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/college-prep/international-students"
                    className="link"
                  >
                    Guidance for International Students
                  </Link>
                </li>

                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
              </ul>
              <Link to="/college-prep">
                <button class="get-started-btn-s1">Get started</button>
              </Link>
            </div>

            <div class="pricing-card">
              <p class="plan-type">Step 2</p>
              <h3>Afford College</h3>

              <ul class="features-list">
                <li>
                  <Link to="/afford-college/financial-aid" className="link">
                    Understanding Financial Aid
                  </Link>
                </li>
                <li>
                  <Link to="/afford-college/scholarship" className="link">
                    Scholarships +
                  </Link>
                </li>
                <li>
                  <Link to="/afford-college/scholarship" className="link">
                    Finding Scholarships
                  </Link>
                </li>

                <li>
                  <Link
                    to="/afford-college/scholarship/understanding-scholarship"
                    className="link"
                  >
                    Understanding Scholarships
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/scholarship/scholarship-search"
                    className="link"
                  >
                    Preparing for the Scholarship Search
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/scholarship/application-tips"
                    className="link"
                  >
                    Maximizing Your Chances of Success
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/scholarship/crafting-application"
                    className="link"
                  >
                    Crafting a Strong Application
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/scholarship/scholarship-interview"
                    className="link"
                  >
                    Mastering the Scholarship Interview
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/scholarship/post-award-tips"
                    className="link"
                  >
                    Post-Award Success Strategies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/afford-college/student-work-opportunities"
                    className="link"
                  >
                    Student Employment Opportunities
                  </Link>
                </li>

                <li class="new-bullet-point-thing"> GZ </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
              </ul>
              <Link to="/afford-college">
                <button class="get-started-btn-s2">Get started</button>
              </Link>
            </div>

            <div class="pricing-card">
              <p class="plan-type">Step 3</p>
              <h3>College Life</h3>

              <ul class="features-list">
                <li>
                  <Link to="/college/before-college" className="link">
                    Preparing for College Life
                  </Link>
                </li>
                <li>
                  <Link to="/college/college-tips" className="link">
                    Essential College Tips
                  </Link>
                </li>
                <li>
                  <Link to="/college/school-benefits" className="link">
                    Maximizing School Benefits
                  </Link>
                </li>
                <li>
                  <Link to="/college/gpa-calculator" className="link">
                    GPA Calculator
                  </Link>
                </li>
                <li>
                  <Link to="/college/stem-internships" className="link">
                    STEM Internship Opportunities
                  </Link>
                </li>
                <li>
                  <Link
                    to="/programs/freshmen-sophomore-programs"
                    className="link"
                  >
                    Freshmen and Sophomore Programs
                  </Link>
                </li>
                <li>
                  <Link to="/college/resume" className="link">
                    Crafting Your Resume
                  </Link>
                </li>

                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
              </ul>
              <Link to="/college">
                <button class="get-started-btn-s3">Get started</button>
              </Link>
            </div>

            <div class="pricing-card">
              <p class="plan-type">Step 4</p>
              <h3>Programs</h3>

              <ul class="features-list">
                <li>
                  <Link to="/programs/research-opportunities" className="link">
                    Research Opportunities
                  </Link>
                </li>
                <li>
                  <Link to="/programs/study-abroad" className="link">
                    Study Abroad Programs
                  </Link>
                </li>
                <li>
                  <Link to="/programs/mentorship-programs" className="link">
                    Mentorship Programs
                  </Link>
                </li>
                <li>
                  <Link to="/programs/tech-conferences" className="link">
                    Tech Conferences
                  </Link>
                </li>
                <li>
                  <Link to="/programs/academic-stem-programs" className="link">
                    Academic STEM Programs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/programs/all-stem-work-opportunities"
                    className="link"
                  >
                    All STEM Work Opportunities
                  </Link>
                </li>
                <li>
                  <Link
                    to="/programs/freshmen-sophomore-programs"
                    className="link"
                  >
                    Freshmen and Sophomore Programs
                  </Link>
                </li>
                <li>
                  <Link to="/programs/apprenticeship" className="link">
                    Apprenticeship Programs
                  </Link>
                </li>
                <li>
                  <Link to="/programs/government-programs" className="link">
                    Government Programs
                  </Link>
                </li>
                <li>
                  <Link to="/programs/free-learning-tools" className="link">
                    Free Educational Resources
                  </Link>
                </li>

                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
                <li class="new-bullet-point-thing"> test </li>
              </ul>

              <Link to="/programs">
                <button class="get-started-btn-s4">Get started</button>
              </Link>
            </div>
          </div>

          <div id="testing-thing">
            <div class="pricing-card-new">
              <p class="plan-type">Step 5</p>
              <h3>Tools</h3>

              <ul class="features-list">
                <li>
                  <Link to="/tools/ai" className="link">
                    Aza (AI Chatbot)
                  </Link>
                </li>
                <li>
                  <Link to="/tools/early-career-search" className="link">
                    Early Career Search (Internships, Fellowships, Research, Programs and etc)
                  </Link>
                </li>
                <li>
                  <Link to="/tools/financial-tracker" className="link">
                    Financial Tracker
                  </Link>
                </li>
                <li>
                  <Link to="/tools/gpa-calculator" className="link">
                    GPA Calculator
                  </Link>
                </li>

                <li>
                  <Link to="/tools/college-search" className="link">
                    College Search
                  </Link>
                </li>

                <li>
                  <Link to="/tools/scholarship-search" className="link">
                    Scholarship Search
                  </Link>
                </li>

                <li>
                  <Link to="/tools/virtual-tour" className="link">
                    Virtual College Tour
                  </Link>
                </li>

                <li>
                  <Link
                    to="/tools/international-students-tool"
                    className="link"
                  >
                    International Students Tool
                  </Link>
                </li>

                <li class="new-bullet-point-thing"> test </li>
              </ul>

              <Link to="/tools">
                <button class="get-started-btn-s4">Get started</button>
              </Link>
            </div>
          </div>

          <div id="quote-background">
            <div id="quote-container">
              <img id="quote-icon" src={quotesicon} alt="quotepic" />

              <p id="quote-text">
                "Every student deserves the chance to reach their full
                potential. At CollegeNav, we're here to guide you on your
                journey to success with the tools, resources, and support you
                need to confidently navigate the path to higher education."
              </p>

              <div class="author-info">
                <img class="author-img" src={quotepic} alt="quotepic" />

                <h1 class="author-name"> CollegeNav </h1>
              </div>
            </div>
          </div>

          <div id="all-TOC-category-container">
            {/* 
            <div className="home-table-of-contents-category">
              <Link to="/college-prep" className="link">
                <h3 className="TOC-category-title">College Prep</h3>
              </Link>
              
              <div className="home-TOC-pages-container">
                <p className="home-TOC-page">
                  <Link to="/college-prep/highschool-roadmap" className="link">
                    1. High School Roadmap
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/different-paths" className="link">
                    2. Exploring Different Paths
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/majors" className="link">
                    3. Choosing a Major<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/college-search" className="link">
                    4. College Search Strategies
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/high-school" className="link">
                    5. High School Planning
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/community-college" className="link">
                    6. Community College Pathways
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/application-process" className="link">
                    7. Navigating the Application Process<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college-prep/college-essay" className="link">
                    8. Crafting Your College Essay<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link
                    to="/college-prep/letters-of-recommendation"
                    className="link"
                  >
                    9. Securing Letters of Recommendation<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link
                    to="/college-prep/international-students"
                    className="link"
                  >
                    10. Guidance for International Students
                  </Link>
                </p>
              </div>
            </div>



            */}

            {/*
            <div className="home-table-of-contents-category">
              <Link to="/college-prep" className="link">
                <h3 className="TOC-category-title">Afford College</h3>
              </Link>
              
              <div className="home-TOC-pages-container">
                <p className="home-TOC-page">
                  <Link to="/afford-college/financial-aid" className="link">
                    1. Understanding Financial Aid<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/afford-college/scholarship" className="link">
                    2. Finding Scholarships<b> ❋❋❋</b>
                  </Link>
                </p>

                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/understanding-scholarship"
                    className="link"
                  >
                    CH1. Understanding Scholarships
                  </Link>
                </p>
                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/scholarship-search"
                    className="link"
                  >
                    CH2. Preparing for the Scholarship Search<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/application-tips"
                    className="link"
                  >
                    CH3. Maximizing Your Chances of Success<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/crafting-application"
                    className="link"
                  >
                    CH4. Crafting a Strong Application<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/scholarship-interview"
                    className="link"
                  >
                    CH5. Mastering the Scholarship Interview<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page-chapter">
                  <Link
                    to="/afford-college/scholarship/post-award-tips"
                    className="link"
                  >
                    CH6. Post-Award Success Strategies<b> ❋❋❋</b>
                  </Link>
                </p>

                <p className="home-TOC-page">
                  <Link
                    to="/afford-college/student-work-opportunities"
                    className="link"
                  >
                    3. Student Employment Opportunities
                  </Link>
                </p>
              </div>
            </div>




*/}

            {/*

            <div className="home-table-of-contents-category">
              <Link to="/college-prep" className="link">
                <h3 className="TOC-category-title">College Life</h3>
              </Link>
              
              <div className="home-TOC-pages-container">
                <p className="home-TOC-page">
                  <Link to="/college/before-college" className="link">
                    1. Preparing for College Life<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college/college-tips" className="link">
                    2. Essential College Tips
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college/school-benefits" className="link">
                    3. Maximizing School Benefits
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college/gpa-calculator" className="link">
                    4. GPA Calculator
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college/stem-internships" className="link">
                    5. STEM Internship Opportunities
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link
                    to="/programs/freshmen-sophomore-programs"
                    className="link"
                  >
                    6. Programs for Freshmen and Sophomores<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/college/resume" className="link">
                    7. Crafting Your Resume<b> ❋❋❋</b>
                  </Link>
                </p>
              </div>
            </div>


         */}

            {/*
            <div className="home-table-of-contents-category">
              <Link to="/college-prep" className="link">
                <h3 className="TOC-category-title">Programs</h3>
              </Link>
              
              <div className="home-TOC-pages-container">
                <p className="home-TOC-page">
                  <Link to="/programs/research-opportunities" className="link">
                    1. Research Opportunities
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/study-abroad" className="link">
                    2. Study Abroad Programs
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/mentorship-programs" className="link">
                    3. Mentorship Programs<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/tech-conferences" className="link">
                    4. Attending Conferences
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/academic-stem-programs" className="link">
                    5. Academic STEM Programs<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link
                    to="/programs/all-stem-work-opportunities"
                    className="link"
                  >
                    6. Comprehensive STEM Work Opportunities<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link
                    to="/programs/freshmen-sophomore-programs"
                    className="link"
                  >
                    7. Programs for Freshmen and Sophomores<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/apprenticeship" className="link">
                    8. Apprenticeship Programs<b> ❋❋❋</b>
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/government-programs" className="link">
                    9. Government-Sponsored Programs
                  </Link>
                </p>
                <p className="home-TOC-page">
                  <Link to="/programs/free-learning-tools" className="link">
                    10. Free Educational Resources<b> ❋❋❋</b>
                  </Link>
                </p>
              </div>
            </div>


            */}
          </div>
        </div>

        {/* 

        <div id="get-involved-container">
          <h2 id="get-involved-title">Get Involved!</h2>
          
          <p>
            Your support is invaluable to us. Whether you share your own
            experiences or explore partnership opportunities, your involvement
            helps us continue to provide crucial resources and guidance to
            students as they embark on their college journey.
          </p>
          <Link to="/contact-us" className="link">
            <button id="get-involved-button">Get Involved</button>
          </Link>
        </div>

*/}

        <div class="wholesale-container">
          <div class="wholesale-content-box">
            <h2 id="new-TOC-name">Get Involved</h2>
            <p id="new-toc-description">
              Your support is invaluable to us. Whether you share your own
              experiences or explore partnership opportunities, your involvement
              helps us continue to provide crucial resources and guidance to
              students as they embark on their college journey.
            </p>
            <Link to="/contact-us" className="link">
              <button class="wholesale-button">Learn More </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
